import {
  GrainCertificate,
  Cultivation,
  CultivationMethod,
  Species,
  DeliveryLocation,
  RegistrationDefaultValues,
} from './grain-registration.model';

export interface RegistrationState {
  information?: Information;
  batches?: Array<Batch>;
  complete?: Complete;
  grower?: Grower;
  customer?: Customer;
  cultivations?: Array<Cultivation>;
  grainCertificates?: Array<GrainCertificate>;
  defaultValues?: RegistrationDefaultValues;
}

export interface Information {
  year: number;
  cultivation: Cultivation;
  grainCertificate: GrainCertificate;
  skalNumber?: string;
  transitionDate?: Date;
}

export interface Batch {
  id: string;
  cultivationMethod: CultivationMethod;
  species: Species;
  hectares: number;
  isPool: boolean;
  isSelfContained: boolean;
  storageTimeType: StorageTimeType;
  isDeposit: boolean;
  isHectareDeposit: boolean;
  isPickUp: boolean;
  deliveryLocation: DeliveryLocation;
  outcome: BatchOutcome;
}

export interface Customer {
  id: number;
  externalCustomerId: string;
  emailAddress: string;
  name: string;
  street: string;
  postalCode: string;
  houseNumber: string;
  houseNumberAddition: string;
  city: string;
  country: string;
  telephoneNumber: string;
  mobileNumber: string;
}

export enum StorageTimeType {
  none,
  short,
  long,
}

export enum BatchOutcome {
  dagprijsInBewaring,
  langeBewaarpool,
  langeBewaarpoolHectareVoorschot,
  basispoolBewarenVoorschot,
  basispoolBewarenZonderVoorschot,
  basispoolBewarenVoorschotHectareVoorschot,
  basispoolVoorschot,
  basispoolZonderVoorschot,
  basispoolVoorschotHectareVoorschot,
}

export interface Grower {
  skalNumber: string;
  transitionDate: Date;
}

export interface Complete {
  comment: string;
  requirementsAccepted: boolean;
}

export interface AppState {
  registration: RegistrationState;
}
