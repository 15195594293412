export interface GrainRegistration {
  id: string;
  year: number;
  cultivation: CultivationItem;
  batches: Array<GrainRegistrationItem>;
  comment: string;
  grower: GrowerReference;
}

export interface GrainRegistrationItem {
  cultivationMethod: CultivationMethodReference;
  species: Species;
  hectares: number;
  isPool: boolean;
  isSelfContained: boolean;
  storageTimeType: string;
  isDeposit: boolean;
  isHectareDeposit: boolean;
  outcome: string;
  deliveryLocation: DeliveryLocation;
}

export interface GrowerReference {
  certificate: string;
  skalNumber: string;
  transitionDate: Date;
}

export interface CustomerReference {
  id: number;
  externalCustomerId: string;
  emailAddress: string;
  name: string;
  street: string;
  postalCode: string;
  houseNumber: string;
  houseNumberAddition: string;
  city: string;
  country: string;
  telephoneNumber: string;
  mobileNumber: string;
}

export interface CustomerGrowerReference {
  customer: CustomerReference;
  grower: unknown;
}

export interface Cultivation {
  id: string;
  cultivation: string;
  cultivationMethods: Array<CultivationMethod>;
  deliveryLocations: Array<DeliveryLocation>;
}

export enum CultivationEnum {
  Biological = '1',
  Common = '2',
  Switchover = '3',
}

export interface CultivationItem {
  id: string;
  cultivation: string;
}

export interface GrainCertificate {
  certificate: string;
}

export interface DeliveryLocation {
  id: string;
  location: string;
  locationDisplayName: string;
}

export interface CultivationMethod {
  id: string;
  method: string;
  species: Array<Species>;
  conditions: Array<Conditions>;
  defaultValues: Array<DefaultValues>;
}

export interface CultivationMethodReference {
  id: string;
  method: string;
}

export interface Species {
  id: string;
  species: string;
}

export interface Conditions {
  propertyName: string;
  propertyValue: unknown;
  preConditions: Array<Conditions>;
}

export interface DefaultValues {
  propertyName: string;
  propertyValue: unknown;
}

export interface RegistrationDefaultValues {
  certificate: string;
  skalNumber: string;
}
