import { CultivationMethod, Species } from 'src/app/registration/models/grain-registration.model';

export interface PickUpState {
  pickUpInformation?: PickUpInformation;
  pickUpList?: PickUpViewModel[];
  pickUpListToBeSaved?: PickUp[];
}

export interface PickUpInformation {
  address: Address;
  pickUpLocation: AddressWithManualSetting;
  email: string;
  additionalEmail: string;
  phone: string;
  crops: CropWithQuantity[];
  customerLoads: boolean;
  insideStorage: boolean;
  remarks: string;
}

export interface CropWithQuantity {
  cultivationId: string;
  crop: CultivationMethod;
  species?: Species;
  quantity: number;
}

export interface Address {
  name: string;
  street: string;
  houseNumber: string;
  houseNumberAddition: string;
  postalCode: string;
  city: string;
}

export interface CropForPickupOverview {
  cultivationId: string;
  cultivationMethodId: string;
  cultivationMethod?: string;
  speciesId?: string;
  species?: string;
  ton: number;
}

export interface PickUpForOverview {
  id: number;
  displayId: string;
  customerId: number;
  customerExternalId: string;
  customerName: string;
  transporterExternalId: string;
  transporterName: string;
  year: number;
  status?: PickUpStatuses;
  internalNotebook?: string;
  pickUpInformation: ApiPickUpInformation;
  contactInformation: ApiContactInformation;
  pdf: GrainPickUpPdf;
  updated: Date;
  displayUpdated: string;
  created: Date;
}

export interface PickUp {
  id: number;
  document: PickUpDetail;
}

export interface PickUpDetail {
  year: number;
  displayId: string;
  customerId: number;
  transporterExternalId: string;
  status?: PickUpStatuses;
  internalNotebook?: string;
  pickupInformation: ApiPickUpInformation;
  contactInformation: ApiContactInformation;
  updated: string;
}

export enum PickUpStatuses {
  planned = 'Planned',
  collected = 'Collected',
  noStatus = 'NoStatus',
}

export interface AddressWithManualSetting extends Address {
  isManual: boolean;
}

export interface ApiRegisterPickUpInformation {
  pickUpInformation: ApiPickUpInformation;
  contactInformation: ApiContactInformation;
}

export interface ApiPickUpInformation {
  isCustomerLoading: boolean;
  isInsideStorage: boolean;
  pickUpLocation: Address;
  crops: ApiCropWithQuantity[];
  remarks: string;
}

export interface ApiContactInformation {
  emailAddress: string;
  additionalEmailAddresses: string[];
  phoneNumber: string;
  address: Address;
}

export interface ApiCropWithQuantity {
  cultivationId: string;
  cropId: string;
  speciesId?: string;
  quantity: number;
}

export interface PickUpViewModel extends PickUpForOverview {
  hasPendingChanges: boolean;
  cropInformation?: CropForPickupOverview;
  remarks: string;
  customer: string;
  pickupLocation: string;
  postalCode: string;
  crop: string;
  tons: number;
  isCustomerLoading: string;
  isInsideStorage: string;
  internalNotebook: string;
  transporterName: string;
}

export interface GrainPickUpPdf {
  id: string;
  fileName: string;
}

export interface PickUpOverviewDataFilters {
  search: string;
}

export enum PickUpColumnTypes {
  id = 'id',
  updated = 'updated',
  status = 'status',
  remarks = 'remarks',
  customer = 'customer',
  pickupLocation = 'pickupLocation',
  postalCode = 'postalCode',
  crop = 'crop',
  tons = 'tons',
  isCustomerLoading = 'isCustomerLoading',
  isInsideStorage = 'isInsideStorage',
  internalNotebook = 'internalNotebook',
  transporterName = 'transporterName',
}

export const displayColumns = [
  PickUpColumnTypes.id,
  PickUpColumnTypes.updated,
  PickUpColumnTypes.status,
  PickUpColumnTypes.transporterName,
  PickUpColumnTypes.remarks,
  PickUpColumnTypes.customer,
  PickUpColumnTypes.pickupLocation,
  PickUpColumnTypes.postalCode,
  PickUpColumnTypes.crop,
  PickUpColumnTypes.tons,
  PickUpColumnTypes.isCustomerLoading,
  PickUpColumnTypes.isInsideStorage,
  PickUpColumnTypes.internalNotebook,
] as const;
