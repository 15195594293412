<div class="batch-overview" data-test="batch-overview">
  <mat-card
    appearance="outlined"
    *ngFor="let batch of batchesForm$ | async"
    class="batch-overview__item spacing--margin-vertical"
  >
    <mat-card-header>
      <mat-card-title data-test="batch-overview__title">{{
        'batch-overview.' + batchConfigurationOutcomeService.getOutcomeName(batch.outcome)
          | translate
      }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div>
        <agr-value-row
          label="{{ 'batch-overview.product' | translate }}"
          data-test="batch-overview__product"
        >
          {{ batch.cultivationMethod.method }}
          <ng-container *ngIf="batch.species">({{ batch.species.species }})</ng-container>
        </agr-value-row>
        <agr-value-row
          label="{{ 'batch-overview.is-self-contained' | translate }}"
          data-test="batch-overview__is-self-contained"
        >
          {{
            (batch.isSelfContained
              ? 'batch-overview.self-contained-true'
              : 'batch-overview.self-contained-false'
            ) | translate
          }}
        </agr-value-row>
        <agr-value-row
          label="{{ 'batch-overview.storage-time-type' | translate }}"
          data-test="batch-overview__storage-time-type"
          *ngIf="batch.storageTimeType"
        >
          {{ getDeliveryName(batch.storageTimeType) }}
        </agr-value-row>
        <agr-value-row
          label="{{ 'batch-overview.delivery-location' | translate }}"
          data-test="batch-overview__delivery-location"
          *ngIf="batch.deliveryLocation || batch.isPickUp"
        >
          {{
            (batch.deliveryLocation
              ? batch.deliveryLocation.locationDisplayName
              : 'batch-overview.delivered-on-location'
            ) | translate
          }}
        </agr-value-row>
        <agr-value-row
          label="{{ 'batch-overview.hectare' | translate }}"
          data-test="batch-overview__hectare"
        >
          {{ batch.hectares | agrNumber }} ha
        </agr-value-row>
        <agr-value-row
          label="{{ 'batch-overview.deposit' | translate }}"
          data-test="batch-overview__is-deposit"
        >
          {{ (batch.isDeposit ? 'yes' : 'no') | translate }}
        </agr-value-row>
        <agr-value-row
          label="{{ 'batch-overview.pool' | translate }}"
          data-test="batch-overview__is-pool"
        >
          {{ (batch.isPool ? 'yes' : 'no') | translate }}
        </agr-value-row>
        <agr-value-row
          label="{{ 'batch-overview.hectare-deposit' | translate }}"
          data-test="batch-overview__is-hectare-deposit"
        >
          {{ (batch.isHectareDeposit ? 'yes' : 'no') | translate }}
        </agr-value-row>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button
        [routerLink]="['/registration', 'batches', batch.id]"
        mat-button
        mat-button-stroked
        color="primary"
        type="button"
        *ngIf="!viewMode"
        data-test="batch-overview__edit"
      >
        <mat-icon class="batch-overview__card-icon">edit</mat-icon>
        {{ 'edit' | translate }}
      </button>
    </mat-card-actions>
  </mat-card>

  <ng-container *ngIf="(hasValidInformationForm$ | async) !== true">
    <agr-notification-empty
      [isEmpty]="(hasValidInformationForm$ | async) !== true"
      emptyTitle="{{ 'batch-overview.missing-information-title' | translate }}"
      emptyDescription="{{ 'batch-overview.missing-information-description' | translate }}"
      emptyImage="assets/images/grain1.jpg"
      class="batch-overview__notification-empty"
      data-test="batch-overview__notification-no-valid-information"
    >
    </agr-notification-empty>
  </ng-container>
  <ng-container
    *ngIf="(hasValidInformationForm$ | async) === true && (hasBatches$ | async) !== true"
  >
    <agr-notification-empty
      *ngIf="(hasValidInformationForm$ | async) === true && (hasBatches$ | async) !== true"
      [isEmpty]="true"
      emptyTitle="{{ 'batch-overview.empty-title' | translate }}"
      emptyDescription="{{ 'batch-overview.empty-description' | translate }}"
      emptyImage="assets/images/grain2.jpg"
      class="batch-overview__notification-empty"
      data-test="batch-overview__notification-empty"
      emptyActionText="{{ 'batch-overview.add-new-batch' | translate }}"
      [showEmptyAction]="true"
      (emptyAction)="addBatch()"
    >
    </agr-notification-empty>
  </ng-container>

  <div
    *ngIf="
      !viewMode && (hasValidInformationForm$ | async) === true && (hasBatches$ | async) === true
    "
    class="batch-overview-buttons"
  >
    <button
      mat-flat-button
      color="accent"
      data-test="batch-overview__add"
      [disabled]="(hasValidInformationForm$ | async) !== true"
      [routerLink]="['/registration', 'batches', 'add']"
    >
      {{ 'batch-overview.add-new-batch' | translate }}
    </button>
    <button
      mat-flat-button
      color="primary"
      class="button-align-right"
      data-test="batch-overview__add"
      [disabled]="(hasValidInformationForm$ | async) !== true"
      [routerLink]="['/registration', 'complete']"
    >
      {{ 'batch-overview.complete-registration' | translate }}
    </button>
  </div>
</div>
