import { setAgrEnvironment, AgrProcessEnv, AgrEnvironment } from '@next/core-lib/environment';

export class Environment extends AgrEnvironment {
  // add app specific environment variables here
  MAINTENANCE_MODE: boolean;
  constructor(env: Environment) {
    super(env);
  }
}

export const setEnvironment = ({
  /* APP_SPECIFIC_ENV, */
  MAINTENANCE_MODE,
  ...env
}: AgrProcessEnv): Environment =>
  new Environment({
    /* APP_SPECIFIC_ENV, */
    /* APP_SPECIFIC_ENV: ..., */
    MAINTENANCE_MODE: MAINTENANCE_MODE?.toLowerCase() === 'true' ? true : false,
    ...setAgrEnvironment(env),
  });
