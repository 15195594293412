import { AppState } from '../shared/models';
import { AppActions, AppActionTypes } from './actions';

export const initialState = {};

export const appReducer = (state: AppState = initialState, action: AppActions): AppState => {
  switch (action.type) {
    case AppActionTypes.SetDefaultCropYear: {
      return {
        ...state,
        defaultCropYear: action.cropYear,
      };
    }

    case AppActionTypes.SetAvailableCropYears: {
      return {
        ...state,
        availableCropYears: action.availableCropYears,
      };
    }

    default:
      return state;
  }
};
