<agr-page-detail
  [pageTitle]="'registration.new-registration' | translate"
  class="registration"
  data-test="registration"
  (backAction)="back()"
>
  <div class="wrapper--spacing-horizontal">
    <nav mat-tab-nav-bar color="accent" class="registration__tabs" [tabPanel]="tabPanel">
      <a
        mat-tab-link
        [routerLink]="['/registration']"
        [active]="isInformation$ | async"
        data-test="registration__tab-information"
      >
        1. {{ 'registration.information' | translate }}
      </a>
      <a
        mat-tab-link
        [routerLink]="['/registration', 'batches']"
        [active]="isBatches$ | async"
        data-test="registration__tab-batches"
      >
        2. {{ 'registration.batches' | translate }}
      </a>
      <a
        mat-tab-link
        [routerLink]="['/registration', 'complete']"
        [active]="isComplete$ | async"
        data-test="registration__tab-complete"
      >
        3. {{ 'registration.complete' | translate }}
      </a>
    </nav>
    <mat-tab-nav-panel #tabPanel>
      <router-outlet></router-outlet>
    </mat-tab-nav-panel>
  </div>
</agr-page-detail>
