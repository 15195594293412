import { props, createAction } from '@ngrx/store';
import { Complete } from '../../models/registration.model';
import { GrainRegistration } from '../../models/grain-registration.model';

export const setComplete = createAction(
  '[Registration] Set complete',
  props<{ complete: Complete }>(),
);
export const saveRegistration = createAction('[Registration] Save Registration');
export const saveRegistrationSuccess = createAction(
  '[Registration] Save Registration success',
  props<{ registration: GrainRegistration }>(),
);
export const saveRegistrationError = createAction(
  '[Registration] Save Registration error',
  props<{ message: string; status: number }>(),
);
