import { Injectable } from '@angular/core';
import { UrlTree, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { selectActiveBatch } from '../store/selectors/batch.selectors';
import { SiteState } from 'src/app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class BatchConfigurationEditGuard {
  constructor(private router: Router, private store: Store<SiteState>) {}

  canActivate(activatedRouteSnapShot: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.store.pipe(
      select(selectActiveBatch, Number(activatedRouteSnapShot.params?.id)),
      map((batch) => (!!batch ? true : this.router.parseUrl(`/registration/batches`))),
    );
  }
}
