import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormControl, ValidatorFn } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of, combineLatest, Subscription, Subject } from 'rxjs';
import {
  take,
  map,
  switchMap,
  withLatestFrom,
  shareReplay,
  debounceTime,
  distinctUntilChanged,
  tap,
  startWith,
  takeUntil,
} from 'rxjs/operators';
import { BatchConfigurationOutcomeService } from '../../services/batch-configuration-outcome.service';
import {
  getNextBatchId,
  selectBatch,
  selectActiveBatchId,
} from 'src/app/registration/store/selectors/batch.selectors';
import { Batch, StorageTimeType, BatchOutcome } from '../../models/registration.model';
import { addBatch, updateBatch, cancelBatch } from '../../store/actions/batch.actions';
import { CultivationMethod, DeliveryLocation } from '../../models/grain-registration.model';
import {
  selectCultivationMethods,
  selectInformation,
} from '../../store/selectors/information.selectors';
import { LocaleService } from '@next/core-lib/i18n';
import { selectDeliveryLocations } from '../../store/selectors/registration.selectors';
import { SiteState } from 'src/app/shared/models';

interface BatchValidators {
  species: ValidatorFn[];
  deliveryLocation: ValidatorFn[];
  storageTimeType: ValidatorFn[];
}

@Component({
  selector: 'app-batch-configuration',
  templateUrl: './batch-configuration.component.html',
  styleUrls: ['./batch-configuration.component.scss'],
})
export class BatchConfigurationComponent implements OnInit, OnDestroy {
  informationForm$ = this.store.select(selectInformation);
  existingId$ = this.store.select(selectActiveBatchId);
  deliveryLocations$ = this.store.select(selectDeliveryLocations);
  formGroupInitialState = {
    id: new FormControl(null, []),
    cultivationMethod: new FormControl(null, [Validators.required]),
    species: new FormControl(null, []),
    hectares: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(999)]),
    isPool: new FormControl(null, [Validators.required]),
    isSelfContained: new FormControl(null, [Validators.required]),
    storageTimeType: new FormControl(null, [Validators.required]),
    isDeposit: new FormControl(null, [Validators.required]),
    isHectareDeposit: new FormControl(null, [Validators.required]),
    isPickUp: new FormControl(null, [Validators.required]),
    deliveryLocation: new FormControl(null, [Validators.required]),
    outcome: new FormControl(null, []),
  };

  batch$: Observable<Batch> = this.existingId$.pipe(
    withLatestFrom(this.store.select(getNextBatchId)),
    switchMap(([id, nextId]) =>
      id !== undefined
        ? this.store.select(selectBatch, String(id))
        : of({
            id: nextId,
            cultivationMethod: null,
            species: null,
            hectares: null,
            isPool: false,
            isSelfContained: false,
            storageTimeType: StorageTimeType.none,
            isDeposit: false,
            isHectareDeposit: false,
            isPickUp: false,
            deliveryLocation: null,
            outcome: null,
          }),
    ),
    debounceTime(1),
    tap(() => {
      this.initBatchForm();
    }),
    shareReplay(1),
  );

  outcome$ = this.batch$.pipe(
    map((batch) =>
      batch.outcome
        ? this.locale.translate('batch-configuration.edit-batch')
        : this.locale.translate('batch-configuration.add-batch'),
    ),
  );

  batchForm: FormGroup = new FormGroup(this.formGroupInitialState);
  StorageTimeType = StorageTimeType;
  BatchOutcome: BatchOutcome;

  formChange$: Observable<Batch> = this.batchForm.valueChanges.pipe(
    debounceTime(1),
    distinctUntilChanged((prev, comp) => JSON.stringify(prev) === JSON.stringify(comp)),
    shareReplay(1),
  );
  cultivationMethod$: Observable<CultivationMethod> =
    this.batchForm.controls.cultivationMethod.valueChanges.pipe(shareReplay(1));
  species$ = this.batchForm.controls.species.valueChanges.pipe(shareReplay(1));
  isPool$: Observable<boolean> = this.batchForm.controls.isPool.valueChanges.pipe(shareReplay(1));
  isSelfContained$ = this.batchForm.controls.isSelfContained.valueChanges.pipe(shareReplay(1));
  isPickUp$ = this.batchForm.controls.isPickUp.valueChanges.pipe(shareReplay(1));
  deliveryLocation$ = this.batchForm.controls.deliveryLocation.valueChanges.pipe(shareReplay(1));
  storageTimeType$: Observable<StorageTimeType> =
    this.batchForm.controls.storageTimeType.valueChanges.pipe(shareReplay(1));
  isDeposit$ = this.batchForm.controls.isDeposit.valueChanges.pipe(shareReplay(1));
  isHectareDeposit$ = this.batchForm.controls.isHectareDeposit.valueChanges.pipe(shareReplay(1));
  filteredCultivationMethods$: Observable<CultivationMethod[]>;
  filteredDeliveryLocations$: Observable<DeliveryLocation[]>;

  disabledChanges$ = combineLatest([this.cultivationMethod$, this.formChange$]);

  disabledStorageTimeType$ = this.disabledChanges$.pipe(
    map(([cultivationMethod, batch]) =>
      this.isControlDisabled(cultivationMethod, batch, 'storageTimeType'),
    ),
    tap((disabled) =>
      disabled
        ? this.batchForm.controls.storageTimeType.disable({ emitEvent: false })
        : this.batchForm.controls.storageTimeType.enable({ emitEvent: false }),
    ),
  );

  disabledSelfContained$ = this.disabledChanges$.pipe(
    map(([cultivationMethod, batch]) =>
      this.isControlDisabled(cultivationMethod, batch, 'isSelfContained'),
    ),
    tap((disabled) =>
      disabled
        ? this.batchForm.controls.isSelfContained.disable({ emitEvent: false })
        : this.batchForm.controls.isSelfContained.enable({ emitEvent: false }),
    ),
  );

  disabledIsPool$ = this.disabledChanges$.pipe(
    map(([cultivationMethod, batch]) => this.isControlDisabled(cultivationMethod, batch, 'isPool')),
    tap((disabled) =>
      disabled
        ? this.batchForm.controls.isPool.disable({ emitEvent: false })
        : this.batchForm.controls.isPool.enable({ emitEvent: false }),
    ),
  );

  disabledDeposit$ = this.disabledChanges$.pipe(
    map(([cultivationMethod, batch]) =>
      this.isControlDisabled(cultivationMethod, batch, 'isDeposit'),
    ),
    tap((disabled) =>
      disabled
        ? this.batchForm.controls.isDeposit.disable({ emitEvent: false })
        : this.batchForm.controls.isDeposit.enable({ emitEvent: false }),
    ),
  );

  disabledPickUp$ = this.disabledChanges$.pipe(
    map(([cultivationMethod, batch]) =>
      this.isControlDisabled(cultivationMethod, batch, 'isPickUp'),
    ),
    tap((disabled) =>
      disabled
        ? this.batchForm.controls.isPickUp.disable({ emitEvent: false })
        : this.batchForm.controls.isPickUp.enable({ emitEvent: false }),
    ),
  );

  disabledHectareDeposit$ = this.disabledChanges$.pipe(
    map(([cultivationMethod, batch]) =>
      this.isControlDisabled(cultivationMethod, batch, 'isHectareDeposit'),
    ),
    tap((disabled) =>
      disabled
        ? this.batchForm.controls.isHectareDeposit.disable({ emitEvent: false })
        : this.batchForm.controls.isHectareDeposit.enable({ emitEvent: false }),
    ),
  );

  showSpecies$ = this.cultivationMethod$.pipe(
    map((cultivationMethod) => cultivationMethod?.species?.length > 0),
  );

  showLocations$ = combineLatest([this.isPickUp$, this.isSelfContained$]).pipe(
    map(([isPickUp, isSelfContained]) => !isSelfContained && !isPickUp),
  );

  showStorageTimeType$ = combineLatest([this.isPool$, this.isSelfContained$]).pipe(
    map(([isPool, isSelfContained]) => !!(isPool && isSelfContained)),
  );

  showDeposit$ = combineLatest([this.isPool$, this.isSelfContained$, this.storageTimeType$]).pipe(
    map(
      ([isPool, isSelfContained, storageTimeType]) =>
        (isPool &&
          isSelfContained &&
          parseInt(String(storageTimeType), 10) === StorageTimeType.short) ||
        (isPool && !isSelfContained),
    ),
  );

  showIsHectareDeposit$ = combineLatest([
    this.isPool$,
    this.isDeposit$,
    this.isSelfContained$,
    this.storageTimeType$,
  ]).pipe(
    map(
      ([isPool, isDeposit, isSelfContained, storageTimeType]: [
        boolean,
        boolean,
        boolean,
        StorageTimeType,
      ]) =>
        (isPool && !isSelfContained && isDeposit) ||
        (isPool &&
          isSelfContained &&
          parseInt(String(storageTimeType), 10) === StorageTimeType.long) ||
        (isPool &&
          isSelfContained &&
          parseInt(String(storageTimeType), 10) === StorageTimeType.short &&
          isDeposit),
    ),
  );

  showIsPickUp$ = this.isSelfContained$.pipe(map((isSelfContained) => !isSelfContained));

  batchValidators$: Observable<BatchValidators> = combineLatest([
    this.showSpecies$,
    this.showLocations$,
    this.showStorageTimeType$,
  ]).pipe(
    map(([showSpecies, showLocations, showStorageTimeType]) => ({
      species: showSpecies ? [Validators.required] : [],
      deliveryLocation: showLocations ? [Validators.required] : [],
      storageTimeType: showStorageTimeType ? [Validators.required] : [],
    })),
  );

  subscriptions: Subscription;
  disabledSubscriptions: Subscription;
  private readonly destroyed$ = new Subject<void>();

  constructor(
    private store: Store<SiteState>,
    private batchConfigurationOutcomeService: BatchConfigurationOutcomeService,
    private locale: LocaleService,
  ) {}

  ngOnInit() {
    this.watchDisabled();
    this.watchValueChanges();

    this.filteredCultivationMethods$ = combineLatest([
      this.batchForm.get('cultivationMethod').valueChanges,
      this.store.select(selectCultivationMethods),
    ]).pipe(
      takeUntil(this.destroyed$),
      map(([input, cultivationMethods]: [string | CultivationMethod, CultivationMethod[]]) => {
        if (!cultivationMethods) {
          return [];
        }
        const filterValue = typeof input === 'string' ? input : input?.method ? input.method : '';
        return filterValue
          ? this.filterCultivationMethods(filterValue, cultivationMethods)
          : cultivationMethods.slice();
      }),
    );

    this.filteredDeliveryLocations$ = combineLatest([
      this.batchForm.get('deliveryLocation').valueChanges.pipe(startWith(null)),
      this.store.select(selectDeliveryLocations),
    ]).pipe(
      takeUntil(this.destroyed$),
      map(([input, deliveryLocations]: [string | DeliveryLocation, DeliveryLocation[]]) => {
        const filterValue =
          typeof input === 'string'
            ? input
            : input?.locationDisplayName
            ? input.locationDisplayName
            : '';
        return filterValue
          ? this.filterDeliveryLocations(filterValue, deliveryLocations)
          : deliveryLocations.slice();
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.disabledSubscriptions.unsubscribe();

    this.destroyed$.next();
    this.destroyed$.complete();
  }

  isControlDisabled(cultivationMethod: CultivationMethod, batch: Batch, propertyName: string) {
    if (cultivationMethod && cultivationMethod.conditions) {
      const conditions = cultivationMethod.conditions.filter(
        (c) => c.propertyName === propertyName,
      );
      return (
        conditions
          .map(
            (condition) =>
              (condition.preConditions && condition.preConditions.length > 0
                ? condition.preConditions.map(
                    (c1) => batch[c1.propertyName as keyof Batch] === c1.propertyValue,
                  )
                : [true]
              ).indexOf(false) === -1,
          )
          .indexOf(true) > -1
      );
    }
    return false;
  }

  initBatchForm() {
    this.batch$.pipe(take(1)).subscribe((batchData) => {
      if (batchData) {
        this.batchForm.setValue(batchData);
      }
    });
  }

  setDefaultValuesByCultivationMethod(cultivationMethod: CultivationMethod) {
    if (cultivationMethod && cultivationMethod.defaultValues) {
      cultivationMethod.defaultValues.forEach((value) => {
        this.batchForm.controls[value.propertyName].setValue(value.propertyValue);
      });
    }
  }

  watchDisabled() {
    this.disabledSubscriptions = this.disabledStorageTimeType$.subscribe();
    this.disabledSubscriptions.add(this.disabledSelfContained$.subscribe());
    this.disabledSubscriptions.add(this.disabledIsPool$.subscribe());
    this.disabledSubscriptions.add(this.disabledDeposit$.subscribe());
    this.disabledSubscriptions.add(this.disabledPickUp$.subscribe());
    this.disabledSubscriptions.add(this.disabledHectareDeposit$.subscribe());
  }

  watchValueChanges() {
    this.subscriptions = this.formChange$.subscribe((batch: Batch) => {
      if (batch.cultivationMethod && batch.cultivationMethod.conditions) {
        batch.cultivationMethod.conditions.forEach((c) => {
          const isValidPreCondition =
            (c.preConditions && c.preConditions.length > 0
              ? c.preConditions.map(
                  (c1) => this.batchForm.controls[c1.propertyName].value === c1.propertyValue,
                )
              : [true]
            ).indexOf(false) === -1;

          if (
            isValidPreCondition &&
            this.batchForm.controls[c.propertyName].value !== c.propertyValue
          ) {
            this.batchForm.controls[c.propertyName].setValue(c.propertyValue, { emitEvent: true });
            this.batchForm.controls[c.propertyName].updateValueAndValidity();
          }
        });
      }
    });

    this.subscriptions.add(
      this.batchForm.controls.cultivationMethod.valueChanges.subscribe(
        (cultivationMethod: CultivationMethod) => {
          this.batchForm.controls.species.setValue('');
          this.setDefaultValuesByCultivationMethod(cultivationMethod);
        },
      ),
    );

    this.subscriptions.add(
      this.batchForm.controls.isPool.valueChanges.subscribe(() => {
        this.batchForm.controls.isSelfContained.setValue(false);
        this.batchForm.controls.deliveryLocation.updateValueAndValidity();
      }),
    );

    this.subscriptions.add(
      this.batchForm.controls.isSelfContained.valueChanges.subscribe(() => {
        this.batchForm.controls.storageTimeType.setValue(StorageTimeType.none);
        this.batchForm.controls.isPickUp.setValue(false);
      }),
    );

    this.subscriptions.add(
      this.batchForm.controls.storageTimeType.valueChanges.subscribe((storage: StorageTimeType) => {
        if (parseInt(String(storage), 10) === StorageTimeType.long) {
          this.batchForm.controls.isDeposit.setValue(true);
        } else {
          this.batchForm.controls.isDeposit.setValue(false);
        }

        this.batchForm.controls.isDeposit.updateValueAndValidity();
      }),
    );

    this.subscriptions.add(
      this.batchForm.controls.isDeposit.valueChanges.subscribe(() => {
        this.batchForm.controls.isHectareDeposit.setValue(false);
        this.batchForm.controls.isHectareDeposit.updateValueAndValidity();
      }),
    );

    this.subscriptions.add(
      this.batchForm.controls.isPickUp.valueChanges.subscribe(() => {
        this.batchForm.controls.deliveryLocation.reset();
      }),
    );

    this.subscriptions.add(
      this.batchValidators$.subscribe((validators) => {
        Object.keys(validators).forEach((key: keyof BatchValidators) => {
          this.batchForm.controls[key].setValidators(validators[key]);
          this.batchForm.controls[key].updateValueAndValidity();
        });
      }),
    );
  }

  private filterCultivationMethods(method: string, cultivationMethods: CultivationMethod[]) {
    return cultivationMethods.filter(
      (option) => option.method.toLowerCase().indexOf(method.toLowerCase()) === 0,
    );
  }

  private filterDeliveryLocations(location: string, deliveryLocations: DeliveryLocation[]) {
    return deliveryLocations.filter(
      (option) => option.locationDisplayName.toLowerCase().indexOf(location.toLowerCase()) === 0,
    );
  }

  displayCultivationMethod(cultivationMethod?: CultivationMethod) {
    return !cultivationMethod ? '' : cultivationMethod.method;
  }

  displayDeliveryLocation(deliveryLocation?: DeliveryLocation) {
    return !deliveryLocation ? '' : deliveryLocation.locationDisplayName;
  }

  save() {
    const deliveryLocation = this.batchForm.controls.deliveryLocation;
    if (deliveryLocation.value && typeof deliveryLocation.value === 'string') {
      deliveryLocation.reset();
    } else if (this.batchForm.valid) {
      this.existingId$.pipe(take(1)).subscribe((existingId) => {
        this.batchForm.controls.outcome.setValue(
          this.batchConfigurationOutcomeService.calculateOutcome(
            this.batchForm.getRawValue() as Batch,
          ),
        );

        this.store.dispatch(
          existingId !== undefined
            ? updateBatch({ batch: this.batchForm.getRawValue() as Batch })
            : addBatch({ batch: this.batchForm.getRawValue() as Batch }),
        );
      });
    }
  }

  cancel() {
    this.store.dispatch(cancelBatch());
  }
}
