import * as informationActions from '../actions/information.actions';
import * as batchActions from '../actions/batch.actions';
import * as registrationActions from '../actions/registration.actions';
import * as completeActions from '../actions/complete.actions';
import { RegistrationState } from '../../models/registration.model';
import { createReducer, on, Action } from '@ngrx/store';

export const initialState = {} as RegistrationState;

const registrationReducer = createReducer(
  initialState,
  on(informationActions.setInformation, (state, information) => ({
    ...state,
    information: {
      cultivation: information.cultivation,
      year: information.year,
      grainCertificate: information.grainCertificate,
    },
    grower: {
      ...state.grower,
      skalNumber: information.skalNumber,
      transitionDate: information.transitionDate,
    },
  })),
  on(completeActions.setComplete, (state, { complete }) => ({
    ...state,
    complete,
  })),
  on(registrationActions.getAllGrainCultivationsSuccess, (state, { cultivations }) => ({
    ...state,
    cultivations,
  })),
  on(registrationActions.getAllGrainCertificatesSuccess, (state, { grainCertificates }) => ({
    ...state,
    grainCertificates,
  })),
  on(registrationActions.getCustomerWithGrowerSuccess, (state, { customerWithGrower }) => ({
    ...state,
    customer: customerWithGrower.customer,
  })),
  on(batchActions.addBatch, (state, { batch }) => {
    const currentBatches = 'batches' in state ? state.batches : [];
    return {
      ...state,
      batches: [...currentBatches, batch],
    };
  }),
  on(batchActions.updateBatch, (state, { batch }) => {
    const batchIndex = state.batches.findIndex((e) => e.id === batch.id);

    return {
      ...state,
      batches: state.batches.map((item, index) => (index !== batchIndex ? item : batch)),
    };
  }),
  on(batchActions.resetBatches, (state, _) => ({
    ...state,
    batches: [],
  })),
  on(registrationActions.getRegistrationDefaultValuesSuccess, (state, { defaultValues }) => ({
    ...state,
    defaultValues,
  })),
  on(registrationActions.resetRegistration, (state, _) => ({
    ...state,
    information: undefined,
    batches: [],
    complete: undefined,
    grower: undefined,
    defaultValues: undefined,
  })),
);

export const reducer = (state: RegistrationState | undefined, action: Action) =>
  registrationReducer(state, action);
