import { Action, createReducer, on } from '@ngrx/store';
import { CropWithQuantity, PickUpColumnTypes, PickUpState } from '../../models/pick-up.model';
import * as informationActions from '../actions/information.actions';
import * as pickupActions from '../actions/pickup.actions';

export const initialState = {} as PickUpState;

const pickUpReducer = createReducer(
  initialState,
  on(informationActions.addCrop, (state, { cropWithQuantity }) => {
    let newCrops: CropWithQuantity[];
    if (!state.pickUpInformation || !state.pickUpInformation.crops) {
      newCrops = [];
    } else {
      newCrops = [...state.pickUpInformation.crops];
    }

    newCrops.push(cropWithQuantity);

    return {
      ...state,
      pickUpInformation: {
        ...state.pickUpInformation,
        crops: newCrops,
      },
    };
  }),
  on(informationActions.removeCrop, (state, { index }) => {
    const newCrops = state.pickUpInformation?.crops ? [...state.pickUpInformation.crops] : [];
    if (newCrops.length > 0 && index >= 0 && index < state.pickUpInformation.crops.length) {
      newCrops.splice(index, 1);
    }

    return {
      ...state,
      pickUpInformation: {
        ...state.pickUpInformation,
        crops: newCrops,
      },
    };
  }),
  on(informationActions.setInformationFormValues, (state, { values }) => {
    const crops = [...state.pickUpInformation?.crops];
    return {
      ...state,
      pickUpInformation: {
        ...values,
        crops,
      },
    };
  }),
  on(pickupActions.getPickupListSuccess, (state, { pickUpList }) => ({
    ...state,
    pickUpList,
  })),
  on(pickupActions.setPickupListToBeSaved, (state, { pickUpList }) => ({
    ...state,
    pickUpListToBeSaved: pickUpList,
  })),
  on(pickupActions.updatePickupInList, (state, { text, rowId, columnType }) => ({
    ...state,
    pickUpList: state.pickUpList.map((pickup) =>
      pickup.id === rowId
        ? columnType === PickUpColumnTypes.remarks
          ? { ...pickup, remarks: text, hasPendingChanges: true }
          : { ...pickup, internalNotebook: text, hasPendingChanges: true }
        : pickup,
    ),
  })),
);

export const reducer = (state: PickUpState | undefined, action: Action) =>
  pickUpReducer(state, action);
