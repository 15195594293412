<agr-form-dialog
  [formGroup]="formGroup"
  [data]="data"
  (confirm)="confirm($event)"
  (cancel)="cancel()"
  (alt)="alt()"
>
  <div class="flex--container" style="min-width: 300px; margin-top: 20px">
    <div class="flex__child--auto">
      <mat-form-field class="full-width-field">
        <mat-label>Input</mat-label>
        <textarea
          matInput
          rows="5"
          cols="40"
          formControlName="input"
          placeholder="Write your text here"
          data-test="single-input-dialog-field"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
</agr-form-dialog>
