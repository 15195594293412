import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { RouterSelectors } from '@next/core-lib/router-store';
import { CustomerGuard } from '@next/core-lib/relationships';
import { switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import * as formActions from '../store/actions/registration.actions';
import {
  selectGrainCertificates,
  selectCultivations,
  selectCustomer,
} from '../store/selectors/registration.selectors';
import { SiteState } from 'src/app/shared/models';
import { of } from 'rxjs';
import { selectSessionCustomerId } from 'src/app/store/selectors';

@Injectable({
  providedIn: 'root',
})
export class RegistrationGuard {
  constructor(private store: Store<SiteState>, private readonly customerGuard: CustomerGuard) {}

  getData() {
    return this.store.pipe(
      select(RouterSelectors.getMergedRoute),
      withLatestFrom(
        this.store.select(selectGrainCertificates),
        this.store.select(selectCultivations),
        this.store.select(selectCustomer),
        this.store.select(selectSessionCustomerId),
      ),
      take(1),
      tap(([route, certificates, cultivations, customer, sessionCustomerId]) => {
        if (route.url === '/registration' || !certificates) {
          this.store.dispatch(formActions.getAllGrainCertificates());
        }
        if (route.url === '/registration' || !cultivations) {
          this.store.dispatch(formActions.getAllGrainCultivations());
        }
        if (
          route.url === '/registration' ||
          !customer ||
          (customer && customer.id !== sessionCustomerId)
        ) {
          this.store.dispatch(formActions.getCustomerWithGrower());
        }
      }),
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.customerGuard.canActivate(route, state).pipe(
      switchMap((result) => {
        if (typeof result !== 'boolean') {
          return of(result);
        }

        return this.getData().pipe(
          switchMap(() => [true]),
          take(1),
        );
      }),
    );
  }
}
