import { createSelector } from '@ngrx/store';
import { selectBatches } from './batch.selectors';
import { selectGrower } from './registration.selectors';
import { selectComplete } from './complete.selectors';
import { selectInformation } from './information.selectors';
import {
  Batch,
  Complete,
  Grower,
  Information,
  StorageTimeType,
  BatchOutcome,
} from '../../models/registration.model';

export const selectRegistrationData = createSelector(
  selectBatches,
  selectComplete,
  selectGrower,
  selectInformation,
  (batches: Batch[], complete: Complete, grower: Grower, information: Information) => ({
    id: null,
    year: information.year,
    cultivation: {
      id: information.cultivation.id,
      cultivation: information.cultivation.cultivation,
    },
    comment: complete.comment,
    batches: batches.map((batch) => ({
      ...batch,
      id: null,
      cultivationMethod: {
        id: batch.cultivationMethod.id,
        method: batch.cultivationMethod.method,
      },
      storageTimeType: StorageTimeType[batch.storageTimeType],
      outcome: BatchOutcome[batch.outcome],
    })),
    grower: {
      certificate: information.grainCertificate.certificate,
      skalNumber: grower.skalNumber,
      transitionDate: !!grower.transitionDate ? grower.transitionDate : null,
    },
  }),
);
