import { props, createAction } from '@ngrx/store';
import {
  Cultivation,
  GrainCertificate,
  CustomerGrowerReference,
  RegistrationDefaultValues,
} from '../../models/grain-registration.model';

export const getAllGrainCultivations = createAction('[Registration] Get all grain cultivations');
export const getAllGrainCultivationsSuccess = createAction(
  '[Registration] Get all grain cultivations success',
  props<{ cultivations: Cultivation[] }>(),
);
export const cancelRegistration = createAction('[Registration] Cancel registration');
export const resetRegistration = createAction('[Registration] Reset registration');

export const getAllGrainCertificates = createAction('[Registration] Get all grain certificates');
export const getAllGrainCertificatesSuccess = createAction(
  '[Registration] Get grain certificates success',
  props<{ grainCertificates: GrainCertificate[] }>(),
);

export const getCustomerWithGrower = createAction(
  '[Registration] Get customer with grower information',
);
export const getCustomerWithGrowerSuccess = createAction(
  '[Registration] Get customer with grower information success',
  props<{ customerWithGrower: CustomerGrowerReference }>(),
);

export const getRegistrationDefaultValues = createAction(
  '[Registration] Get default values',
  props<{ cultivationId: string }>(),
);
export const getRegistrationDefaultValuesSuccess = createAction(
  '[Registration] Get default values success',
  props<{ defaultValues: RegistrationDefaultValues }>(),
);
