import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentInjector } from '@next/core-lib/environment';
import {
  GrainRegistration,
  Cultivation,
  GrainCertificate,
  CustomerGrowerReference,
  RegistrationDefaultValues,
} from '../models/grain-registration.model';
import { Environment } from '../../../environment';
import { ApiRegisterPickUpInformation } from 'src/app/pick-up/models/pick-up.model';

@Injectable({
  providedIn: 'root',
})
export class GrainRegistrationService {
  // change the base url to point to the according service name
  private baseUrl = `${this.env.API_BASE_URL}/service/grain-registration`;
  constructor(private http: HttpClient, @Inject(EnvironmentInjector) private env: Environment) {}

  saveRegistration(grainRegistration: GrainRegistration) {
    return this.http.post<GrainRegistration>(
      `${this.baseUrl}/secure/registration`,
      grainRegistration,
      {},
    );
  }

  getCultivations() {
    return this.http.get<Array<Cultivation>>(`${this.baseUrl}/secure/cultivation`, {});
  }

  getGrainCertificates() {
    return this.http.get<Array<GrainCertificate>>(`${this.baseUrl}/secure/certificate`, {});
  }

  getCustomerGrower() {
    return this.http.get<CustomerGrowerReference>(`${this.baseUrl}/secure/customer`, {});
  }

  getRegistrationDefaultValues(cultivationId: string) {
    return this.http.get<RegistrationDefaultValues>(
      `${this.baseUrl}/secure/registration/defaultvalues/${cultivationId}`,
      {},
    );
  }

  savePickUpRequest(year: number, pickUpInformation: ApiRegisterPickUpInformation) {
    return this.http.post(
      `${this.baseUrl}/secure/grainPickUpRequest/register/${year}`,
      pickUpInformation,
    );
  }
}
