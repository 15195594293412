import { SessionSelectors } from '@next/core-lib/session';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState, ArableSessionState } from '../shared/models';

export const selectApp = createFeatureSelector<AppState>('app');

export const selectUserSession = createFeatureSelector<{ user?: { customerId?: number } }>(
  'session',
);

export const selectSessionCustomerId = createSelector(
  selectUserSession,
  (session) => session?.user?.customerId,
);

export const selectArableSession = createSelector(
  SessionSelectors.selectSessionState,
  (session: ArableSessionState) => session?.arable,
);

export const getDefaultCropYear = createSelector(selectApp, (store) => store.defaultCropYear);

export const getCropYear = createSelector(
  selectArableSession,
  getDefaultCropYear,
  (arable, defaultYear) => (arable?.cropYear ? arable.cropYear : defaultYear),
);

export const getAvailableCropYears = createSelector(
  selectApp,
  (store) => store.availableCropYears || [],
);
