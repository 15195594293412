<div *ngrxLet="selectedCrops$ as selectedCrops" data-test="information" class="information col-7">
  <form
    *ngIf="selectedCustomer"
    [formGroup]="informationForm"
    (ngSubmit)="save()"
    class="spacing--margin-vertical spacing--padding-top flex--container layout--padding-half-left"
  >
    <mat-form-field class="full-width-field">
      <mat-label>{{ 'pick-up.address' | translate }}</mat-label>
      <mat-select
        placeholder="{{ 'pick-up.address' | translate }}"
        formControlName="address"
        data-test="information__address"
      >
        <mat-option *ngFor="let shipTo of selectedCustomer.shipTos" [value]="shipTo">
          {{ selectedCustomer.name }}, {{ shipTo.street }}, {{ selectedCustomer.city }} ({{
            shipTo.externalId
          }})
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width-field">
      <mat-label>{{ 'pick-up.pick-up-location' | translate }}</mat-label>
      <mat-select
        placeholder="{{ 'pick-up.pick-up-location' | translate }}"
        formControlName="pickUpLocation"
        data-test="information__pickUpLocation"
      >
        <mat-option *ngFor="let shipTo of selectedCustomer.shipTos" [value]="shipTo">
          {{ shipTo.street }}, {{ selectedCustomer.city }} ({{ shipTo.externalId }})
        </mat-option>
        <mat-option [value]="newLocationValue">
          {{ 'pick-up.new-location' | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="isAddNewLocation" formGroupName="newLocation">
      <mat-form-field class="full-width-field">
        <mat-label>{{ 'pick-up.address-description' | translate }}</mat-label>
        <input
          formControlName="name"
          matInput
          placeholder=""
          type="text"
          data-test="information__addressDescription"
        />
      </mat-form-field>
      <div class="flex--row flex--gutter">
        <div class="flex__child flex__child-2">
          <mat-form-field class="full-width-field">
            <mat-label>{{ 'pick-up.street' | translate }}</mat-label>
            <input
              formControlName="street"
              matInput
              placeholder=""
              type="text"
              data-test="information__street"
            />
          </mat-form-field>
        </div>
        <div class="flex__child">
          <mat-form-field class="full-width-field">
            <mat-label>{{ 'pick-up.number' | translate }}</mat-label>
            <input
              formControlName="houseNumber"
              matInput
              placeholder=""
              type="number"
              data-test="information__number"
            />
          </mat-form-field>
        </div>
        <div class="flex__child">
          <mat-form-field class="full-width-field">
            <mat-label>{{ 'pick-up.additional' | translate }}</mat-label>
            <input
              formControlName="houseNumberAddition"
              matInput
              placeholder=""
              type="additional"
              data-test="information__additional"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="flex--row flex--gutter">
        <div class="flex__child">
          <mat-form-field class="full-width-field">
            <mat-label>{{ 'pick-up.postal-code' | translate }}</mat-label>
            <input
              formControlName="postalCode"
              matInput
              placeholder=""
              type="text"
              data-test="information__postalCode"
            />
            <mat-error *ngIf="informationForm.controls.newLocation.get('postalCode').invalid">
              {{ 'pick-up.error-postal-code' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="flex__child flex__child-3">
          <mat-form-field class="full-width-field">
            <mat-label>{{ 'pick-up.city' | translate }}</mat-label>
            <input
              formControlName="city"
              matInput
              placeholder=""
              type="text"
              data-test="information__city"
            />
          </mat-form-field>
        </div>
      </div>
    </div>

    <mat-form-field class="full-width-field">
      <mat-label>{{ 'pick-up.email' | translate }}</mat-label>
      <input
        formControlName="email"
        matInput
        placeholder=""
        type="email"
        data-test="information__email"
      />
    </mat-form-field>

    <mat-form-field class="full-width-field">
      <mat-label>{{ 'pick-up.additional-email' | translate }}</mat-label>
      <input
        formControlName="additionalEmail"
        matInput
        placeholder=""
        type="email"
        data-test="information__additionalEmail"
      />
      <mat-error *ngIf="informationForm.controls.additionalEmail.invalid">
        {{ 'pick-up.error-additional-email' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width-field">
      <mat-label>{{ 'pick-up.phone' | translate }}</mat-label>
      <input
        formControlName="phone"
        matInput
        placeholder=""
        type="text"
        data-test="information__phone"
      />
      <mat-error *ngIf="informationForm.controls.phone.invalid">
        {{ 'pick-up.error-phone' | translate }}
      </mat-error>
    </mat-form-field>

    <div class="mat-checkbox-row-wrapper">
      <div class="mat-checkbox-row">
        <mat-checkbox
          data-test="information__customerLoads"
          formControlName="customerLoads"
          color="primary"
        >
          {{ 'pick-up.customer-loads' | translate }}
        </mat-checkbox>
      </div>
    </div>
    <div class="mat-checkbox-row-wrapper">
      <div class="mat-checkbox-row">
        <mat-checkbox
          data-test="information__insideStorage"
          formControlName="insideStorage"
          color="primary"
        >
          {{ 'pick-up.inside-storage' | translate }}
        </mat-checkbox>
      </div>
    </div>

    <mat-divider class="spacing--padding-bottom"></mat-divider>
    <!-- Crop details form -->
    <h3 class="text--h3 text--light">{{ 'pick-up.crop' | translate }}</h3>
    <app-pick-up-crop-info (cropFormEvent)="setCropForm($event)"></app-pick-up-crop-info>
    <!-- End of crop details form -->

    <div class="flex--row spacing--padding-top">
      <h3 class="text--h3 text--light">{{ 'pick-up.add-additional-crop' | translate }}</h3>
      <div class="flex--align-center spacing--padding-left">
        <button
          mat-flat-button
          (click)="loadAdditionalCropFormComponent()"
          [disabled]="disableAddCropBtn()"
          color="accent"
          data-test="done-adding-product"
          type="button"
        >
          {{ 'add' | translate }}
        </button>
      </div>
    </div>
    <app-pick-up-crop-info
      (cropFormEvent)="setAdditionalCropForm($event)"
      *ngIf="additionalCropFormComponentLoaded"
    ></app-pick-up-crop-info>

    <!-- List of additional added crops -->
    <mat-list
      *ngIf="selectedCrops"
      data-test="information__crops-list"
      class="spacing--margin-bottom"
    >
      <ng-container *ngFor="let selectedCrop of selectedCrops; let i = index">
        <mat-list-item data-test="information__cropRow">
          <div
            matLine
            class="flex--align-self-flex-start flex--align-center flex--justify-space-between full-width"
            style="display: flex; align-items: center"
          >
            <div class="flex--align-self-flex-start flex--align-center column-gap">
              <mat-icon matListIcon svgIcon="ship-grain"></mat-icon>
              <span>{{ selectedCrop.crop.method }}</span>
              <span *ngIf="selectedCrop.species">{{ selectedCrop.species.species }}</span>
              <span> {{ selectedCrop.quantity }} t </span>
            </div>
            <div style="display: flex; align-items: center">
              <button
                mat-icon-button
                type="button"
                (click)="removeCrop(i)"
                data-test="information__removeCrop"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </mat-list-item>
      </ng-container>
    </mat-list>

    <mat-divider class="spacing--padding-bottom"></mat-divider>

    <mat-form-field class="full-width-field">
      <mat-label>{{ 'pick-up.remarks' | translate }}</mat-label>

      <textarea matInput formControlName="remarks" data-test="information__remarks"></textarea>
    </mat-form-field>

    <div class="spacing--margin-vertical spacing--padding-bottom">
      <button
        [disabled]="
          informationForm.invalid ||
          cropForm?.invalid ||
          (isAddNewLocation && informationForm.controls.newLocation.invalid)
        "
        mat-flat-button
        color="primary"
        data-test="information__save"
        type="submit"
      >
        {{ 'pick-up.confirmation' | translate }}
      </button>
    </div>
  </form>
</div>
