import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { SiteState } from '@next/core-lib/site';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, shareReplay, startWith, takeUntil } from 'rxjs/operators';
import {
  Cultivation,
  CultivationMethod,
} from 'src/app/registration/models/grain-registration.model';
import { getAllGrainCultivations } from 'src/app/registration/store/actions/registration.actions';
import { selectCultivations } from 'src/app/registration/store/selectors/registration.selectors';

@Component({
  selector: 'app-pick-up-crop-info',
  templateUrl: './pick-up-crop-info.component.html',
})
export class PickUpCropInfoComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  filteredCultivationMethods$: Observable<CultivationMethod[]>;
  availableCultivationMethods: CultivationMethod[];
  cultivations: Cultivation[];

  @Output() cropFormEvent: EventEmitter<FormGroup> = new EventEmitter();
  cropForm = new FormGroup({
    cultivation: new FormControl(null, [Validators.required]),
    cultivationMethod: new FormControl(null, [Validators.required, this.requireMatch.bind(this)]),
    species: new FormControl(null),
    quantity: new FormControl('', [Validators.required, Validators.min(0)]),
  });

  cultivationMethod$: Observable<CultivationMethod> =
    this.cropForm.controls.cultivationMethod.valueChanges.pipe(shareReplay(1));

  showSpecies$ = this.cultivationMethod$.pipe(
    map((cultivationMethod) => cultivationMethod?.species?.length > 0),
  );

  constructor(private readonly siteStore: Store<SiteState>) {}

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.siteStore.dispatch(getAllGrainCultivations());
    this.selectCultivations();
    this.setFilteredCultivationMethods();
    this.onCultivationChanged();
    this.cropFormEvent.emit(this.cropForm);
    this.cropForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.cropFormEvent.emit(this.cropForm));
  }

  displayCultivationMethod(cultivationMethod?: CultivationMethod) {
    return !cultivationMethod ? '' : cultivationMethod.method;
  }

  selectCultivations(): void {
    this.siteStore
      .select(selectCultivations)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        this.cultivations = result;
      });
  }

  onCultivationChanged(): void {
    this.cropForm.controls.cultivation.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.cropForm.controls.cultivationMethod.reset();
      });
  }

  setFilteredCultivationMethods(): void {
    this.filteredCultivationMethods$ = combineLatest([
      this.cropForm.get('cultivationMethod').valueChanges.pipe(startWith('')),
      this.cropForm.get('cultivation').valueChanges.pipe(startWith('')),
    ]).pipe(
      takeUntil(this.destroyed$),
      map(([input, cultivationMethodId]: [string | CultivationMethod, string]) => {
        if (this.cultivations && cultivationMethodId) {
          const cultivationMethods = (this.availableCultivationMethods = this.cultivations.find(
            (x) => x.id === cultivationMethodId,
          ).cultivationMethods);

          if (!cultivationMethods) {
            return [];
          }

          const filterValue = typeof input === 'string' ? input : input?.method ?? '';
          return filterValue
            ? this.filterCultivationMethods(filterValue, cultivationMethods)
            : cultivationMethods.slice();
        }
      }),
    );
  }

  private requireMatch(control: FormControl): ValidationErrors | null {
    const selection: CultivationMethod = control.value;
    if (
      this.availableCultivationMethods &&
      this.availableCultivationMethods.indexOf(selection) < 0
    ) {
      return { requireMatch: true };
    }
    return null;
  }

  private filterCultivationMethods(method: string, cultivationMethods: CultivationMethod[]) {
    return cultivationMethods.filter(
      (option) => option.method.toLowerCase().indexOf(method.toLowerCase()) === 0,
    );
  }
}
