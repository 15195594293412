import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule, Routes, mapToCanActivate } from '@angular/router';
import {
  AgrBlockSpinnerModule,
  AgrFilterModule,
  AgrNotificationEmptyModule,
  AgrPageDetailModule,
  AgrPageToolbarModule,
  AgrValueRowModule,
} from '@next/core-lib/components';
import { AgrFormModule } from '@next/core-lib/form';
import { AgrExportModule } from '@next/core-lib/export';
import { CustomerGuard } from '@next/core-lib/relationships';
import { TableV2Module } from '@next/core-lib/table-v2';
import { SharedModule } from '../shared/shared.module';
import { TableActionsButtonModule } from '../shared/table-actions-button/table-actions-button.module';
import { PickUpRegistrationComponent } from './components/pick-up-registration/pick-up-registration.component';
import { PickUpComponent } from './components/pick-up/pick-up.component';
import { PickUpRegistrationInfoComponent } from './components/pick-up-registration-info/pick-up-registration-info.component';
import { reducer } from './store/reducers/pick-up.reducer';
import { StoreModule } from '@ngrx/store';
import { LetDirective, PushPipe } from '@ngrx/component';
import { MatListModule } from '@angular/material/list';
import { PickUpRegistrationConfirmComponent } from './components/pick-up-registration-confirm/pick-up-registration-confirm.component';
import { PickUpEffects } from './store/effects/pick-up.effects';
import { EffectsModule } from '@ngrx/effects';
import { AgrNotificationModule } from '@next/core-lib/notification';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AgrDialogModule } from '@next/core-lib/dialog';
import { SingleInputDialogComponent } from './components/pick-up/pick-up-overview/components/single-input-dialog.component';
import { PickUpGuard } from './guards/pickup.guard';
import { OverviewTableComponent } from './components/pick-up/pick-up-overview/overview-table/overview-table.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PickUpCropInfoComponent } from './components/pick-up-registration-info/pick-up-crop-info/pick-up-crop-info.component';

const registrationChildren: Routes = [
  {
    path: '',
    redirectTo: 'information',
    pathMatch: 'full',
  },
  { path: 'information', component: PickUpRegistrationInfoComponent },
  { path: 'confirm', component: PickUpRegistrationConfirmComponent },
];

@NgModule({
  declarations: [
    PickUpComponent,
    PickUpRegistrationComponent,
    PickUpRegistrationInfoComponent,
    PickUpRegistrationConfirmComponent,
    PickUpCropInfoComponent,
    OverviewTableComponent,
    SingleInputDialogComponent,
  ],

  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature('pickUpModule', reducer),
    EffectsModule.forFeature([PickUpEffects]),
    RouterModule.forChild([
      { path: 'pick-up', component: PickUpComponent, canActivate: mapToCanActivate([PickUpGuard]) },
      {
        path: 'pick-up/registration',
        component: PickUpRegistrationComponent,
        children: registrationChildren,
        canActivate: mapToCanActivate([CustomerGuard]),
      },
    ]),
    LetDirective,
    PushPipe,
    FormsModule,
    ReactiveFormsModule,
    AgrPageToolbarModule,
    AgrValueRowModule,
    AgrFilterModule,
    AgrPageDetailModule,
    AgrFormModule,
    AgrNotificationModule,
    AgrNotificationEmptyModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatInputModule,
    MatCardModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatListModule,
    MatInputModule,
    MatCheckboxModule,
    MatOptionModule,
    MatSelectModule,
    TableV2Module,
    MatSortModule,
    AgrExportModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatSlideToggleModule,
    AgrNotificationEmptyModule,
    MatFormFieldModule,
    TableActionsButtonModule,
    MatToolbarModule,
    MatTooltipModule,
    AgrDialogModule,
    MatProgressSpinnerModule,
    AgrBlockSpinnerModule,
  ],
  exports: [],
})
export class PickUpModule {}
