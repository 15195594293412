import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentInjector } from '@next/core-lib/environment';
import { GrainRegistrationItem } from '../models/grain-registration.model';
import { Environment } from '../../../environment';

@Injectable({
  providedIn: 'root',
})
export class GrainRegistrationService {
  // change the base url to point to the according service name
  private baseUrl = `${this.env.API_BASE_URL}/service/grain-registration`;
  constructor(private http: HttpClient, @Inject(EnvironmentInjector) private env: Environment) {}

  getRegistrationHistory(cropYear: number) {
    return this.http.get<Array<GrainRegistrationItem>>(
      `${this.baseUrl}/secure/registration/${cropYear}`,
      {},
    );
  }

  getRegistrationHistoryDocument(id: string) {
    return this.http.get(`${this.baseUrl}/secure/blobstorage/${id}`, {
      responseType: 'blob',
      observe: 'response',
    });
  }
}
