import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AgrProcessEnv } from '@next/core-lib/environment';

import { AppBrowserModule } from './app/app.browser.module';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    AGR_ENV_PROD: AgrProcessEnv;
  }
}

if (window.AGR_ENV_PROD.PRODUCTION) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppBrowserModule)
  .catch((err) => console.error(err));
