import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { RouterSelectors, MergedRoute } from '@next/core-lib/router-store';
import { RegistrationState } from './models/registration.model';
import { cancelRegistration } from './store/actions/registration.actions';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
})
export class RegistrationComponent {
  routeUrl$ = this.store.pipe(
    select(RouterSelectors.getMergedRoute),
    map((route: MergedRoute) => route.url),
  );
  isInformation$ = this.routeUrl$.pipe(map((url) => url === '/registration'));
  isBatches$ = this.routeUrl$.pipe(map((url) => url.indexOf('batches') > 0));
  isComplete$ = this.routeUrl$.pipe(map((url) => url.indexOf('complete') > 0));

  constructor(private store: Store<RegistrationState>) {}

  back() {
    this.store.dispatch(cancelRegistration());
  }
}
