import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccessRoleTypes, AccessRule } from '@next/core-lib';
import { AuthorizationSelectors } from '@next/core-lib/authorization';
import { FilterConfig, FiltersConfig, FilterTypes } from '@next/core-lib/components';
import { EnvironmentInjector } from '@next/core-lib/environment';
import { SiteState } from '@next/core-lib/site';
import { select, Store } from '@ngrx/store';
import { ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { SetCropYear } from 'src/app/store/actions';
import * as AppSelectors from 'src/app/store/selectors';
import { Environment } from 'src/environment';
import { savePickupsRequest, updatePickupList } from '../../store/actions/pickup.actions';

@Component({
  selector: 'app-pick-up',
  templateUrl: './pick-up.component.html',
})
export class PickUpComponent implements OnInit, OnDestroy {
  isInMaintenanceMode = false;
  isEditAllPickupsMode = false;
  private readonly cropYearChanged$ = new Subject<number>();
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  cropYear$ = this.store.pipe(select(AppSelectors.getCropYear));
  defaultCropYear$ = this.store.pipe(select(AppSelectors.getDefaultCropYear));
  availableCropYears$ = this.store.pipe(select(AppSelectors.getAvailableCropYears));
  userRole$ = this.store.pipe(
    select(AuthorizationSelectors.selectAccessRuleForApplicationKey('GrainPickUp')),
  );

  filterConfig: FilterConfig<FiltersConfig> = {
    filters: {
      year: {
        label: 'year',
        type: FilterTypes.Select,
        options: this.availableCropYears$.pipe(
          map((availableYears) =>
            availableYears.map((availableYear) => ({
              value: availableYear,
              viewValue: availableYear.toString(),
            })),
          ),
        ),
        value: this.cropYear$,
        defaultValue: this.defaultCropYear$,
        changes: this.cropYearChanged$,
      },
    },
  };

  constructor(
    private store: Store<SiteState>,
    private router: Router,
    private ngZone: NgZone,
    @Inject(EnvironmentInjector) private readonly env: Environment,
  ) {}

  ngOnInit(): void {
    this.isInMaintenanceMode = this.env.MAINTENANCE_MODE;
    this.cropYearChanged();
  }

  cropYearChanged(): void {
    this.cropYearChanged$
      .pipe(withLatestFrom(this.cropYear$), takeUntil(this.destroyed$))
      .subscribe(([newYear, existingYear]) => {
        if (newYear && newYear !== existingYear) {
          this.store.dispatch(new SetCropYear(newYear));
          this.store.dispatch(updatePickupList());
        }
      });
  }

  onNavigationAddPickUp() {
    this.ngZone.run(() => this.router.navigate(['/pick-up', 'registration'])).then();
  }

  canEditPickups$ = this.userRole$.pipe(
    map((userRole: AccessRule | undefined) => {
      if (
        userRole &&
        (userRole.RoleKey === AccessRoleTypes.Administrator ||
          userRole.RoleKey === AccessRoleTypes.GlobalAdministrator ||
          userRole.RoleKey === AccessRoleTypes.Transporter)
      ) {
        return true;
      }

      return false;
    }),
  );

  secondaryActionIcon() {
    return !this.isEditAllPickupsMode ? 'edit' : 'save';
  }

  editAllPickUpsToggle() {
    this.isEditAllPickupsMode = !this.isEditAllPickupsMode;
  }

  editOrSavePickUps() {
    this.editAllPickUpsToggle();
    if (!this.isEditAllPickupsMode) {
      this.store.dispatch(savePickupsRequest());
    }
  }

  get transporterRole() {
    return AccessRoleTypes.Transporter;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
