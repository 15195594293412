/* eslint-disable max-classes-per-file, @typescript-eslint/no-shadow */
import { Action } from '@ngrx/store';

export enum AppActionTypes {
  SetCropYear = '[App] Set crop year',
  GetDefaultCropYear = '[App] Get default crop year',
  SetDefaultCropYear = '[App] Set default crop year',
  CropYearChanged = '[App] Crop year changed',
  ContextChanged = '[App] Context changed',
  LoadCustomerData = '[App] Load customer data',
  SetAvailableCropYears = '[App] Set available crop year',
}

export class GetDefaultCropYear implements Action {
  readonly type = AppActionTypes.GetDefaultCropYear;
}

export class SetDefaultCropYear implements Action {
  readonly type = AppActionTypes.SetDefaultCropYear;
  constructor(public readonly cropYear: number) {}
}

export class SetCropYear implements Action {
  readonly type = AppActionTypes.SetCropYear;
  constructor(public readonly cropYear: number) {}
}

export class CropYearChanged implements Action {
  readonly type = AppActionTypes.CropYearChanged;
  constructor(public readonly cropYear: number) {}
}

export class ContextChanged implements Action {
  readonly type = AppActionTypes.ContextChanged;
}

export class LoadCustomerData implements Action {
  readonly type = AppActionTypes.LoadCustomerData;
}

export class SetAvailableCropYears implements Action {
  readonly type = AppActionTypes.SetAvailableCropYears;
  constructor(public readonly availableCropYears: number[]) {}
}

// export the combined type of all actions
export type AppActions =
  | SetCropYear
  | GetDefaultCropYear
  | SetDefaultCropYear
  | CropYearChanged
  | ContextChanged
  | SetAvailableCropYears;
