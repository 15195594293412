<form [formGroup]="cropForm" class="spacing--margin-vertical spacing--padding-top flex--container">
  <mat-form-field class="full-width-field">
    <mat-label>{{ 'information.cultivation' | translate }}</mat-label>
    <mat-select
      placeholder="{{ 'information.cultivation' | translate }}"
      formControlName="cultivation"
      data-test="information__pickUpLocation"
    >
      <mat-option *ngFor="let cultivation of cultivations" [value]="cultivation.id">
        {{ cultivation.cultivation }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="full-width-field">
    <mat-label>{{ 'pick-up.crop' | translate }}</mat-label>
    <input
      type="text"
      [placeholder]="'pick-up.crop' | translate"
      matInput
      formControlName="cultivationMethod"
      [matAutocomplete]="cultivationMethodAutocomplete"
      data-test="information__product"
    />
    <mat-autocomplete
      #cultivationMethodAutocomplete="matAutocomplete"
      [displayWith]="displayCultivationMethod"
    >
      <mat-option
        *ngFor="let option of filteredCultivationMethods$ | async"
        [value]="option"
        data-test="information__cultivation-method-option"
      >
        {{ option.method }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field *ngIf="showSpecies$ | async" class="full-width-field">
    <mat-label>{{ 'batch-configuration.species' | translate }}</mat-label>
    <mat-select
      placeholder="{{ 'batch-configuration.species' | translate }}"
      formControlName="species"
      data-test="information__species"
    >
      <mat-option
        *ngFor="let species of cropForm.controls.cultivationMethod.value.species"
        [value]="species"
      >
        {{ species.species }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="full-width-field">
    <mat-label>{{ 'pick-up.quantity' | translate }}</mat-label>
    <input
      formControlName="quantity"
      matInput
      placeholder=""
      type="number"
      data-test="information__quantity"
    />
  </mat-form-field>
</form>
