import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  AbstractDialogResultData,
  AbstractFormDialog,
  AbstractFormDialogData,
} from '@next/core-lib/dialog';

export interface SingleInputDialogModel extends AbstractFormDialogData {
  input: string;
}

@Component({
  selector: 'app-single-input-dialog',
  templateUrl: './single-input-dialog.component.html',
  styleUrls: ['./single-input-dialog.component.scss'],
})
export class SingleInputDialogComponent extends AbstractFormDialog<{
  input: string;
}> {
  formGroup = this.formBuilder.group({
    input: this.data.input,
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SingleInputDialogModel,
    private readonly formBuilder: FormBuilder,
    protected mdDialogRef: MatDialogRef<
      SingleInputDialogComponent,
      AbstractDialogResultData<{ input: string }>
    >,
  ) {
    super();
  }
}
