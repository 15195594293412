/* eslint-disable brace-style */
import { formatDate } from '@angular/common';
import { CollectionViewer } from '@angular/cdk/collections';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import {
  AgrDataSource,
  ExportableDataSource,
  FilterableDataSource,
  PaginatedDataSource,
  PaginationModel,
  SortableDataSource,
  Sorting,
} from '@next/core-lib/table-v2';
import {
  ApiContactInformation,
  ApiPickUpInformation,
  CropForPickupOverview,
  PickUp,
  PickUpColumnTypes,
  PickUpDetail,
  PickUpForOverview,
  PickUpOverviewDataFilters,
  PickUpViewModel,
} from 'src/app/pick-up/models/pick-up.model';
import { PageAndSize } from 'src/app/pick-up/models/paginated-pickups.model';
import { MatTableDataSource } from '@angular/material/table';
import { SortDirection } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { SiteState } from '@next/core-lib/site';
import { setPickupListToBeSaved } from 'src/app/pick-up/store/actions/pickup.actions';
import { AuthorizationSelectors } from '@next/core-lib/authorization';
import { selectPickupList } from 'src/app/pick-up/store/selectors/pick-up.selectors';
import { AccessRoleTypes } from '@next/core-lib';
import * as pickupActions from 'src/app/pick-up/store/actions/pickup.actions';
import { LocaleService } from '@next/core-lib/i18n';

export class PickUpOverviewDataSource
  extends AgrDataSource<PickUpForOverview>
  implements
    ExportableDataSource<PickUpForOverview>,
    PaginatedDataSource<PickUpForOverview>,
    SortableDataSource<PickUpForOverview>,
    FilterableDataSource<PickUpOverviewDataFilters, PickUpForOverview>
{
  private readonly filtersSubject = new ReplaySubject<PickUpOverviewDataFilters>(1);
  private readonly sortingSubject = new BehaviorSubject<Sorting>({});
  private readonly pageAndSizeSubject = new BehaviorSubject<PageAndSize>({
    pageIndex: 0,
    pageSize: 25,
  });
  private readonly destroyPaginatorSubject = new Subject<void>();
  private readonly destroySubject = new Subject<void>();
  private result: PickUpViewModel[] = [];

  public data$ = new BehaviorSubject<PickUpViewModel[]>([]);
  private userRole$ = this.store.pipe(
    select(AuthorizationSelectors.selectAccessRuleForApplicationKey('GrainPickUp')),
  );
  private pickUpList$ = this.store.select(selectPickupList);

  constructor(private store: Store<SiteState>, private localeService: LocaleService) {
    super();

    this.userRole$.subscribe((userRole) => {
      if (userRole && userRole.RoleKey === AccessRoleTypes.Transporter) {
        this.store.dispatch(pickupActions.getPickupListByTransporter());
      }
      if (
        userRole &&
        (userRole.RoleKey === AccessRoleTypes.GlobalAdministrator ||
          userRole.RoleKey === AccessRoleTypes.Administrator)
      ) {
        this.store.dispatch(pickupActions.getPickupListByCropYear());
      }
      if (
        userRole &&
        (userRole.RoleKey === AccessRoleTypes.Advisor ||
          userRole.RoleKey === AccessRoleTypes.Customer)
      ) {
        this.store.dispatch(pickupActions.getPickupListByCustomer());
      }
    });
    this.pickUpList$.pipe(takeUntil(this.destroySubject)).subscribe((result) => {
      if (result && result.length > 0) {
        this.addPickupsWithMultipleCrops(result);
      } else {
        this.data$.next([]);
        this.loadingSubject.next(false);
      }
    });
  }

  connect(_: CollectionViewer): Observable<PickUpForOverview[]> {
    return combineLatest([
      this.data$,
      this.pageAndSizeSubject,
      this.sortingSubject,
      this.filtersSubject,
    ]).pipe(
      takeUntil(this.destroySubject),
      map(([list, paginator, sorting, filtering]) => ({ list, paginator, sorting, filtering })),
      map(({ list, paginator, sorting, filtering }) => ({
        data: list.slice(
          paginator.pageIndex * paginator.pageSize,
          paginator.pageIndex * paginator.pageSize + paginator.pageSize,
        ),
        sorting,
        filtering,
      })),
      map(({ data, sorting, filtering }) => {
        const sortEntries = Object.entries(sorting);
        if (sortEntries.length > 0) {
          const [key, direction] = sortEntries[0];

          if (direction !== '') {
            this.sortData(data, key, direction);
          }
        } else {
          this.sortData(data, PickUpColumnTypes.updated, 'desc');
        }

        if (filtering.search?.length > 0) {
          const dataToBeFiltered = new MatTableDataSource(this.result);
          dataToBeFiltered.filter = filtering.search?.trim().toLowerCase();
          if (sortEntries.length > 0) {
            const [key, direction] = sortEntries[0];
            this.sortData(dataToBeFiltered.filteredData, key, direction);
          }

          return dataToBeFiltered.filteredData;
        }

        return data;
      }),
    );
  }

  disconnect(collectionViewer: CollectionViewer): void {
    super.disconnect(collectionViewer);
    if (!this.destroySubject.isStopped) {
      this.destroySubject.next();
      this.destroySubject.complete();
    }
  }

  connectPaginator(): Observable<PaginationModel> {
    return this.pageAndSizeSubject.pipe(
      takeUntil(this.destroyPaginatorSubject),
      map(({ pageIndex, pageSize }) => ({
        pageSize,
        pageIndex,
        length: this.data$.value.length,
      })),
    );
  }

  disconnectPaginator(): void {
    if (!this.destroyPaginatorSubject.isStopped) {
      this.destroyPaginatorSubject.next();
      this.destroyPaginatorSubject.complete();
    }
  }

  applySorting(sorting: Sorting): void {
    this.sortingSubject.next(sorting);
  }

  applyFilters(filters: PickUpOverviewDataFilters) {
    this.filtersSubject.next(filters);
  }

  applyPage(pageIndex: number, pageSize: number): void {
    this.pageAndSizeSubject.next({ pageIndex, pageSize });
  }

  resetPagination(): void {
    this.pageAndSizeSubject
      .pipe(take(1))
      .subscribe(({ pageSize }) => this.pageAndSizeSubject.next({ pageIndex: 0, pageSize }));
  }

  prepareExportData() {
    this.sortData(this.data$.value, PickUpColumnTypes.id, 'desc');
    return this.data$;
  }

  onChanges(changedPickup: PickUpViewModel) {
    const changedPickupList = [...this.result];
    changedPickupList
      .sort((p1, p2) => p2.created.valueOf() - p1.created.valueOf())
      .map((pickup) => {
        if (
          changedPickup.id === pickup.id &&
          (JSON.stringify(pickup) !== JSON.stringify(changedPickup) ||
            changedPickup.hasPendingChanges)
        ) {
          pickup.status = changedPickup.status;
          pickup.internalNotebook = changedPickup.internalNotebook;
          //Map pickup information
          pickup.pickUpInformation.remarks = changedPickup.remarks;
          pickup.pickUpInformation.isInsideStorage =
            changedPickup.pickUpInformation.isInsideStorage;
          pickup.pickUpInformation.isCustomerLoading =
            changedPickup.pickUpInformation.isCustomerLoading;
        }
        return pickup;
      });
    this.result = changedPickupList;
    this.data$.next(this.result);
    this.setPickupsToBeSaved(changedPickupList.filter((pickup) => pickup.hasPendingChanges));
  }

  setPickupsToBeSaved(changedPickupList: PickUpViewModel[]) {
    const pickupListToBeSaved: PickUp[] = [];
    changedPickupList.forEach((originalPickup) => {
      const pickUpForOverview = this.result.find((x) => x.id === originalPickup.id);
      if (pickUpForOverview) {
        pickUpForOverview.pickUpInformation.crops.forEach((crop) => {
          const originalCropInformation = this.data$.value.find(
            (cropFromOverview) =>
              cropFromOverview.id === pickUpForOverview.id &&
              cropFromOverview.cropInformation.cultivationId === crop.cultivationId &&
              cropFromOverview.cropInformation.cultivationMethodId === crop.cropId,
          );
          if (originalCropInformation) {
            crop.quantity = +originalCropInformation.cropInformation.ton;
          }
        });
        const pickupToSave: PickUp = {
          id: pickUpForOverview.id,
          document: {
            year: pickUpForOverview.year,
            displayId: pickUpForOverview.displayId,
            customerId: pickUpForOverview.customerId,
            transporterExternalId: pickUpForOverview.transporterExternalId,
            status: pickUpForOverview.status,
            internalNotebook: pickUpForOverview.internalNotebook,
            pickupInformation: {
              isCustomerLoading: pickUpForOverview.pickUpInformation.isCustomerLoading,
              isInsideStorage: pickUpForOverview.pickUpInformation.isInsideStorage,
              pickUpLocation: pickUpForOverview.pickUpInformation.pickUpLocation,
              crops: pickUpForOverview.pickUpInformation.crops,
              remarks: pickUpForOverview.remarks,
            } as ApiPickUpInformation,
            contactInformation: {
              phoneNumber: pickUpForOverview.contactInformation.phoneNumber,
              emailAddress: pickUpForOverview.contactInformation.emailAddress,
              additionalEmailAddresses:
                pickUpForOverview.contactInformation.additionalEmailAddresses,
              address: pickUpForOverview.contactInformation.address,
            } as ApiContactInformation,
          } as PickUpDetail,
        };
        if (pickupListToBeSaved.filter((pickup) => pickup.id === pickupToSave.id).length === 0) {
          pickupListToBeSaved.push(pickupToSave);
        }
      }
    });

    this.store.dispatch(setPickupListToBeSaved({ pickUpList: pickupListToBeSaved }));
  }

  private addPickupsWithMultipleCrops(pickupList: PickUpViewModel[]) {
    const tempList = pickupList.map((pickup: PickUpViewModel) => {
      if (!pickup.cropInformation) {
        pickup.cropInformation = {
          cultivationId: pickup.pickUpInformation.crops[0].cultivationId,
          cultivationMethodId: pickup.pickUpInformation.crops[0].cropId,
          ton: pickup.pickUpInformation.crops[0].quantity,
          speciesId: pickup.pickUpInformation.crops[0].speciesId,
        } as CropForPickupOverview;

        for (let i = 1; i < pickup.pickUpInformation.crops.length; i++) {
          const newPickup = { ...pickup };
          newPickup.cropInformation = {
            cultivationId: pickup.pickUpInformation.crops[i].cultivationId,
            cultivationMethodId: pickup.pickUpInformation.crops[i].cropId,
            ton: pickup.pickUpInformation.crops[i].quantity,
            speciesId: pickup.pickUpInformation.crops[0].speciesId,
          } as CropForPickupOverview;
          pickupList.push(newPickup);
        }
      }

      return { ...pickup };
    });
    if (tempList.filter((pickup) => pickup.pickUpInformation.crops.length > 1).length > 0) {
      pickupList.sort((a: PickUpForOverview, b: PickUpForOverview) =>
        b.id.toString().localeCompare(a.id.toString()),
      );
    }

    this.result = pickupList;
    this.result.forEach((pickup) => {
      if (pickup.hasPendingChanges) {
        this.onChanges(pickup);
      }
    });
    this.formatDates(this.result);
    this.data$.next(this.result);
    this.loadingSubject.next(false);
  }

  private sortData(data: PickUpViewModel[], key: string, direction: SortDirection) {
    data // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .sort((a: any, b: any) =>
        direction === 'asc'
          ? ('' + a[key]).localeCompare('' + b[key])
          : ('' + b[key]).localeCompare('' + a[key]),
      );
  }

  private formatDates(data: PickUpViewModel[]) {
    data.forEach(
      (pickup) =>
        (pickup.displayUpdated = formatDate(
          pickup.updated,
          'dd-MM-yyyy HH:mm',
          this.localeService.getLocale(),
        )),
    );
  }
}
