import { formatDate } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AccessRoleTypes } from '@next/core-lib';
import { AuthorizationSelectors } from '@next/core-lib/authorization';
import { ExportFormat } from '@next/core-lib/export';
import { LocaleService } from '@next/core-lib/i18n';
import { ExportOptionsV2 } from '@next/core-lib/table-v2';
import { select, Store } from '@ngrx/store';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import {
  displayColumns,
  PickUpColumnTypes,
  PickUpOverviewDataFilters,
  PickUpStatuses,
  PickUpViewModel,
} from 'src/app/pick-up/models/pick-up.model';
import * as pickupActions from 'src/app/pick-up/store/actions/pickup.actions';
import { TableDisplayMode } from 'src/app/shared/models';
import { SiteState } from '@next/core-lib/site';
import { PickUpOverviewDataSource } from '../datasource/pick-up.datasource';
import { selectCultivations } from 'src/app/registration/store/selectors/registration.selectors';
import { MatTableDataSource } from '@angular/material/table';
import { getAllGrainCultivations } from 'src/app/registration/store/actions/registration.actions';
import { Cultivation } from 'src/app/registration/models/grain-registration.model';

@Component({
  selector: 'app-pickup-overview-table',
  templateUrl: './overview-table.component.html',
  styleUrls: ['./overview-table.component.scss'],
})
export class OverviewTableComponent implements OnInit, OnChanges, OnDestroy {
  pickupStatuses = PickUpStatuses;
  readonly subscriptions: Subscription[] = [];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private userRole$ = this.store.pipe(
    select(AuthorizationSelectors.selectAccessRuleForApplicationKey('GrainPickUp')),
  );
  private grainCultivations$ = this.store.select(selectCultivations);
  private cropCultivations: Cultivation[];

  readonly searchAndFilters = new FormGroup({
    search: new FormControl(''),
  });
  readonly dataSource = new PickUpOverviewDataSource(this.store, this.localeService);
  readonly columnsToDisplay = [...displayColumns, 'actions'];
  isEmpty$: Observable<boolean>;
  isEmptyText: string;
  tableDisplayMode: TableDisplayMode = TableDisplayMode.list;
  exportOptions: ExportOptionsV2;
  userCanDeleteRow = false;
  isTransporter = false;

  statusesName = new Map<string, string>([
    [PickUpStatuses.collected, 'Collected'],
    [PickUpStatuses.planned, 'Planned'],
    [PickUpStatuses.noStatus, 'No Status'],
  ]);

  @Input() isEditAllPickupsMode = false;
  @ViewChild(MatTableDataSource) tableDataSource: MatTableDataSource<PickUpViewModel[]>;

  constructor(private store: Store<SiteState>, private localeService: LocaleService) {
    this.displayColumnsByUserRole();

    const columnsToExport = [...this.columnsToDisplay];
    columnsToExport.splice(columnsToExport.indexOf('actions'), 1);
    this.exportOptions = {
      formats: [
        {
          format: ExportFormat.xlsx,
          name: 'XLSX',
        },
        {
          format: ExportFormat.csv,
          name: 'CSV',
        },
      ],
      fileName: `${this.localeService
        .translate('pick-up.page-title')
        .split(' ')
        .join('-')}-${formatDate(new Date(), 'dd-MM-yyyy', this.localeService.getLocale())}`,
      columns: columnsToExport.map((col) => ({
        id: col,
        name: this.localeService.translate(`pick-up.overview-table.${col}`),
      })),
    };

    this.applyFilters();
  }

  ngOnInit(): void {
    this.isEmpty$ = this.dataSource.data$.pipe(map((data) => data.length > 0));
    this.store.dispatch(getAllGrainCultivations());

    this.subscriptions.push(
      this.searchAndFilters.valueChanges.subscribe(() => {
        this.applyFilters();
      }),
    );

    this.getGrainCultivations();
    this.canDeleteRow();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.isEditAllPickupsMode = changes.isEditAllPickupsMode.currentValue;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  displayColumnsByUserRole() {
    this.subscriptions.push(
      this.userRole$.pipe(take(1)).subscribe((userRole) => {
        if (userRole && userRole.RoleKey !== AccessRoleTypes.Transporter) {
          const notebookInternalColumnIndex = this.columnsToDisplay.indexOf(
            PickUpColumnTypes.internalNotebook,
          );
          this.columnsToDisplay.splice(notebookInternalColumnIndex, 1);
        } else if (userRole && userRole.RoleKey === AccessRoleTypes.Transporter) {
          this.isTransporter = true;
        }
        if (userRole && userRole.RoleKey !== AccessRoleTypes.GlobalAdministrator) {
          const transporterColumnIndex = this.columnsToDisplay.indexOf(
            PickUpColumnTypes.transporterName,
          );
          this.columnsToDisplay.splice(transporterColumnIndex, 1);
        }
        if (
          userRole &&
          userRole.RoleKey !== AccessRoleTypes.Transporter &&
          userRole.RoleKey !== AccessRoleTypes.GlobalAdministrator &&
          userRole.RoleKey !== AccessRoleTypes.Administrator
        ) {
          this.removeDisplayedColumnsForAdvisor();
        }
      }),
    );
  }

  isEqual(a: PickUpViewModel, b: PickUpViewModel) {
    return a.id === b.id;
  }

  resetFilters() {
    this.searchAndFilters.reset({
      search: null,
    });
  }

  downloadPdfFromRow(row: PickUpViewModel) {
    if (!row.pdf) {
      return;
    }
    this.store.dispatch(pickupActions.getPickupRegistrationDocument({ id: row.pdf.id }));
  }

  deleteRow(row: PickUpViewModel) {
    this.store.dispatch(pickupActions.removePickupRequest({ pickupId: row.id }));
  }

  onChange(row: PickUpViewModel) {
    row.hasPendingChanges = true;
    this.dataSource.onChanges(row);
  }

  onRemarkChange(row: PickUpViewModel) {
    this.store.dispatch(
      pickupActions.openEditSingleInputDialog({
        text: row.pickUpInformation.remarks,
        rowId: row.id,
        columnType: PickUpColumnTypes.remarks,
      }),
    );
  }

  onInternalNotebookChange(row: PickUpViewModel) {
    this.store.dispatch(
      pickupActions.openEditSingleInputDialog({
        text: row.internalNotebook,
        rowId: row.id,
        columnType: PickUpColumnTypes.internalNotebook,
      }),
    );
  }

  canDeleteRow() {
    this.subscriptions.push(
      this.userRole$.subscribe((userRole) => {
        if (
          userRole &&
          (userRole.RoleKey === AccessRoleTypes.Transporter ||
            userRole.RoleKey === AccessRoleTypes.Administrator ||
            userRole.RoleKey === AccessRoleTypes.GlobalAdministrator)
        ) {
          this.userCanDeleteRow = true;
        }
      }),
    );
  }

  getGrainCultivations() {
    this.grainCultivations$.pipe(takeUntil(this.destroyed$)).subscribe((grainCultivations) => {
      this.cropCultivations = grainCultivations;
    });
  }

  mapCultivationMethod() {
    if (this.dataSource.data$.value.length > 0) {
      this.dataSource.data$.value.forEach((pickup) => {
        const cultivationMethod = this.getCultivationMethod(pickup);

        pickup.cropInformation.cultivationMethod = cultivationMethod;
        pickup.crop = cultivationMethod;
        pickup.tons = pickup.cropInformation.ton;
      });
    }
  }

  allDataIsLoaded() {
    //Once all data is loaded, we map the cultivation method only once
    if (
      this.dataSource.data$.value.length > 0 &&
      this.dataSource.data$.value.every((pickup) => !pickup.cropInformation?.cultivationMethod)
    ) {
      this.mapCultivationMethod();
    }
    return (
      this.dataSource.data$.value.length === 0 ||
      (this.dataSource.data$.value.length > 0 &&
        this.dataSource.data$.value.every(
          (pickup) => pickup.cropInformation?.cultivationMethod?.length > 0,
        ))
    );
  }

  private applyFilters() {
    this.dataSource.applyFilters(this.searchAndFilters.value as PickUpOverviewDataFilters);
  }

  private getCultivationMethod(pickup: PickUpViewModel): string {
    if (this.cropCultivations && this.cropCultivations.length > 0) {
      const cultivation = this.cropCultivations.find(
        (c) => c.id === pickup.cropInformation.cultivationId,
      );
      const cultivationMethod = cultivation?.cultivationMethods.find(
        (cm) => cm.id === pickup.cropInformation.cultivationMethodId,
      );
      if (pickup.cropInformation.speciesId) {
        const species = cultivationMethod.species.find(
          (sp) => sp.id === pickup.cropInformation.speciesId,
        );
        if (species) {
          return `${cultivationMethod.method} - ${species.species}`;
        }
      }
      if (cultivationMethod) {
        return cultivationMethod.method;
      }
    }
  }

  private removeDisplayedColumnsForAdvisor() {
    //Remove 'Status' column
    const statusColumnIndex = this.columnsToDisplay.indexOf(PickUpColumnTypes.status);
    this.columnsToDisplay.splice(statusColumnIndex, 1);
    //Remove 'Customer' column
    const customerColumnIndex = this.columnsToDisplay.indexOf(PickUpColumnTypes.customer);
    this.columnsToDisplay.splice(customerColumnIndex, 1);
    //Remove 'Pickup location' column
    const pickupLocationColumnIndex = this.columnsToDisplay.indexOf(
      PickUpColumnTypes.pickupLocation,
    );
    this.columnsToDisplay.splice(pickupLocationColumnIndex, 1);
    //Remove 'Postal code' column
    const postalCodeColumnIndex = this.columnsToDisplay.indexOf(PickUpColumnTypes.postalCode);
    this.columnsToDisplay.splice(postalCodeColumnIndex, 1);
    //Replace actions column with 'pdf'
    const actionsColumnIndex = this.columnsToDisplay.indexOf('actions');
    this.columnsToDisplay.splice(actionsColumnIndex, 1);
    this.columnsToDisplay.push('pdf');
  }
}
