import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { AccessRoleTypes, CoreActions } from '@next/core-lib';
import { AuthorizationSelectors } from '@next/core-lib/authorization';
import { DialogService, DIALOG_RETURN_TYPES } from '@next/core-lib/dialog';
import { LocaleService } from '@next/core-lib/i18n';
import { NotificationActions } from '@next/core-lib/notification';
import { UserSelectors } from '@next/core-lib/user';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY, interval, of, throwError } from 'rxjs';
import {
  switchMap,
  finalize,
  startWith,
  withLatestFrom,
  catchError,
  tap,
  mergeMap,
  concatMap,
  exhaustMap,
  map,
  debounce,
} from 'rxjs/operators';
import { GrainRegistrationService } from 'src/app/registration/services/grain-registration.service';
import { getCropYear } from 'src/app/store/selectors';
import { SingleInputDialogComponent } from '../../components/pick-up/pick-up-overview/components/single-input-dialog.component';
import {
  Address,
  ApiCropWithQuantity,
  ApiRegisterPickUpInformation,
  CropWithQuantity,
  PickUpForOverview,
  PickUpInformation,
  PickUpState,
  PickUpViewModel,
} from '../../models/pick-up.model';
import { GrainPickupService } from '../../services/grain-pickup.service';
import * as PickUpInformationActionTypes from '../actions/information.actions';
import * as PickUpActionTypes from '../actions/pickup.actions';
import { selectPickUpInformation, selectPickupListToBeSaved } from '../selectors/pick-up.selectors';
import { isPlatformServer } from '@angular/common';

@Injectable()
export class PickUpEffects {
  savePickUpRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PickUpInformationActionTypes.savePickUpRequest),
      withLatestFrom(this.store.select(selectPickUpInformation), this.store.select(getCropYear)),
      switchMap(([_, pickUpInformation, cropYear]) =>
        this.api.savePickUpRequest(cropYear, this.mapPickUpInformation(pickUpInformation)).pipe(
          switchMap(() => {
            this.router.navigate(['/pick-up']);
            return of(PickUpInformationActionTypes.resetPickUpRequest());
          }),
          catchError((error: HttpErrorResponse) =>
            of(
              PickUpInformationActionTypes.showPickUpRequestError({
                message: error.error,
                status: error.status,
              }),
            ),
          ),
          finalize(() => this.store.dispatch(CoreActions.setLoading({ loading: false }))),
          startWith(CoreActions.setLoading({ loading: true })),
        ),
      ),
    ),
  );

  showPickUpRequestError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PickUpInformationActionTypes.showPickUpRequestError),
        tap(({ status, message }) =>
          this.dialog.notify({
            title: this.locale.translate('pick-up.api-errors.dialog-header'),
            text:
              status === 400 && Array.isArray(message)
                ? message
                    .map((m) => this.locale.translate(`pick-up.api-errors.validation.${m}`))
                    .join(' ')
                : this.locale.translate('pick-up.api-errors.internal-server-saving'),
          }),
        ),
        switchMap(() => EMPTY),
      ),
    { dispatch: false },
  );

  resetPickUpRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PickUpInformationActionTypes.resetPickUpRequest),
      switchMap((_) =>
        of(
          PickUpInformationActionTypes.setInformationFormValues({
            values: {} as PickUpInformation,
          }),
        ),
      ),
    ),
  );

  getPickupListByCropYear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PickUpActionTypes.getPickupListByCropYear),
      withLatestFrom(this.store.pipe(select(getCropYear))),
      mergeMap(([_, cropYear]) =>
        this.grainPickupService.getPickUpListByCropYear(cropYear ?? new Date().getFullYear()).pipe(
          switchMap((result) =>
            of(
              PickUpActionTypes.getPickupListByCropYearToRefresh(),
              PickUpActionTypes.getPickupListSuccess({
                pickUpList: result.grainPickUpRequests.map((pickup) =>
                  this.mapPickUpsForOverview(pickup),
                ),
              }),
            ),
          ),
          catchError((error: HttpErrorResponse) =>
            error.status === 404
              ? of(
                  PickUpActionTypes.getPickupListSuccess({
                    pickUpList: [],
                  }),
                )
              : throwError(() => error),
          ),
          startWith(CoreActions.setLoading({ loading: true })),
          finalize(() => this.store.dispatch(CoreActions.setLoading({ loading: false }))),
        ),
      ),
    ),
  );

  getPickupListByTransporter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PickUpActionTypes.getPickupListByTransporter),
      withLatestFrom(
        this.store.pipe(select(UserSelectors.selectProfileId)),
        this.store.pipe(select(getCropYear)),
      ),
      mergeMap(([_, profileId, cropYear]) =>
        this.grainPickupService
          .getPickUpListByTransporter(cropYear ?? new Date().getFullYear(), profileId)
          .pipe(
            switchMap((result) =>
              of(
                PickUpActionTypes.getPickupListSuccess({
                  pickUpList: result.grainPickUpRequests.map((pickup) =>
                    this.mapPickUpsForOverview(pickup),
                  ),
                }),
              ),
            ),
            catchError((error: HttpErrorResponse) =>
              error.status === 404
                ? of(
                    PickUpActionTypes.getPickupListSuccess({
                      pickUpList: [],
                    }),
                  )
                : throwError(error),
            ),
            startWith(CoreActions.setLoading({ loading: true })),
            finalize(() => this.store.dispatch(CoreActions.setLoading({ loading: false }))),
          ),
      ),
    ),
  );

  getPickupListByCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PickUpActionTypes.getPickupListByCustomer),
      withLatestFrom(this.store.pipe(select(getCropYear))),
      mergeMap(([_, cropYear]) =>
        this.grainPickupService.getPickUpListByCustomer(cropYear ?? new Date().getFullYear()).pipe(
          switchMap((result) => {
            const hasItemsToProcess =
              result.grainPickUpRequests.filter((h) => h.pdf === null).length > 0;
            if (hasItemsToProcess) {
              return of(
                PickUpActionTypes.getPickupListByCustomerToRefresh(),
                PickUpActionTypes.getPickupListSuccess({
                  pickUpList: result.grainPickUpRequests.map((pickup) =>
                    this.mapPickUpsForOverview(pickup),
                  ),
                }),
              );
            } else {
              return of(
                PickUpActionTypes.getPickupListSuccess({
                  pickUpList: result.grainPickUpRequests.map((pickup) =>
                    this.mapPickUpsForOverview(pickup),
                  ),
                }),
              );
            }
          }),
          catchError((error: HttpErrorResponse) =>
            error.status === 404
              ? of(
                  PickUpActionTypes.getPickupListSuccess({
                    pickUpList: [],
                  }),
                )
              : throwError(error),
          ),
          startWith(CoreActions.setLoading({ loading: true })),
          finalize(() => this.store.dispatch(CoreActions.setLoading({ loading: false }))),
        ),
      ),
    ),
  );

  getPickupListByCustomerToRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PickUpActionTypes.getPickupListByCustomerToRefresh),
      withLatestFrom(this.store.pipe(select(getCropYear))),
      debounce(() => (isPlatformServer(this.platformId) ? interval(0) : interval(10000))),
      mergeMap(([_, cropYear]) =>
        this.grainPickupService.getPickUpListByCustomer(cropYear ?? new Date().getFullYear()).pipe(
          map((result) => this.sortGrainPickUpRequests(result.grainPickUpRequests)),
          switchMap((grainPickUpRequests) => {
            const hasItemsToProcess = grainPickUpRequests.filter((h) => h.pdf === null).length > 0;
            if (!isPlatformServer(this.platformId) && hasItemsToProcess) {
              return of(
                PickUpActionTypes.getPickupListByCustomerToRefresh(),
                PickUpActionTypes.getPickupListSuccess({
                  pickUpList: grainPickUpRequests.map((pickup) =>
                    this.mapPickUpsForOverview(pickup),
                  ),
                }),
              );
            } else {
              return of(
                PickUpActionTypes.getPickupListSuccess({
                  pickUpList: grainPickUpRequests.map((pickup) =>
                    this.mapPickUpsForOverview(pickup),
                  ),
                }),
              );
            }
          }),
        ),
      ),
    ),
  );

  getPickupListByCropYearToRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PickUpActionTypes.getPickupListByCropYearToRefresh),
      withLatestFrom(this.store.pipe(select(getCropYear))),
      debounce(() => (isPlatformServer(this.platformId) ? interval(0) : interval(10000))),
      mergeMap(([_, cropYear]) =>
        this.grainPickupService.getPickUpListByCropYear(cropYear ?? new Date().getFullYear()).pipe(
          map((result) => this.sortGrainPickUpRequests(result.grainPickUpRequests)),
          switchMap((grainPickUpRequests) => {
            const hasItemsToProcess = grainPickUpRequests.filter((h) => h.pdf === null).length > 0;
            if (!isPlatformServer(this.platformId) && hasItemsToProcess) {
              return of(
                PickUpActionTypes.getPickupListByCropYearToRefresh(),
                PickUpActionTypes.getPickupListSuccess({
                  pickUpList: grainPickUpRequests.map((pickup) =>
                    this.mapPickUpsForOverview(pickup),
                  ),
                }),
              );
            } else {
              return of(
                PickUpActionTypes.getPickupListSuccess({
                  pickUpList: grainPickUpRequests.map((pickup) =>
                    this.mapPickUpsForOverview(pickup),
                  ),
                }),
              );
            }
          }),
        ),
      ),
    ),
  );

  saveGrainPickups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PickUpActionTypes.savePickupsRequest),
      withLatestFrom(this.store.pipe(select(selectPickupListToBeSaved))),
      switchMap(([_, pickupList]) =>
        pickupList && pickupList.length > 0
          ? pickupList.map((pickup) => PickUpActionTypes.saveGrainPickupFromOverview({ pickup }))
          : EMPTY,
      ),
    ),
  );

  cancelPickupRegistration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PickUpInformationActionTypes.cancelPickupRequest),
        switchMap((_) =>
          this.dialog
            .confirm({
              title: this.locale.translate('registration.cancel-pickup-title'),
              text: this.locale.translate('registration.cancel-text'),
              pushHistoryState: false,
            })
            .pipe(
              tap(({ status }) => {
                if (status === DIALOG_RETURN_TYPES.confirm) {
                  this.store.dispatch(PickUpInformationActionTypes.resetPickUpRequest());
                  this.ngZone.run(() => this.router.navigate(['/pick-up'])).then();
                }
              }),
            ),
        ),
      ),
    { dispatch: false },
  );

  saveGrainPickupFromOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PickUpActionTypes.saveGrainPickupFromOverview),
      mergeMap(({ pickup }) =>
        this.grainPickupService.saveGrainPickup(pickup).pipe(
          concatMap(() =>
            of(
              PickUpActionTypes.updatePickupList(),
              PickUpActionTypes.setPickupListToBeSaved({ pickUpList: [] }),
            ),
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              PickUpInformationActionTypes.showPickUpRequestError({
                message: error.error,
                status: error.status,
              }),
            ),
          ),
          startWith(CoreActions.setLoading({ loading: true })),
          finalize(() => this.store.dispatch(CoreActions.setLoading({ loading: false }))),
        ),
      ),
    ),
  );

  updatePickupList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PickUpActionTypes.updatePickupList),
        withLatestFrom(
          this.store.pipe(
            select(AuthorizationSelectors.selectAccessRuleForApplicationKey('GrainPickUp')),
          ),
        ),
        tap(([_, userRole]) => {
          if (userRole && userRole.RoleKey === AccessRoleTypes.Transporter) {
            this.store.dispatch(PickUpActionTypes.getPickupListByTransporter());
          }
          if (
            userRole &&
            (userRole.RoleKey === AccessRoleTypes.GlobalAdministrator ||
              userRole.RoleKey === AccessRoleTypes.Administrator)
          ) {
            this.store.dispatch(PickUpActionTypes.getPickupListByCropYear());
          }
          if (
            userRole &&
            (userRole.RoleKey === AccessRoleTypes.Customer ||
              userRole.RoleKey === AccessRoleTypes.Advisor)
          ) {
            this.store.dispatch(PickUpActionTypes.getPickupListByCustomer());
          }
        }),
      ),
    { dispatch: false },
  );

  removeGrainPickup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PickUpActionTypes.removePickupRequest),
      switchMap(({ pickupId }) =>
        this.grainPickupService.removeGrainPickup(pickupId).pipe(
          switchMap(() => of(PickUpActionTypes.updatePickupList())),
          catchError((error: HttpErrorResponse) =>
            of(
              PickUpInformationActionTypes.showPickUpRequestError({
                message: error.error,
                status: error.status,
              }),
            ),
          ),
          startWith(CoreActions.setLoading({ loading: true })),
          finalize(() => this.store.dispatch(CoreActions.setLoading({ loading: false }))),
        ),
      ),
    ),
  );

  openEditSingleInputDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PickUpActionTypes.openEditSingleInputDialog),
      switchMap((input) =>
        this.dialog
          .form(SingleInputDialogComponent, {
            title: 'Edit text',
            input: input.text,
            fullscreen: false,
          })
          .pipe(
            switchMap(({ status, result }) =>
              status === DIALOG_RETURN_TYPES.confirm
                ? of(
                    PickUpActionTypes.updatePickupInList({
                      text: result.input,
                      rowId: input.rowId,
                      columnType: input.columnType,
                    }),
                  )
                : EMPTY,
            ),
          ),
      ),
    ),
  );

  getPickupRegistrationDocument$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PickUpActionTypes.getPickupRegistrationDocument),
        exhaustMap((action) => this.grainPickupService.getPickupRegistrationDocument(action.id)),
        tap((response) => {
          const contentDisposition = response.headers.get('Content-Disposition');
          const filename = contentDisposition
            .split(';')[1]
            .split('filename')[1]
            .split('=')[1]
            .trim();

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const windowNavigator = window.navigator as any;

          if (windowNavigator.msSaveOrOpenBlob) {
            windowNavigator.msSaveBlob(response.body, filename);
          } else {
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(response.body);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
          }
        }),
        map(() => PickUpActionTypes.getPickupRegistrationDocumentSuccess()),
        catchError(() => {
          const message = this.locale.translate('pick-up.overview-table.no-report-error');
          return of(
            PickUpActionTypes.getPickupRegistrationDocumentFailed(),
            NotificationActions.errorSnackbar({ message }),
          );
        }),
      ),
    { dispatch: false },
  );

  mapPickUpInformation(pickUpInformation: PickUpInformation): ApiRegisterPickUpInformation {
    return {
      contactInformation: {
        address: this.mapAddress(pickUpInformation.address),
        emailAddress: pickUpInformation.email,
        phoneNumber: pickUpInformation.phone,
        additionalEmailAddresses: pickUpInformation.additionalEmail
          ? pickUpInformation.additionalEmail
              .replace(/[ ]+/g, '')
              .split(';')
              .filter((email) => email)
          : [],
      },
      pickUpInformation: {
        pickUpLocation: this.mapAddress(pickUpInformation.pickUpLocation),
        isCustomerLoading: !!pickUpInformation.customerLoads,
        isInsideStorage: !!pickUpInformation.insideStorage,
        remarks: pickUpInformation.remarks,
        crops: pickUpInformation.crops.map((crop) => this.mapCrop(crop)),
      },
    };
  }

  mapPickUpsForOverview = (pickup: PickUpForOverview): PickUpViewModel => ({
    hasPendingChanges: false,
    remarks: pickup.pickUpInformation.remarks,
    // eslint-disable-next-line max-len
    customer: `${pickup.customerName} (${pickup.customerExternalId}), ${pickup.contactInformation.emailAddress}, ${pickup.contactInformation.phoneNumber}`,
    // eslint-disable-next-line max-len
    pickupLocation: `${pickup.pickUpInformation.pickUpLocation.street} ${pickup.pickUpInformation.pickUpLocation.houseNumber}, ${pickup.pickUpInformation.pickUpLocation.city}`,
    postalCode: pickup.pickUpInformation.pickUpLocation.postalCode,
    crop: '',
    tons: 0,
    isCustomerLoading: pickup.pickUpInformation.isCustomerLoading
      ? this.locale.translate('yes')
      : this.locale.translate('no'),
    isInsideStorage: pickup.pickUpInformation.isInsideStorage
      ? this.locale.translate('yes')
      : this.locale.translate('no'),
    internalNotebook: pickup.internalNotebook,
    transporterName: pickup.transporterName,
    ...pickup,
  });

  private mapAddress(address: Address): Address {
    let houseNumber = address?.houseNumber;
    let street = address?.street;

    if (!houseNumber) {
      const splitStreet = address.street.match(/\d+$/);
      if (splitStreet?.length) {
        houseNumber = splitStreet[0];
        street = address.street.split(/ ?\d+$/)[0];
      } else {
        houseNumber = '';
      }
    }

    return {
      city: address.city,
      houseNumber,
      houseNumberAddition: address.houseNumberAddition,
      name: address.name,
      postalCode: address.postalCode,
      street,
    };
  }

  private mapCrop(crop: CropWithQuantity): ApiCropWithQuantity {
    return {
      cropId: crop.crop.id,
      cultivationId: crop.cultivationId,
      quantity: crop.quantity,
      speciesId: crop.species?.id,
    };
  }

  private sortGrainPickUpRequests(grainPickUpRequests: PickUpForOverview[]) {
    return grainPickUpRequests.sort((h1: PickUpForOverview, h2: PickUpForOverview) => {
      h1.created = new Date(h1.created);
      h2.created = new Date(h2.created);
      return h2.created.valueOf() - h1.created.valueOf();
    });
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    protected readonly store: Store<PickUpState>,
    protected readonly api: GrainRegistrationService,
    protected readonly grainPickupService: GrainPickupService,
    protected readonly actions$: Actions,
    protected readonly router: Router,
    protected readonly dialog: DialogService,
    private locale: LocaleService,
    private ngZone: NgZone,
  ) {}
}
