import { createSelector } from '@ngrx/store';
import { selectRegistration } from '../selectors/registration.selectors';
import { hasValidInformation } from './information.selectors';
import { RegistrationState, Batch } from '../../models/registration.model';
import { RouterSelectors } from '@next/core-lib/router-store';

export const selectBatches = createSelector(selectRegistration, (state: RegistrationState) =>
  state && state.batches ? state.batches : [],
);

export const hasBatches = createSelector(selectBatches, (state: Batch[]) =>
  state ? state.length > 0 : false,
);

export const selectBatch = createSelector(selectBatches, (state: Batch[], id: string) =>
  state ? state.find((batch) => batch.id === id) : undefined,
);

export const getNextBatchId = createSelector(selectBatches, (state: Batch[]) =>
  state?.length ? String(state.length + 1) : '1',
);

export const hasValidBatchId = createSelector(
  hasValidInformation,
  selectBatches,
  (hasValidInfo: boolean, batches: Batch[], id: string) => {
    let valid = false;
    if (id) {
      valid = batches.findIndex((b) => b.id === id) > -1;
    } else {
      valid = hasValidInfo;
    }
    return valid;
  },
);

export const selectActiveBatchId = createSelector(RouterSelectors.getRouteParam('id'), (id) =>
  id ? parseInt(id, 10) : undefined,
);

export const selectActiveBatch = createSelector(
  selectBatches,
  (batches: Batch[], batchId: number) =>
    !!batches && batchId ? batches.find((b) => b.id === String(batchId)) : undefined,
);
