import { props, createAction } from '@ngrx/store';
import { CropWithQuantity, PickUpInformation } from '../../models/pick-up.model';

export const addCrop = createAction(
  '[PickUp][Information] Add crop',
  props<{ cropWithQuantity: CropWithQuantity }>(),
);

export const removeCrop = createAction(
  '[PickUp][Information] Remove crop',
  props<{ index: number }>(),
);

export const setInformationFormValues = createAction(
  '[PickUp][Information] Add information form value',
  props<{ values: PickUpInformation }>(),
);

export const savePickUpRequest = createAction('[PickUp] Save the pick up request');

export const cancelPickupRequest = createAction('[PickUp] Cancel pickup request');

export const resetPickUpRequest = createAction(
  '[PickUp] After canceling or completing the pick up request reset the state',
);

export const showPickUpRequestError = createAction(
  '[PickUp] Show PickUpRequest error',
  props<{ message: string[]; status: number }>(),
);
