import { createReducer, on, Action } from '@ngrx/store';
import * as historyActions from '../store/history.actions';
import { HistoryModuleState } from '../models/history.model';

export const initialState = [] as HistoryModuleState;

const historyReducer = createReducer(
  initialState,
  on(historyActions.getHistorySuccess, (_, { history }) => [...history]),
);

export const reducer = (state: HistoryModuleState | undefined, action: Action) =>
  historyReducer(state, action);
