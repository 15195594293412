import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: '/history',
        },
      ],
      { initialNavigation: 'enabledNonBlocking' },
    ),
  ],
})
export class AppRoutingModule {}
