import { CustomerGuard } from '@next/core-lib/relationships';
import { AuthorizationSelectors } from '@next/core-lib/authorization';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store, select } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class HistoryGuard {
  constructor(private store: Store<History>, private readonly customerGuard: CustomerGuard) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.pipe(
      select(AuthorizationSelectors.hasGlobalAdministratorRole),
      take(1),
      switchMap((isGlobalAdministrator) => {
        if (isGlobalAdministrator) {
          return of(true);
        }

        return this.customerGuard.canActivate(route, state);
      }),
    );
  }
}
