import { Injectable, NgZone } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Router } from '@angular/router';
import {
  map,
  catchError,
  switchMap,
  exhaustMap,
  take,
  tap,
  withLatestFrom,
  startWith,
  finalize,
  mergeMap,
  debounceTime,
} from 'rxjs/operators';
import { of, EMPTY } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as registrationActions from '../actions/registration.actions';
import * as batchActions from '../actions/batch.actions';
import * as informationActions from '../actions/information.actions';
import * as completeActions from '../actions/complete.actions';
import { selectInformation } from '../selectors/information.selectors';
import { RegistrationState } from '../../models/registration.model';
import { GrainRegistrationService } from '../../services/grain-registration.service';
import { CoreActions } from '@next/core-lib';
import { NotificationActions } from '@next/core-lib/notification';
import { LocaleService } from '@next/core-lib/i18n';
import { DialogService, DIALOG_RETURN_TYPES } from '@next/core-lib/dialog';
import { selectRegistrationData } from '../selectors/result.selectors';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class RegistrationEffects {
  constructor(
    private actions$: Actions,
    private dialog: DialogService,
    private router: Router,
    private store: Store<RegistrationState>,
    private api: GrainRegistrationService,
    private ngZone: NgZone,
    private locale: LocaleService,
  ) {}

  getAllGrainCultivations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(registrationActions.getAllGrainCultivations),
      mergeMap((_) =>
        this.api.getCultivations().pipe(
          map((cultivations) =>
            registrationActions.getAllGrainCultivationsSuccess({ cultivations }),
          ),
          startWith(CoreActions.setLoading({ loading: true })),
          finalize(() => this.store.dispatch(CoreActions.setLoading({ loading: false }))),
        ),
      ),
    ),
  );

  getAllGrainCertificates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(registrationActions.getAllGrainCertificates),
      mergeMap((_) =>
        this.api.getGrainCertificates().pipe(
          map((grainCertificates) =>
            registrationActions.getAllGrainCertificatesSuccess({ grainCertificates }),
          ),
          startWith(CoreActions.setLoading({ loading: true })),
          finalize(() => this.store.dispatch(CoreActions.setLoading({ loading: false }))),
        ),
      ),
    ),
  );

  getCustomerWithGrower$ = createEffect(() =>
    this.actions$.pipe(
      ofType(registrationActions.getCustomerWithGrower),
      mergeMap((_) =>
        this.api.getCustomerGrower().pipe(
          map((customerWithGrower) =>
            registrationActions.getCustomerWithGrowerSuccess({ customerWithGrower }),
          ),
          startWith(CoreActions.setLoading({ loading: true })),
          finalize(() => this.store.dispatch(CoreActions.setLoading({ loading: false }))),
        ),
      ),
    ),
  );

  addBatch$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(batchActions.addBatch),
        tap(() => {
          this.ngZone.run(() => this.router.navigate(['/registration/batches'])).then();
        }),
      ),
    { dispatch: false },
  );

  cancelBatch$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(batchActions.cancelBatch),
        switchMap((_) =>
          this.dialog
            .confirm({
              title: this.locale.translate('batch-configuration.cancel-title'),
              text: this.locale.translate('batch-configuration.cancel-text'),
              pushHistoryState: false,
            })
            .pipe(
              tap(({ status }) => {
                if (status === DIALOG_RETURN_TYPES.confirm) {
                  this.ngZone.run(() => this.router.navigate(['/registration/batches'])).then();
                }
              }),
            ),
        ),
      ),
    { dispatch: false },
  );

  updateBatch$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(batchActions.updateBatch),
        tap(() => [this.ngZone.run(() => this.router.navigate(['/registration/batches'])).then()]),
      ),
    { dispatch: false },
  );

  checkInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(informationActions.checkInformation),
      withLatestFrom(this.store.select(selectInformation)),
      debounceTime(500),
      switchMap(([information, currentInformation]) =>
        currentInformation?.cultivation &&
        information.cultivation !== currentInformation.cultivation
          ? this.dialog
              .confirm({
                title: this.locale.translate('registration.checkInformationTitle'),
                text: this.locale.translate('registration.checkInformationText'),
                pushHistoryState: false,
              })
              .pipe(
                switchMap(
                  ({ status }) =>
                    (status === DIALOG_RETURN_TYPES.confirm && [
                      informationActions.setInformation(information),
                      batchActions.resetBatches(),
                    ]) ||
                    EMPTY,
                ),
              )
          : [informationActions.setInformation(information)],
      ),
    ),
  );

  setInformation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(informationActions.setInformation),
        tap((_) => this.ngZone.run(() => this.router.navigate(['/registration/batches'])).then()),
      ),
    { dispatch: false },
  );

  cancelRegistration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(registrationActions.cancelRegistration),
        switchMap((_) =>
          this.dialog
            .confirm({
              title: this.locale.translate('registration.cancel-title'),
              text: this.locale.translate('registration.cancel-text'),
              pushHistoryState: false,
            })
            .pipe(
              tap(({ status }) => {
                if (status === DIALOG_RETURN_TYPES.confirm) {
                  this.store.dispatch(registrationActions.resetRegistration());
                  this.ngZone.run(() => this.router.navigate(['/history'])).then();
                }
              }),
            ),
        ),
      ),
    { dispatch: false },
  );

  saveRegistration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(completeActions.saveRegistration),
      switchMap((_) => this.store.pipe(select(selectRegistrationData), take(1))),
      exhaustMap((data) =>
        this.api.saveRegistration(data).pipe(
          map((_) => completeActions.saveRegistrationSuccess({ registration: data })),
          catchError((error: HttpErrorResponse) =>
            of(
              completeActions.saveRegistrationError({
                message: error.message,
                status: error.status,
              }),
            ),
          ),
          startWith(CoreActions.setLoading({ loading: true })),
          finalize(() => this.store.dispatch(CoreActions.setLoading({ loading: false }))),
        ),
      ),
    ),
  );

  saveRegistrationError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(completeActions.saveRegistrationError),
        map(({ status }) =>
          status === 400
            ? 'Er is een fout gevonden in uw opgevoerde opgave(s), controleer de opgave(s).'
            : 'Er deed zich een fout voor waardoor de graanaanmelding niet verstuurd kon worden.',
        ),
        switchMap((errormsg: string) =>
          of(NotificationActions.errorSnackbar({ message: errormsg })),
        ),
      ),
    { dispatch: false },
  );

  saveRegistrationSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(completeActions.saveRegistrationSuccess),
        switchMap(() =>
          of(
            NotificationActions.successSnackbar({
              message: 'De graanaanmelding is verstuurd.',
            }),
          ),
        ),
        tap(() => {
          this.store.dispatch(registrationActions.resetRegistration());
          this.ngZone.run(() => this.router.navigate(['/history'])).then();
        }),
      ),
    { dispatch: false },
  );

  getRegistrationDefaultValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(registrationActions.getRegistrationDefaultValues),
      mergeMap((action) =>
        this.api.getRegistrationDefaultValues(action.cultivationId).pipe(
          map((defaultValues) =>
            registrationActions.getRegistrationDefaultValuesSuccess({ defaultValues }),
          ),
          startWith(CoreActions.setLoading({ loading: true })),
          finalize(() => this.store.dispatch(CoreActions.setLoading({ loading: false }))),
        ),
      ),
    ),
  );
}
