import { Inject, Injectable } from '@angular/core';
import { PickUp, PickUpForOverview } from '../models/pick-up.model';
import { HttpClient } from '@angular/common/http';
import { EnvironmentInjector } from '@next/core-lib/environment';
import { Environment } from 'src/environment';
@Injectable({
  providedIn: 'root',
})
export class GrainPickupService {
  // change the base url to point to the according service name
  private baseUrl = `${this.env.API_BASE_URL}/service/grain-registration/secure/grainPickUpRequest`;
  private grainRegistrationUrl = `${this.env.API_BASE_URL}/service/grain-registration`;
  constructor(private http: HttpClient, @Inject(EnvironmentInjector) private env: Environment) {}

  getPickUpListByCropYear(cropYear: number) {
    return this.http.get<{ grainPickUpRequests: PickUpForOverview[] }>(
      `${this.baseUrl}/year/${cropYear}`,
      {},
    );
  }

  getPickUpListByTransporter(cropYear: number, profileId: number) {
    return this.http.get<{ grainPickUpRequests: PickUpForOverview[] }>(
      `${this.baseUrl}/cropYear/${cropYear}/profile/${profileId}`,
      {},
    );
  }

  getPickUpListByCustomer(cropYear: number) {
    return this.http.get<{ grainPickUpRequests: PickUpForOverview[] }>(
      `${this.baseUrl}/cropYear/${cropYear}/customer`,
      {},
    );
  }

  removeGrainPickup(pickupId: number) {
    return this.http.delete(`${this.baseUrl}/${pickupId}`);
  }

  saveGrainPickup(pickup: PickUp) {
    return this.http.post<PickUp>(`${this.baseUrl}`, pickup, {});
  }

  getPickupRegistrationDocument(id: string) {
    return this.http.get(`${this.grainRegistrationUrl}/secure/blobstorage/${id}`, {
      responseType: 'blob',
      observe: 'response',
    });
  }
}
