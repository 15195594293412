<div class="complete">
  <div class="col-7" *ngIf="(hasValidInformationForm$ | async) && (hasBatches$ | async)">
    <div class="spacing--padding-top">
      <h2 class="text--h3 text--light">{{ 'complete.title' | translate }}</h2>
      <p>{{ 'complete.description' | translate }}</p>
    </div>

    <app-registration-information [viewMode]="true"></app-registration-information>
    <app-registration-grower></app-registration-grower>

    <div class="spacing--padding-top">
      <h3 class="text--h3 text--light">{{ 'complete.batch-overview-title' | translate }}</h3>
      <app-registration-batch-overview [viewMode]="true"></app-registration-batch-overview>
    </div>

    <form
      *ngIf="completeForm && completeForm$"
      class="spacing--padding-vertical"
      [formGroup]="completeForm"
      [agrConnectForm]="completeForm$"
      (ngSubmit)="save()"
    >
      <mat-form-field class="full-width-field" (keyup)="setComplete()">
        <textarea
          matInput
          placeholder="{{ 'complete.comment' | translate }}"
          formControlName="comment"
          data-test="complete__comment"
        ></textarea>
      </mat-form-field>

      <div class="mat-checkbox-row-wrapper">
        <div class="mat-checkbox-row">
          <mat-checkbox
            data-test="complete__requirements-accepted"
            formControlName="requirementsAccepted"
            (change)="setComplete()"
            color="primary"
          >
            {{ 'complete.requirements-accepted' | translate }}
            <a
              href="https://www.agrifirm.nl/globalassets/agrifirm-group/agrifirm-nl/documenten/leveringsvoorwaarden-en-condities/deelnameVoorwaarden.pdf"
              target="_blank"
              >{{ 'complete.agreements' | translate }}
            </a></mat-checkbox
          >
        </div>
        <mat-error
          *ngIf="
            completeForm.controls.requirementsAccepted.invalid &&
            completeForm.controls.requirementsAccepted.touched
          "
        >
          {{ 'complete.error-requirements-accepted' | translate }}
        </mat-error>
      </div>

      <div class="spacing--padding-bottom spacing--margin-bottom">
        <button mat-flat-button color="accent" type="submit">
          {{ 'complete.confirm' | translate }}
        </button>
      </div>
    </form>
  </div>

  <ng-container *ngIf="(hasValidInformationForm$ | async) !== true">
    <agr-notification-empty
      [isEmpty]="(hasValidInformationForm$ | async) !== true"
      emptyTitle="{{ 'complete.missing-information-title' | translate }}"
      emptyDescription="{{ 'complete.missing-information-description' | translate }}"
      emptyImage="assets/images/grain3.jpg"
      class="complete__notification-empty"
    >
    </agr-notification-empty>
  </ng-container>
  <ng-container
    *ngIf="(hasValidInformationForm$ | async) === true && (hasBatches$ | async) !== true"
  >
    <agr-notification-empty
      *ngIf="(hasValidInformationForm$ | async) === true && (hasBatches$ | async) !== true"
      [isEmpty]="true"
      emptyTitle="{{ 'complete.missing-batches-title' | translate }}"
      emptyDescription="{{ 'complete.missing-batches-description' | translate }}"
      emptyImage="assets/images/grain4.jpg"
      class="complete__notification-empty"
    >
    </agr-notification-empty>
  </ng-container>
</div>
