<div
  data-test="batch-configuration"
  class="col-7 spacing--margin-vertical"
  *ngIf="batchForm && informationForm$ | async; let informationForm"
>
  <h2 class="text--h3">{{ outcome$ | async }}</h2>
  <form
    [formGroup]="batchForm"
    [agrConnectForm]="batch$"
    *ngIf="informationForm.cultivation"
    (ngSubmit)="save()"
  >
    <mat-form-field class="full-width-field">
      <mat-label>{{ 'batch-configuration.cultivation-method' | translate }}</mat-label>
      <input
        type="text"
        [placeholder]="'batch-configuration.cultivation-method' | translate"
        matInput
        formControlName="cultivationMethod"
        [matAutocomplete]="cultivationMethodAutocomplete"
        data-test="batch-configuration__cultivation-method"
      />
      <mat-autocomplete
        #cultivationMethodAutocomplete="matAutocomplete"
        [displayWith]="displayCultivationMethod"
      >
        <mat-option
          *ngFor="let option of filteredCultivationMethods$ | async"
          [value]="option"
          data-test="batch-configuration__cultivation-method-option"
        >
          {{ option.method }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="full-width-field" *ngIf="showSpecies$ | async">
      <mat-select
        placeholder="{{ 'batch-configuration.species' | translate }}"
        formControlName="species"
        data-test="batch-configuration__species"
      >
        <mat-option
          *ngFor="let species of batchForm.controls.cultivationMethod.value.species"
          [value]="species"
        >
          {{ species.species }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="batchForm.controls.species.invalid">
        {{ 'batch-configuration.error-species' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width-field">
      <mat-label>{{ 'batch-configuration.hectares' | translate }}</mat-label>
      <input
        formControlName="hectares"
        matInput
        placeholder="Hectare"
        type="number"
        data-test="batch-configuration__hectares"
      />
      <mat-error *ngIf="batchForm.controls.hectares.invalid">
        {{ 'batch-configuration.error-hectares' | translate }}
      </mat-error>
    </mat-form-field>

    <div class="mat-slide-group">
      <mat-slide-toggle
        color="primary"
        formControlName="isPool"
        data-test="batch-configuration__is-pool"
        >{{ 'batch-configuration.is-pool' | translate }}</mat-slide-toggle
      >
    </div>

    <div class="mat-slide-group">
      <mat-slide-toggle
        color="primary"
        formControlName="isSelfContained"
        data-test="batch-configuration__is-self-contained"
        >{{ 'batch-configuration.is-self-contained' | translate }}</mat-slide-toggle
      >
    </div>

    <div *ngIf="showIsPickUp$ | async" class="mat-slide-group">
      <mat-slide-toggle
        color="primary"
        formControlName="isPickUp"
        data-test="batch-configuration__is-pick-up"
        >{{ 'batch-configuration.is-pick-up' | translate }}</mat-slide-toggle
      >
    </div>

    <div *ngIf="showLocations$ | async">
      <div *ngIf="deliveryLocations$ | async">
        <mat-form-field class="full-width-field">
          <mat-label>{{ 'batch-configuration.delivery-location' | translate }}</mat-label>
          <input
            type="text"
            [placeholder]="'batch-configuration.delivery-location' | translate"
            matInput
            formControlName="deliveryLocation"
            [matAutocomplete]="deliveryLocationAutocomplete"
            data-test="batch-configuration__location"
          />
          <mat-autocomplete
            #deliveryLocationAutocomplete="matAutocomplete"
            [displayWith]="displayDeliveryLocation"
          >
            <mat-option
              *ngFor="let option of filteredDeliveryLocations$ | async"
              [value]="option"
              data-test="batch-configuration__location-option"
            >
              {{ option.locationDisplayName }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="showStorageTimeType$ | async">
      <label>{{ 'batch-configuration.storage-time-type' | translate }}</label>
      <mat-radio-group formControlName="storageTimeType">
        <mat-radio-button
          value="{{ StorageTimeType.short }}"
          data-test="batch-configuration__storage-time-type-short"
          >September of oktober</mat-radio-button
        >
        <mat-radio-button
          value="{{ StorageTimeType.long }}"
          data-test="batch-configuration__storage-time-type-long"
          >Januari – mei</mat-radio-button
        >
      </mat-radio-group>
      <mat-error *ngIf="batchForm.controls.storageTimeType.invalid">
        {{ 'batch-configuration.error-storage-time-type' | translate }}
      </mat-error>
    </div>

    <div *ngIf="showDeposit$ | async" class="mat-slide-group">
      <mat-slide-toggle
        color="primary"
        formControlName="isDeposit"
        data-test="batch-configuration__is-deposit"
        >{{ 'batch-configuration.is-deposit' | translate }}</mat-slide-toggle
      >
    </div>

    <div *ngIf="showIsHectareDeposit$ | async" class="mat-slide-group">
      <mat-slide-toggle
        color="primary"
        formControlName="isHectareDeposit"
        data-test="batch-configuration__is-hectare-deposit"
        >{{ 'batch-configuration.is-hectare-deposit' | translate }}</mat-slide-toggle
      >
    </div>

    <div class="mat-button-row spacing--margin-vertical">
      <button mat-flat-button color="primary" type="submit">
        {{ 'save' | translate }}
      </button>
      <button mat-stroked-button (click)="cancel()" type="button">
        {{ 'cancel' | translate }}
      </button>
    </div>
  </form>
</div>
