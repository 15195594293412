import { Component } from '@angular/core';
import { MergedRoute, RouterSelectors } from '@next/core-lib/router-store';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { RegistrationState } from 'src/app/registration/models/registration.model';
import { cancelPickupRequest } from '../../store/actions/information.actions';

@Component({
  selector: 'app-pick-up-registration',
  templateUrl: './pick-up-registration.component.html',
})
export class PickUpRegistrationComponent {
  routeUrl$ = this.store.pipe(
    select(RouterSelectors.getMergedRoute),
    map((route: MergedRoute) => route.url),
  );

  isInformation$ = this.routeUrl$.pipe(map((url) => url.indexOf('/information') > 0));
  isConfirm$ = this.routeUrl$.pipe(map((url) => url.indexOf('/confirm') > 0));

  constructor(private store: Store<RegistrationState>) {}

  back(): void {
    this.store.dispatch(cancelPickupRequest());
  }
}
