import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SiteState } from '@next/core-lib/site';
import { Location, SelectedCustomer, UserSelectors } from '@next/core-lib/user';
import { Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil, withLatestFrom } from 'rxjs/operators';
import {
  AddressWithManualSetting,
  CropWithQuantity,
  PickUpInformation,
  PickUpState,
} from '../../models/pick-up.model';
import {
  addCrop,
  removeCrop,
  setInformationFormValues,
} from '../../store/actions/information.actions';
import {
  selectPickUpInformation,
  selectPickUpRegistrationCrops,
} from '../../store/selectors/pick-up.selectors';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-pick-up-registration-info',
  templateUrl: './pick-up-registration-info.component.html',
})
export class PickUpRegistrationInfoComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  selectedCrops$ = this.siteStore.select(selectPickUpRegistrationCrops);

  cropForm: FormGroup;
  additionalCropForm: FormGroup;
  additionalCropFormComponentLoaded = false;

  informationForm = new UntypedFormGroup({
    address: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
    pickUpLocation: new FormControl(null, [Validators.required]),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})$/),
    ]),
    additionalEmail: new FormControl('', [
      Validators.pattern(
        /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*;{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/,
      ),
    ]),
    phone: new FormControl(null, [Validators.required, this.phoneValidator.bind(this)]),
    customerLoads: new FormControl(null),
    insideStorage: new FormControl(null),
    remarks: new FormControl(null, [Validators.maxLength(300)]),
  });

  newLocationForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    street: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    houseNumber: new FormControl('', [Validators.required]),
    houseNumberAddition: new FormControl('', [Validators.maxLength(300)]),
    postalCode: new FormControl('', [
      Validators.required,
      Validators.pattern(/^\d{4}\s?[a-zA-Z]{2}$/),
    ]),
    city: new FormControl('', [Validators.required, Validators.maxLength(100)]),
  });

  selectedCustomer: SelectedCustomer;

  newLocationValue = 'NEW_ADDRESS';
  isAddNewLocation = false;

  constructor(
    private readonly siteStore: Store<SiteState>,
    private readonly pickUpStore: Store<PickUpState>,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.selectCustomer();
    this.onPickUpLocationChanged();
    this.setInitialValues();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  phoneValidator(control: AbstractControl) {
    const value = control.value;

    if (!value) {
      return null;
    }

    const clearedValue = value.replace(/[ -]+/g, '');
    const isValid = /^[+]?\d{3,15}$/.test(clearedValue);

    return !isValid ? { pattern: true } : null;
  }

  onPickUpLocationChanged(): void {
    this.informationForm.controls.pickUpLocation.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value === this.newLocationValue && !this.informationForm.contains('newLocation')) {
          this.informationForm.addControl('newLocation', this.newLocationForm);
          this.isAddNewLocation = true;
        } else {
          if (this.isAddNewLocation) {
            this.informationForm.removeControl('newLocation');
            this.isAddNewLocation = false;
          }
        }
      });
  }

  selectCustomer(): void {
    this.siteStore
      .select(UserSelectors.selectCustomer)
      .pipe(
        takeUntil(this.destroyed$),
        withLatestFrom(this.pickUpStore.select(selectPickUpInformation)),
      )
      .subscribe(([result, pickUpInformation]) => {
        if (result) {
          if (!result.shipTos?.length) {
            result.shipTos = [
              {
                id: 0,
                city: result.city,
                country: result.country,
                houseNumber: result.houseNumber,
                houseNumberAddition: result.houseNumberAddition,
                name: result.name,
                postalCode: result.postalCode,
                street: result.street,
                externalId: '-',
              } as Location,
            ];
          }
          this.selectedCustomer = { ...result, shipTos: [...result.shipTos] };
          if (!pickUpInformation?.address) {
            this.setFormInitValues();
          }
        }
      });
  }

  setFormInitValues(): void {
    this.informationForm.controls.email.setValue(this.selectedCustomer.emailAddress);
    const phone = this.selectedCustomer.mobileNumber
      ? this.selectedCustomer.mobileNumber
      : this.selectedCustomer.phoneNumber;
    this.informationForm.controls.phone.setValue(phone);
    const defaultLocationIndex = this.selectedCustomer.shipTos.findIndex(
      (x) => x.street === this.selectedCustomer.street,
    );
    if (defaultLocationIndex && defaultLocationIndex !== 0) {
      const defaultLocation = this.selectedCustomer.shipTos.splice(defaultLocationIndex, 1);
      this.selectedCustomer.shipTos.unshift(defaultLocation[0]);
    }
    this.informationForm.controls.address.setValue(this.selectedCustomer.shipTos[0]);
    this.informationForm.controls.pickUpLocation.setValue(this.selectedCustomer.shipTos[0]);
  }

  setCropForm(cropForm: FormGroup) {
    this.cropForm = cropForm;
  }

  setAdditionalCropForm(additionalCropForm: FormGroup) {
    this.additionalCropForm = additionalCropForm;
  }

  loadAdditionalCropFormComponent() {
    this.additionalCropFormComponentLoaded = true;

    if (
      this.cropForm &&
      this.cropForm.valid &&
      (!this.additionalCropForm || this.additionalCropForm.invalid)
    ) {
      this.addCrop(this.cropForm);
    } else if (this.additionalCropForm && this.additionalCropForm.valid) {
      this.addCrop(this.additionalCropForm);
      this.resetAdditionalCropFormValues();
    }
  }

  disableAddCropBtn(): boolean {
    return (this.cropForm && this.cropForm.valid && !this.additionalCropForm) ||
      this.additionalCropForm?.valid
      ? false
      : true;
  }

  addCrop(fromCropForm: FormGroup): void {
    const cropWithQuantity = this.createCropWithQuantity(fromCropForm);
    this.pickUpStore.dispatch(addCrop({ cropWithQuantity }));
  }

  removeCrop(index: number): void {
    this.pickUpStore.dispatch(removeCrop({ index }));
  }

  save() {
    if (
      this.informationForm.valid &&
      this.cropForm?.valid &&
      (!this.isAddNewLocation || this.informationForm.get('newLocation').valid)
    ) {
      if (!this.additionalCropForm) {
        this.addCrop(this.cropForm);
      } else if (this.additionalCropForm.valid) {
        this.addCrop(this.additionalCropForm);
      }

      const values: PickUpInformation = this.informationForm.value as PickUpInformation;
      if (this.informationForm.controls.pickUpLocation.value === this.newLocationValue) {
        values.pickUpLocation = this.informationForm.get('newLocation')
          .value as AddressWithManualSetting;
        values.pickUpLocation.isManual = true;
      }
      this.pickUpStore.dispatch(
        setInformationFormValues({
          values,
        }),
      );
      this.router.navigate(['/pick-up', 'registration', 'confirm']);
    }
  }

  private setInitialValues() {
    this.pickUpStore
      .select(selectPickUpInformation)
      .pipe(take(1), withLatestFrom(this.siteStore.select(UserSelectors.selectCustomer)))
      .subscribe(([pickUpInformation, selectedCustomer]) => {
        if (pickUpInformation && pickUpInformation.address) {
          if (selectedCustomer) {
            this.informationForm.controls.address.setValue(
              selectedCustomer.shipTos.find(
                (s) =>
                  s.postalCode === pickUpInformation.address.postalCode &&
                  s.houseNumber === pickUpInformation.address.houseNumber,
              ),
            );
          }
          if (pickUpInformation.pickUpLocation) {
            if (selectedCustomer && !pickUpInformation.pickUpLocation.isManual) {
              this.informationForm.controls.pickUpLocation.setValue(
                selectedCustomer.shipTos.find(
                  (s) =>
                    s.postalCode === pickUpInformation.pickUpLocation.postalCode &&
                    s.houseNumber === pickUpInformation.pickUpLocation.houseNumber,
                ),
              );
            }
            if (pickUpInformation.pickUpLocation.isManual) {
              this.informationForm.controls.pickUpLocation.setValue(this.newLocationValue);
              this.newLocationForm.controls.name.setValue(pickUpInformation.pickUpLocation.name);
              this.newLocationForm.controls.street.setValue(
                pickUpInformation.pickUpLocation.street,
              );
              this.newLocationForm.controls.houseNumber.setValue(
                pickUpInformation.pickUpLocation.houseNumber,
              );
              this.newLocationForm.controls.houseNumberAddition.setValue(
                pickUpInformation.pickUpLocation.houseNumberAddition,
              );
              this.newLocationForm.controls.postalCode.setValue(
                pickUpInformation.pickUpLocation.postalCode,
              );
              this.newLocationForm.controls.city.setValue(pickUpInformation.pickUpLocation.city);
            }
          }

          this.informationForm.controls.email.setValue(pickUpInformation.email);
          this.informationForm.controls.phone.setValue(pickUpInformation.phone);
          this.informationForm.controls.additionalEmail.setValue(pickUpInformation.additionalEmail);
          this.informationForm.controls.customerLoads.setValue(pickUpInformation.customerLoads);
          this.informationForm.controls.insideStorage.setValue(pickUpInformation.insideStorage);
          this.informationForm.controls.remarks.setValue(pickUpInformation.remarks);
        }
      });
  }

  private createCropWithQuantity(form: FormGroup): CropWithQuantity {
    const cropWithQuantity = {
      crop: form.controls.cultivationMethod.value,
      cultivationId: form.controls.cultivation.value,
      quantity: form.controls.quantity.value,
    } as CropWithQuantity;

    const crop = form.controls.cultivationMethod.value;
    if (crop && crop.species && crop.species.length > 0) {
      cropWithQuantity.species = form.controls.species.value;
    }

    return cropWithQuantity;
  }

  private resetAdditionalCropFormValues() {
    this.additionalCropForm.controls.cultivation.reset();
    this.additionalCropForm.controls.cultivationMethod.reset();
    this.additionalCropForm.controls.species.reset();
    this.additionalCropForm.controls.quantity.reset();
  }
}
