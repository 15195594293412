import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RegistrationState } from '../../models/registration.model';

export const selectRegistration = createFeatureSelector<RegistrationState>('registrationModule');

export const selectCultivations = createSelector(
  selectRegistration,
  (state: RegistrationState) => state.cultivations,
);

export const selectGrainCertificates = createSelector(
  selectRegistration,
  (state: RegistrationState) => state.grainCertificates,
);

export const selectGrower = createSelector(selectRegistration, (state: RegistrationState) =>
  state ? state.grower : undefined,
);

export const selectCustomer = createSelector(selectRegistration, (state: RegistrationState) =>
  state ? state.customer : undefined,
);

export const selectDeliveryLocations = createSelector(
  selectRegistration,
  (state: RegistrationState) =>
    state?.information?.cultivation?.deliveryLocations
      ? state.information.cultivation.deliveryLocations
          .slice()
          .sort((a, b) => (a.locationDisplayName > b.locationDisplayName ? 1 : -1))
      : [],
);

export const selectRegistrationDefaultValues = createSelector(
  selectRegistration,
  (state: RegistrationState) => state.defaultValues,
);
