import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PickUpState } from '../../models/pick-up.model';

export const selectPickUp = createFeatureSelector<PickUpState>('pickUpModule');

export const selectPickUpRegistrationCrops = createSelector(
  selectPickUp,
  (state: PickUpState) => state.pickUpInformation?.crops ?? [],
);

export const selectPickUpInformation = createSelector(
  selectPickUp,
  (state: PickUpState) => state.pickUpInformation,
);

export const selectPickupList = createSelector(
  selectPickUp,
  (state: PickUpState) => state.pickUpList ?? [],
);

export const selectPickupListToBeSaved = createSelector(
  selectPickUp,
  (state: PickUpState) => state.pickUpListToBeSaved ?? [],
);
