<div class="confirm spacing--margin-vertical">
  <ng-container *ngIf="!pickUpInformation?.crops?.length">
    <agr-notification-empty
      [isEmpty]="true"
      emptyTitle="{{ 'pick-up.confirm.no-products-title' | translate }}"
      emptyDescription="{{ 'pick-up.confirm.no-products-description' | translate }}"
      emptyImage="assets/images/grain4.jpg"
      class="confirm__notification-empty"
    >
    </agr-notification-empty>
  </ng-container>
  <div class="col-7" *ngIf="pickUpInformation?.crops?.length">
    <div class="spacing--padding-top">
      <h2 class="text--h3 text--light">{{ 'pick-up.confirm.title' | translate }}</h2>
      <p>{{ 'pick-up.confirm.description' | translate }}</p>
    </div>

    <div class="spacing--padding-top">
      <h3 class="text--h3 text--light">{{ 'pick-up.confirm.registration' | translate }}</h3>

      <agr-value-row label="{{ 'pick-up.address' | translate }}" data-test="pick_up_address">
        {{ pickUpInformation?.address?.name }}<br />
        {{ pickUpInformation?.address?.street }} {{ pickUpInformation?.address?.houseNumber
        }}{{ pickUpInformation?.address?.houseNumberAddition }}<br />
        {{ pickUpInformation?.address?.postalCode }} {{ pickUpInformation?.address?.city }}
      </agr-value-row>

      <agr-value-row
        label="{{ 'pick-up.pick-up-location' | translate }}"
        data-test="pick_up_pickuplocation"
      >
        {{ pickUpInformation.pickUpLocation?.name }}<br />
        {{ pickUpInformation.pickUpLocation?.street }}
        {{ pickUpInformation.pickUpLocation?.houseNumber
        }}{{ pickUpInformation.pickUpLocation?.houseNumberAddition }}<br />
        {{ pickUpInformation.pickUpLocation?.postalCode }}
        {{ pickUpInformation.pickUpLocation?.city }}
      </agr-value-row>

      <agr-value-row label="{{ 'pick-up.phone' | translate }}" data-test="pick_up_phone">
        {{ pickUpInformation.phone }}
      </agr-value-row>

      <agr-value-row label="{{ 'pick-up.email' | translate }}" data-test="pick_up_email">
        {{ pickUpInformation.email }}
      </agr-value-row>

      <agr-value-row
        *ngIf="pickUpInformation.additionalEmail"
        label="{{ 'pick-up.confirm.additional-email' | translate }}"
        data-test="pick_up_additionalemail"
      >
        <span *ngFor="let email of pickUpInformation.additionalEmail.replace(' ', '').split(';')">
          {{ email }}<br />
        </span>
      </agr-value-row>

      <agr-value-row label="{{ 'pick-up.customer-loads' | translate }}" data-test="pick_up_loads">
        {{ (pickUpInformation.customerLoads ? 'yes' : 'no') | translate }}
      </agr-value-row>

      <agr-value-row
        label="{{ 'pick-up.inside-storage' | translate }}"
        data-test="pick_up_insidestorage"
      >
        {{ (pickUpInformation.insideStorage ? 'yes' : 'no') | translate }}
      </agr-value-row>

      <div class="spacing--padding-top">
        <h3 class="text--h3 text--light">{{ 'pick-up.confirm.products' | translate }}</h3>

        <mat-list>
          <ng-container *ngFor="let crop of pickUpInformation.crops; let i = index">
            <mat-list-item data-test="information__cropRow">
              <div matLine class="flex--align-self-flex-start flex--align-center column-gap">
                <mat-icon matListIcon svgIcon="ship-grain"></mat-icon>
                <span>{{ crop.crop.method }}</span>
                <span *ngIf="crop.species">{{ crop.species.species }}</span>
                <span> {{ crop.quantity }} t </span>
              </div>
            </mat-list-item>
          </ng-container>
        </mat-list>
      </div>

      <div class="spacing--padding-top" *ngIf="pickUpInformation.remarks">
        <h4 class="text--h4 text--light">{{ 'pick-up.remarks' | translate }}</h4>
        <span>
          {{ pickUpInformation.remarks }}
        </span>
      </div>

      <div class="spacing--padding-top spacing--padding-bottom">
        <button
          mat-flat-button
          color="primary"
          data-test="information__save"
          type="submit"
          (click)="savePickUpRequest()"
        >
          {{ 'save' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
