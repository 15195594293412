<agr-page-toolbar
  [pageTitle]="'history.registrations' | translate"
  class="history"
  data-test="history"
  (primaryAction)="onNavigationAddRegistration()"
  primaryActionIcon="add"
  [primaryActionText]="'add' | translate"
  [hasPrimaryAction]="!isInMaintenanceMode"
  [isEmpty]="(history$ | async)?.length === 0"
  emptyTitle="{{ 'history.empty-title' | translate }}"
  emptyDescription="{{ 'history.empty-description' | translate }}"
>
  <div *ngIf="isInMaintenanceMode" class="wrapper--spacing-horizontal layout--margin-top">
    <div class="notification notification-warning" data-test="history-maintenance-mode-warning">
      <mat-icon>warning</mat-icon>
      {{ 'maintenance_mode_warning' | translate }}
    </div>
  </div>
  <div displayAlways class="wrapper--spacing-horizontal layout--margin-top">
    <agr-filter [config]="filterConfig"></agr-filter>
  </div>
  <table
    mat-table
    [dataSource]="pagedHistory$"
    data-test="history__table"
    class="history__table spacing--margin-vertical"
  >
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>{{ 'history.display-id' | translate }}</th>
      <td mat-cell *matCellDef="let item" data-test="history__display-id">
        {{ item.displayId }}
      </td>
    </ng-container>
    <ng-container matColumnDef="year">
      <th mat-header-cell *matHeaderCellDef>{{ 'history.year' | translate }}</th>
      <td mat-cell *matCellDef="let item" data-test="history__year">{{ item.year }}</td>
    </ng-container>
    <ng-container matColumnDef="customer" *ngIf="hasGlobalAdminAccessRule$ | async">
      <th mat-header-cell *matHeaderCellDef>{{ 'history.customer' | translate }}</th>
      <td mat-cell *matCellDef="let item" data-test="history__customer">
        {{ item.customerName }} ({{ item.externalCustomerId }})
      </td>
    </ng-container>
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>{{ 'history.date' | translate }}</th>
      <td mat-cell *matCellDef="let item" data-test="history__date">
        {{ item.date | agrDate : 'short' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="certification">
      <th mat-header-cell *matHeaderCellDef>{{ 'history.certification' | translate }}</th>
      <td mat-cell *matCellDef="let item" data-test="history__certificate">
        {{ item.certificate }}
      </td>
    </ng-container>
    <ng-container matColumnDef="cultivation">
      <th mat-header-cell *matHeaderCellDef>{{ 'history.cultivation' | translate }}</th>
      <td mat-cell *matCellDef="let item" data-test="history__cultivation">
        {{ item.cultivation }}
      </td>
    </ng-container>
    <ng-container matColumnDef="hectares">
      <th mat-header-cell *matHeaderCellDef>{{ 'history.hectares' | translate }}</th>
      <td mat-cell *matCellDef="let item" data-test="history__hectares">{{ item.hectares }}</td>
    </ng-container>
    <ng-container matColumnDef="pdf">
      <th mat-header-cell *matHeaderCellDef>{{ 'history.actions' | translate }}</th>
      <td mat-cell *matCellDef="let item" data-test="history__pdf">
        <button mat-icon-button *ngIf="item.pdf" (click)="onOpenPdfDocument(item.pdf.id)">
          <mat-icon data-test="history__pdf-icon" svgIcon="mdi-file-pdf"></mat-icon>
        </button>
        <div *ngIf="!item.pdf">
          <mat-progress-spinner
            class="history__pdf-spinner"
            color="primary"
            mode="indeterminate"
            diameter="21"
            data-test="history__pdf-spinner"
            matTooltip="{{ 'history.pdf-being-generated' | translate }}"
            matTooltipPosition="left"
          ></mat-progress-spinner>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    [pageSize]="pageSize"
    [pageSizeOptions]="[10, 25, 50]"
    [length]="(history$ | async)?.length"
    (page)="page($event)"
  ></mat-paginator>
</agr-page-toolbar>
