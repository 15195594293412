<agr-page-toolbar
  [pageTitle]="'pick-up.page-title' | translate"
  class="pick-up"
  data-test="pick-up"
  (primaryAction)="onNavigationAddPickUp()"
  primaryActionIcon="add"
  [primaryActionText]="'add' | translate"
  [hasPrimaryAction]="!isInMaintenanceMode && (userRole$ | async)?.RoleKey !== transporterRole"
  [hasSecondaryAction]="canEditPickups$ | async"
  [secondaryActionText]="
    (canEditPickups$ | async) && !isEditAllPickupsMode
      ? ('editAllPickUps' | translate)
      : ('saveAllPickUps' | translate)
  "
  [secondaryActionIcon]="secondaryActionIcon()"
  (secondaryAction)="editOrSavePickUps()"
  secondaryActionColor="primary"
  [isEmpty]="false"
  emptyTitle="{{ 'pick-up.empty-title' | translate }}"
  emptyDescription="{{ 'pick-up.empty-description' | translate }}"
>
  <div *ngIf="isInMaintenanceMode" class="wrapper--spacing-horizontal layout--margin-top">
    <div class="notification notification-warning" data-test="history-maintenance-mode-warning">
      <mat-icon>warning</mat-icon>
      {{ 'maintenance_mode_warning' | translate }}
    </div>
  </div>
  <div displayAlways class="wrapper--spacing-horizontal layout--margin-top">
    <agr-filter [config]="filterConfig"></agr-filter>
  </div>
  <!-- Overview table -->
  <app-pickup-overview-table
    [isEditAllPickupsMode]="isEditAllPickupsMode"
  ></app-pickup-overview-table>
</agr-page-toolbar>
