/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { PickUp, PickUpColumnTypes, PickUpViewModel } from '../../models/pick-up.model';

export const getPickupListByCropYear = createAction(
  '[PickUp][Overview] Get pickup list by crop year',
);

export const getPickupListByTransporter = createAction(
  '[PickUp][Overview] Get pickup list by transporter',
);

export const getPickupListByCustomer = createAction(
  '[PickUp][Overview] Get pickup list by customer',
);

export const getPickupListSuccess = createAction(
  '[PickUp][Overview] Get pickup list success',
  props<{ pickUpList: PickUpViewModel[] }>(),
);

export const getPickupListByCustomerToRefresh = createAction(
  '[PickUp][Overview] Get pickup list by customer refresh',
);

export const getPickupListByCropYearToRefresh = createAction(
  '[PickUp][Overview] Get pickup list by crop year refresh',
);

export const setPickupListToBeSaved = createAction(
  '[PickUp][Overview] Set pickup list to be saved',
  props<{ pickUpList: PickUp[] }>(),
);

export const savePickupsRequest = createAction('[PickUp][Overview] Save pickups request');

export const saveGrainPickupFromOverview = createAction(
  '[PickUp][Overview] Save pickup request',
  props<{ pickup: PickUp }>(),
);

export const removePickupRequest = createAction(
  '[PickUp][Overview] Remove pickup request',
  props<{ pickupId: number }>(),
);

export const updatePickupList = createAction('[PickUp][Overview] Update pickup list');

export const openEditSingleInputDialog = createAction(
  '[PickUp][Overview] Open edit single input dialog',
  props<{ text: string; rowId: number; columnType: PickUpColumnTypes }>(),
);

export const updatePickupInList = createAction(
  '[PickUp][Overview] Update pickup in list',
  props<{ text: string; rowId: number; columnType: PickUpColumnTypes }>(),
);

export const getPickupRegistrationDocument = createAction(
  '[PickUp][Overview] Get pickup document',
  props<{ id: string }>(),
);

export const getPickupRegistrationDocumentSuccess = createAction(
  '[PickUp][Overview] Get pickup document success',
);

export const getPickupRegistrationDocumentFailed = createAction(
  '[PickUp][Overview] Get pickup document failed',
);
