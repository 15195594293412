import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { EnvironmentInjector } from '@next/core-lib/environment';
import { Environment } from 'src/environment';
import { GetDefaultYearResponse } from '../models';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PeriodService {
  private baseUrl = `${this.env.API_BASE_URL}/service/arable-period/secure/defaultyear`;

  constructor(private http: HttpClient, @Inject(EnvironmentInjector) private env: Environment) {}

  getDefaultYear(): Observable<number> {
    return this.http.get<GetDefaultYearResponse>(this.baseUrl).pipe(
      map((d) => d.defaultYear),
      catchError(() => of(new Date().getFullYear())),
    );
  }
}
