import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { LocaleService } from '@next/core-lib/i18n';
import { hasValidInformation } from 'src/app/registration/store/selectors/information.selectors';
import { StorageTimeType } from '../../models/registration.model';
import { BatchConfigurationOutcomeService } from '../../services/batch-configuration-outcome.service';
import { selectBatches, hasBatches } from '../../store/selectors/batch.selectors';
import { SiteState } from 'src/app/shared/models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registration-batch-overview',
  templateUrl: './batch-overview.component.html',
  styleUrls: ['./batch-overview.component.scss'],
})
export class BatchOverviewComponent {
  @Input() viewMode: boolean;

  hasValidInformationForm$ = this.store.select(hasValidInformation);
  batchesForm$ = this.store.select(selectBatches);
  hasBatches$ = this.store.select(hasBatches);
  StorageTimeType = StorageTimeType;

  constructor(
    private store: Store<SiteState>,
    private locale: LocaleService,
    public batchConfigurationOutcomeService: BatchConfigurationOutcomeService,
    private router: Router,
  ) {}

  getDeliveryName(storageTimeType: StorageTimeType) {
    return parseInt(String(storageTimeType), 10) === StorageTimeType.long
      ? this.locale.translate('batch-overview.storage-time-type-long')
      : parseInt(String(storageTimeType), 10) === StorageTimeType.short
      ? this.locale.translate('batch-overview.storage-time-type-short')
      : '';
  }

  addBatch() {
    this.router.navigate(['/registration', 'batches', 'add']);
  }
}
