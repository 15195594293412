import { createSelector } from '@ngrx/store';
import { RegistrationState, Information } from '../../models/registration.model';
import { selectRegistration } from './registration.selectors';

export const selectInformation = createSelector(selectRegistration, (state: RegistrationState) => {
  const information: Information = state ? { ...state.information } : undefined;
  if (state && state.grower) {
    if (state.grower.skalNumber) {
      information.skalNumber = state.grower.skalNumber;
    }
    if (state.grower.transitionDate) {
      information.transitionDate = state.grower.transitionDate;
    }
  }
  return information;
});

export const hasValidInformation = createSelector(
  selectInformation,
  (state: Information) => typeof state === 'object',
);

export const selectCultivationMethods = createSelector(
  selectInformation,
  (state: Information) => state?.cultivation?.cultivationMethods,
);
