import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { CustomerGuard } from '@next/core-lib/relationships';
import { switchMap, take } from 'rxjs/operators';
import { SiteState } from 'src/app/shared/models';
import { of } from 'rxjs';
import { AuthorizationSelectors } from '@next/core-lib/authorization';
import { AccessRoleTypes } from '@next/core-lib';

@Injectable({
  providedIn: 'root',
})
export class PickUpGuard {
  constructor(private store: Store<SiteState>, private readonly customerGuard: CustomerGuard) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.customerGuard.canActivate(route, state).pipe(
      switchMap((result) =>
        this.store.pipe(
          select(AuthorizationSelectors.selectAccessRuleForApplicationKey('GrainPickUp')),
          switchMap((userRole) => {
            if (
              userRole &&
              (userRole.RoleKey === AccessRoleTypes.Advisor ||
                userRole.RoleKey === AccessRoleTypes.Customer)
            ) {
              if (typeof result !== 'boolean') {
                return of(result);
              } else {
                return [true];
              }
            } else {
              //For any other role, skip the customer selection screen
              return [true];
            }
          }),
          take(1),
        ),
      ),
    );
  }
}
