import { createAction, props } from '@ngrx/store';
import { HistoryItem } from '../models/history.model';

export const getHistory = createAction('[History] Get history');
export const getHistoryRefresh = createAction('[History] Get history refresh');
export const getHistorySuccess = createAction(
  '[Batch] Get history success',
  props<{ history: HistoryItem[] }>(),
);

export const getHistoryDocument = createAction(
  '[Batch] Get history document',
  props<{ id: string }>(),
);
export const getHistoryDocumentSuccess = createAction('[Batch] Get history document success');
