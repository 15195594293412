import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectCustomer } from 'src/app/registration/store/selectors/registration.selectors';
import { RegistrationState } from '../../models/registration.model';

@Component({
  selector: 'app-registration-grower',
  templateUrl: './grower.component.html',
})
export class GrowerComponent {
  @Input() editable: boolean;

  customer$ = this.store.select(selectCustomer);

  constructor(private store: Store<RegistrationState>) {}
}
