import { NgModule } from '@angular/core';
import { AgrBrowserModule } from '@next/core-lib/browser';
import { AppModule } from './app.module';
import { AppComponent } from './app.component';
import { ENVIRONMENT_MAPPER } from '@next/core-lib/environment-browser';
import { setEnvironment } from 'src/environment';

@NgModule({
  imports: [AppModule, AgrBrowserModule],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: ENVIRONMENT_MAPPER,
      useValue: setEnvironment,
    },
  ],
})
export class AppBrowserModule {}
