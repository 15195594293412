import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppRoutingModule } from './app-routing.module';
import { RegistrationModule } from './registration/registration.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { HistoryModule } from './history/history.module';
import { AgrAppModule } from '@next/core-lib/app';
import { AgrWrapperSiteSidenavModule } from '@next/core-lib/wrapper-site-sidenav';
import { AgrGoogleAnalyticsModule } from '@next/core-lib/google-analytics';
import { RouterModule } from '@angular/router';
import { appReducer } from './store/reducer';
import { AppEffects } from './store/effects';
import { PickUpModule } from './pick-up/pick-up.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    RouterModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    SharedModule,
    HistoryModule,
    RegistrationModule,
    PickUpModule,
    AppRoutingModule,
    AgrAppModule,
    AgrWrapperSiteSidenavModule,
    AgrGoogleAnalyticsModule,
    StoreModule.forFeature('app', appReducer),
    EffectsModule.forFeature([AppEffects]),
  ],
})
export class AppModule {}
