import { Injectable } from '@angular/core';
import { Batch, BatchOutcome, StorageTimeType } from '../models/registration.model';

@Injectable({
  providedIn: 'root',
})
export class BatchConfigurationOutcomeService {
  constructor() {}

  calculateOutcome(batch: Batch) {
    return batch.isPool
      ? batch.isSelfContained
        ? String(batch.storageTimeType) === String(StorageTimeType.long)
          ? this.determineBasisPoolLong(batch.isHectareDeposit)
          : this.determineBasisPoolShort(batch.isDeposit, batch.isHectareDeposit)
        : this.determineBasisPoolWithoutStorage(batch.isDeposit, batch.isHectareDeposit)
      : BatchOutcome.dagprijsInBewaring;
  }

  determineBasisPoolLong(isHectareDeposit: boolean) {
    return isHectareDeposit
      ? BatchOutcome.langeBewaarpoolHectareVoorschot
      : BatchOutcome.langeBewaarpool;
  }

  determineBasisPoolShort(isDeposit: boolean, isHectareDeposit: boolean) {
    return isDeposit
      ? isHectareDeposit
        ? BatchOutcome.basispoolBewarenVoorschotHectareVoorschot
        : BatchOutcome.basispoolBewarenVoorschot
      : BatchOutcome.basispoolBewarenZonderVoorschot;
  }

  determineBasisPoolWithoutStorage(isDeposit: boolean, isHectareDeposit: boolean) {
    return isDeposit
      ? isHectareDeposit
        ? BatchOutcome.basispoolVoorschotHectareVoorschot
        : BatchOutcome.basispoolVoorschot
      : BatchOutcome.basispoolZonderVoorschot;
  }

  getOutcomeName(outcome: number) {
    return BatchOutcome[outcome];
  }
}
