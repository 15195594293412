import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  selectInformation,
  hasValidInformation,
} from '../../store/selectors/information.selectors';
import { RegistrationState, Complete } from '../../models/registration.model';
import { setComplete, saveRegistration } from '../../store/actions/complete.actions';
import { hasBatches } from '../../store/selectors/batch.selectors';
import { selectComplete } from '../../store/selectors/complete.selectors';

@Component({
  selector: 'app-registration-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.scss'],
})
export class CompleteComponent {
  completeForm = new FormGroup({
    comment: new FormControl('', []),
    requirementsAccepted: new FormControl(null, [Validators.required, Validators.requiredTrue]),
  });

  completeForm$ = this.store.select(selectComplete);
  hasValidInformationForm$ = this.store.select(hasValidInformation);
  hasBatches$ = this.store.select(hasBatches);
  informationForm$ = this.store.select(selectInformation);

  constructor(private store: Store<RegistrationState>) {}

  setComplete() {
    this.store.dispatch(setComplete({ complete: this.completeForm.value as unknown as Complete }));
  }

  save() {
    this.completeForm.controls.requirementsAccepted.markAsTouched();

    if (this.completeForm.valid) {
      this.store.dispatch(saveRegistration());
    }
  }
}
