<div data-test="information" class="information col-7">
  <form
    *ngIf="!viewMode"
    [formGroup]="informationForm"
    [agrConnectForm]="information$"
    (ngSubmit)="save()"
    class="spacing--margin-vertical"
  >
    <mat-form-field class="full-width-field spacing--margin-top">
      <mat-label>{{ 'information.year' | translate }}</mat-label>
      <mat-select
        placeholder="{{ 'information.year' | translate }}"
        formControlName="year"
        data-test="information__year"
      >
        <mat-option *ngFor="let year of years" [value]="year">
          {{ year }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="cultivations$ | async; let cultivations" class="full-width-field">
      <mat-label>{{ 'information.cultivation' | translate }}</mat-label>
      <mat-select
        placeholder="{{ 'information.cultivation' | translate }}"
        formControlName="cultivation"
        [compareWith]="compareCultivationsFn"
        data-test="information__cultivation"
      >
        <mat-option *ngFor="let cultivation of cultivations" [value]="cultivation">
          {{ cultivation.cultivation }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="informationForm.controls.cultivation.invalid">{{
        'information.error-cultivation' | translate
      }}</mat-error>
    </mat-form-field>

    <mat-form-field
      *ngIf="grainCertificates$ | async; let grainCertificates"
      class="full-width-field"
    >
      <mat-label>{{ 'information.certification' | translate }}</mat-label>
      <mat-select
        placeholder="{{ 'information.certification' | translate }}"
        formControlName="grainCertificate"
        [compareWith]="compareCertificatesFn"
        data-test="information__certificate"
      >
        <mat-option *ngFor="let grainCertificate of grainCertificates" [value]="grainCertificate">
          {{ grainCertificate.certificate }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="informationForm.controls.grainCertificate.invalid">{{
        'information.error-grain-certificate' | translate
      }}</mat-error>
    </mat-form-field>

    <mat-form-field class="full-width-field" *ngIf="showSkalNumber">
      <mat-label>{{ 'information.skal' | translate }}</mat-label>
      <input
        formControlName="skalNumber"
        matInput
        placeholder=""
        type="number"
        data-test="information__skal-number"
      />
      <mat-error *ngIf="informationForm.controls.skalNumber.invalid">{{
        'information.error-skal-number' | translate
      }}</mat-error>
    </mat-form-field>

    <mat-form-field class="full-width-field" *ngIf="showTransitionDate">
      <mat-label>{{ 'information.transition-date' | translate }}</mat-label>
      <input
        formControlName="transitionDate"
        matInput
        [matDatepicker]="mdTransitionDate"
        placeholder=""
        data-test="information__transition-date"
      />
      <mat-datepicker-toggle matSuffix [for]="mdTransitionDate"></mat-datepicker-toggle>
      <mat-datepicker #mdTransitionDate></mat-datepicker>
      <mat-error *ngIf="informationForm.controls.transitionDate.invalid">{{
        'information.error-transition-date' | translate
      }}</mat-error>
    </mat-form-field>
    <div class="spacing--margin-vertical spacing--padding-bottom">
      <button mat-flat-button color="primary" type="submit">
        {{ 'save' | translate }}
      </button>
    </div>
  </form>

  <ng-container *ngIf="hasValidInformation$ | async">
    <div
      *ngIf="!!viewMode && (information$ | async) as information"
      class="spacing--padding-top"
      data-test="information__values"
    >
      <h3 class="text--h3 text--light">{{ 'information.title' | translate }}</h3>

      <agr-value-row label="{{ 'information.year' | translate }}" data-test="information__year">
        {{ information.year }}
      </agr-value-row>
      <agr-value-row
        label="{{ 'information.cultivation' | translate }}"
        data-test="information__cultivation"
      >
        {{ information.cultivation.cultivation }}
      </agr-value-row>
      <agr-value-row
        label="{{ 'information.certification' | translate }}"
        data-test="information__certificate"
      >
        {{ information.grainCertificate.certificate }}
      </agr-value-row>
      <agr-value-row
        label="{{ 'information.skal' | translate }}"
        data-test="information__skal-number"
        *ngIf="showSkalNumber"
      >
        {{ information.skalNumber }}
      </agr-value-row>
      <agr-value-row
        label="{{ 'information.transition-date' | translate }}"
        data-test="information__transition-date"
        *ngIf="showTransitionDate"
      >
        {{ information.transitionDate | agrDate : 'shortDate' }}
      </agr-value-row>
    </div>
  </ng-container>
</div>
