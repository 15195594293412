<div data-test="grower" class="spacing--padding-top">
  <h3 class="text--h3 text--light">{{ 'complete.grower' | translate }}</h3>
  <div *ngIf="customer$ | async as customer" data-test="grower__values">
    <agr-value-row
      label="{{ 'complete.growerNumber' | translate }}"
      *ngIf="customer.externalCustomerId"
      data-test="grower__external-customer-id"
    >
      {{ customer.externalCustomerId }}
    </agr-value-row>
    <agr-value-row
      label="{{ 'complete.companyName' | translate }}"
      *ngIf="customer.name"
      data-test="grower__name"
    >
      {{ customer.name }}
    </agr-value-row>
    <agr-value-row label="{{ 'complete.address' | translate }}" data-test="grower__address">
      {{ customer.street }} {{ customer.houseNumber }}{{ customer.houseNumberAddition }}<br />
      {{ customer.postalCode }} {{ customer.city }}
    </agr-value-row>
    <agr-value-row label="{{ 'complete.phoneNumber' | translate }}" data-test="grower__phone">
      {{ customer.telephoneNumber }}
    </agr-value-row>
    <agr-value-row label="{{ 'complete.mobileNumber' | translate }}" data-test="grower__phone">
      {{ customer.mobileNumber }}
    </agr-value-row>
  </div>
</div>
