import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {
  selectCultivations,
  selectGrainCertificates,
  selectRegistrationDefaultValues,
} from 'src/app/registration/store/selectors/registration.selectors';

import {
  Cultivation,
  CultivationEnum,
  GrainCertificate,
} from '../../models/grain-registration.model';
import { Information, RegistrationState } from '../../models/registration.model';
import { checkInformation } from '../../store/actions/information.actions';
import { getRegistrationDefaultValues } from '../../store/actions/registration.actions';
import {
  hasValidInformation,
  selectInformation,
} from '../../store/selectors/information.selectors';

@Component({
  selector: 'app-registration-information',
  templateUrl: './information.component.html',
})
export class InformationComponent implements OnInit, OnDestroy {
  @Input() viewMode: boolean;

  informationForm = new FormGroup({
    cultivation: new FormControl('', [Validators.required]),
    year: new FormControl('', [Validators.required]),
    grainCertificate: new FormControl('', [Validators.required]),
    skalNumber: new FormControl('', []),
    transitionDate: new FormControl('', []),
  });

  cultivations$ = this.store.select(selectCultivations);
  grainCertificates$ = this.store.select(selectGrainCertificates);
  hasValidInformation$ = this.store.select(hasValidInformation);
  years: Array<number> = [new Date().getUTCFullYear(), new Date().getUTCFullYear() + 1];
  information$ = this.store.pipe(
    select(selectInformation),
    map((information) => (!information ? { ...information, year: this.years[0] } : information)),
    shareReplay(1),
  );
  showSkalNumber = false;
  showTransitionDate = false;
  subscription: Subscription;

  defaultValues$ = this.store.select(selectRegistrationDefaultValues);

  constructor(private store: Store<RegistrationState>) {}

  ngOnInit() {
    this.subscription = this.informationForm
      .get('cultivation')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .valueChanges.subscribe((cultivation: any) => this.setControlsByCultivation(cultivation));

    this.defaultValues$.subscribe((defaultValues) => {
      if (this.showSkalNumber) {
        this.informationForm.controls.skalNumber.setValue(defaultValues?.skalNumber);
      }
      this.informationForm.controls.grainCertificate.setValue(defaultValues?.certificate);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  save() {
    if (this.informationForm.valid) {
      this.store.dispatch(checkInformation(this.informationForm.value as unknown as Information));
    }
  }

  setControlsByCultivation(cultivation: Cultivation) {
    if (cultivation) {
      switch (cultivation.id) {
        case CultivationEnum.Biological: {
          this.showSkalNumber = true;
          this.showTransitionDate = false;
          this.informationForm.controls.skalNumber.setValidators([Validators.required]);
          break;
        }
        case CultivationEnum.Switchover: {
          this.showSkalNumber = true;
          this.showTransitionDate = true;
          this.informationForm.controls.transitionDate.setValidators([Validators.required]);
          break;
        }
        case CultivationEnum.Common:
        default: {
          this.clearControl(this.informationForm.controls.skalNumber);
          this.clearControl(this.informationForm.controls.transitionDate);
          this.showSkalNumber = false;
          this.showTransitionDate = false;
        }
      }

      this.store.dispatch(getRegistrationDefaultValues({ cultivationId: cultivation.id }));
    }
  }

  clearControl(control: AbstractControl) {
    control.setValidators([]);
    control.setValue('');
    control.updateValueAndValidity();
    control.markAsUntouched();
  }

  compareCultivationsFn(c1: Cultivation, c2: Cultivation): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  compareCertificatesFn(g1: GrainCertificate, g2: GrainCertificate): boolean {
    return g1 && g2 ? g1.certificate === g2.certificate : g1 === g2;
  }
}
