import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, mapToCanActivate } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { BatchConfigurationComponent } from './components/batch-configuration/batch-configuration.component';
import { BatchOverviewComponent } from './components/batch-overview/batch-overview.component';
import { CompleteComponent } from './components/complete/complete.component';
import { InformationComponent } from './components/information/information.component';
import { RegistrationComponent } from './registration.component';
import { reducer } from './store/reducers/registration.reducer';
import { SharedModule } from '../shared/shared.module';
import { CustomerGuard } from '@next/core-lib/relationships';
import { RegistrationEffects } from './store/effects/registration.effects';
import { RegistrationGuard } from './guards/registration-guard';
import { BatchConfigurationAddGuard } from './guards/batch-configuration-add.guard';
import { BatchConfigurationEditGuard } from './guards/batch-configuration-edit.guard';
import {
  AgrPageDetailModule,
  AgrNotificationEmptyModule,
  AgrValueRowModule,
} from '@next/core-lib/components';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { AgrFormModule } from '@next/core-lib/form';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

const routes: Routes = [
  { path: '', component: InformationComponent, canActivate: mapToCanActivate([CustomerGuard]) },
  {
    path: 'batches',
    children: [
      {
        path: '',
        component: BatchOverviewComponent,
        canActivate: mapToCanActivate([CustomerGuard]),
      },
      {
        path: 'add',
        component: BatchConfigurationComponent,
        canActivate: mapToCanActivate([BatchConfigurationAddGuard, CustomerGuard]),
      },
      {
        path: ':id',
        component: BatchConfigurationComponent,
        canActivate: mapToCanActivate([BatchConfigurationEditGuard, CustomerGuard]),
      },
    ],
  },
  {
    path: 'complete',
    component: CompleteComponent,
    canActivate: mapToCanActivate([CustomerGuard]),
  },
];

@NgModule({
  declarations: [
    BatchConfigurationComponent,
    BatchOverviewComponent,
    CompleteComponent,
    InformationComponent,
    RegistrationComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature('registrationModule', reducer),
    EffectsModule.forFeature([RegistrationEffects]),
    RouterModule.forChild([
      {
        path: 'registration',
        component: RegistrationComponent,
        children: routes,
        canActivate: mapToCanActivate([RegistrationGuard]),
      },
    ]),
    AgrPageDetailModule,
    AgrFormModule,
    AgrNotificationEmptyModule,
    AgrValueRowModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatInputModule,
    MatCardModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatAutocompleteModule,
  ],
  exports: [],
})
export class RegistrationModule {}
