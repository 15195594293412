<agr-page-detail
  [pageTitle]="'pick-up.new-registration' | translate"
  class="registration"
  data-test="registration"
  (backAction)="back()"
>
  <div class="wrapper--spacing-horizontal">
    <nav mat-tab-nav-bar color="accent" class="registration__tabs">
      <a
        mat-tab-link
        [routerLink]="['/pick-up', 'registration', 'information']"
        [active]="isInformation$ | async"
        data-test="registration__tab-information"
      >
        1. {{ 'pick-up.information' | translate }}
      </a>
      <a
        mat-tab-link
        [disabled]="(isConfirm$ | async) === false"
        [active]="isConfirm$ | async"
        data-test="registration__tab-confirm"
      >
        2. {{ 'pick-up.confirmation' | translate }}
      </a>
    </nav>
    <router-outlet></router-outlet>
  </div>
</agr-page-detail>
