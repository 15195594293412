import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
  map,
  switchMap,
  mergeMap,
  debounceTime,
  startWith,
  finalize,
  exhaustMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, iif, of } from 'rxjs';
import * as historyActions from './history.actions';
import { HistoryItem } from '../models/history.model';
import { GrainRegistrationService } from '../services/grain-registration.service';
import { CoreActions } from '@next/core-lib';
import { select, Store } from '@ngrx/store';
import { SiteState } from '@next/core-lib/site';
import { getCropYear } from 'src/app/store/selectors';

@Injectable()
export class HistoryEffects {
  constructor(
    private actions$: Actions,
    private api: GrainRegistrationService,
    private store: Store<SiteState>,
  ) {}

  getHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(historyActions.getHistory),
      withLatestFrom(this.store.pipe(select(getCropYear))),
      mergeMap(([_, cropYear]) =>
        iif(
          () => cropYear != null,
          this.api.getRegistrationHistory(cropYear).pipe(
            map((history) =>
              history.sort(
                (h1: HistoryItem, h2: HistoryItem) =>
                  new Date(h2.date).getTime() - new Date(h1.date).getTime(),
              ),
            ),
            switchMap((history) => {
              const hasItemsToProcess = history.filter((h) => h.pdf === null).length > 0;
              if (hasItemsToProcess) {
                return of(
                  historyActions.getHistoryRefresh(),
                  historyActions.getHistorySuccess({ history }),
                );
              } else {
                return of(historyActions.getHistorySuccess({ history }));
              }
            }),
            startWith(CoreActions.setLoading({ loading: true })),
            finalize(() => this.store.dispatch(CoreActions.setLoading({ loading: false }))),
          ),
          EMPTY,
        ),
      ),
    ),
  );

  getHistoryRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(historyActions.getHistoryRefresh),
      withLatestFrom(this.store.pipe(select(getCropYear))),
      debounceTime(10000),
      mergeMap(([_, cropYear]) =>
        this.api.getRegistrationHistory(cropYear).pipe(
          map((history) =>
            history.sort(
              (h1: HistoryItem, h2: HistoryItem) =>
                new Date(h2.date).getTime() - new Date(h1.date).getTime(),
            ),
          ),
          switchMap((history) => {
            const hasItemsToProcess = history.filter((h) => h.pdf === null).length > 0;
            if (hasItemsToProcess) {
              return of(
                historyActions.getHistoryRefresh(),
                historyActions.getHistorySuccess({ history }),
              );
            } else {
              return of(historyActions.getHistorySuccess({ history }));
            }
          }),
        ),
      ),
    ),
  );

  getHistoryDocument$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(historyActions.getHistoryDocument),
        exhaustMap((action) => this.api.getRegistrationHistoryDocument(action.id)),
        tap((response) => {
          const contentDisposition = response.headers.get('Content-Disposition');
          const filename = contentDisposition
            .split(';')[1]
            .split('filename')[1]
            .split('=')[1]
            .trim();

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const windowNavigator = window.navigator as any;

          if (windowNavigator.msSaveOrOpenBlob) {
            windowNavigator.msSaveBlob(response.body, filename);
          } else {
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(response.body);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
          }
        }),
      ),
    { dispatch: false },
  );
}
