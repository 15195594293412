import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AgrSharedModule } from '@next/core-lib/shared';
import { GrowerComponent } from '../registration/components/grower/grower.component';
import { AgrValueRowModule } from '@next/core-lib/components';

@NgModule({
  declarations: [GrowerComponent],
  imports: [
    CommonModule,
    AgrSharedModule,
    AgrValueRowModule,
    // add shared imported modules here
  ],
  exports: [
    AgrSharedModule,
    GrowerComponent,
    // add reshared modules and providers here
  ],
  providers: [
    // add shared services and guards here
  ],
})
export class SharedModule {
  constructor(matIcon: MatIconRegistry, sanitizer: DomSanitizer) {
    matIcon.addSvgIconLiteral(
      'mdi-file-pdf',
      sanitizer.bypassSecurityTrustHtml(
        /* eslint-disable max-len */
        `<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2M10.1,11.4C10.08,11.44 9.81,13.16 8,16.09C8,16.09 4.5,17.91 5.33,19.27C6,20.35 7.65,19.23 9.07,16.59C9.07,16.59 10.89,15.95 13.31,15.77C13.31,15.77 17.17,17.5 17.7,15.66C18.22,13.8 14.64,14.22 14,14.41C14,14.41 12,13.06 11.5,11.2C11.5,11.2 12.64,7.25 10.89,7.3C9.14,7.35 9.8,10.43 10.1,11.4M10.91,12.44C10.94,12.45 11.38,13.65 12.8,14.9C12.8,14.9 10.47,15.36 9.41,15.8C9.41,15.8 10.41,14.07 10.91,12.44M14.84,15.16C15.42,15 17.17,15.31 17.1,15.64C17.04,15.97 14.84,15.16 14.84,15.16M7.77,17C7.24,18.24 6.33,19 6.1,19C5.87,19 6.8,17.4 7.77,17M10.91,10.07C10.91,10 10.55,7.87 10.91,7.92C11.45,8 10.91,10 10.91,10.07Z" /></svg>`,
      ),
    );
    matIcon.addSvgIconLiteral(
      'ship-grain',
      sanitizer.bypassSecurityTrustHtml(
        /* eslint-disable max-len */
        `<svg version=\"1.1\" id=\"Layer_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t width=\"116px\" height=\"256px\" viewBox=\"0 0 116 256\" enable-background=\"new 0 0 116 256\" xml:space=\"preserve\">\n<path d=\"M50.364,254c-30.937-2.154-54.825-30.154-46.21-63.832C30.196,199.958,51.538,214.839,50.364,254 M56.238,78.951\n\tC28.825,64.657,18.056,29.413,39.007,2C58.979,21.385,72.881,43.315,56.238,78.951 M65.636,177.832\n\tc30.937-2.35,54.825-30.154,46.21-63.832C85.804,123.79,64.462,138.671,65.636,177.832 M65.636,238.336\n\tc30.937-2.35,54.825-30.154,46.21-63.832C85.804,184.294,64.462,199.175,65.636,238.336 M65.636,117.329\n\tc30.937-2.154,54.825-30.154,46.21-63.832C85.804,63.287,64.462,78.168,65.636,117.329 M50.364,193.692\n\tc-30.937-2.35-54.825-30.35-46.21-63.832C30.196,139.65,51.538,154.531,50.364,193.692 M50.364,133.189\n\tc-30.937-2.35-54.825-30.154-46.21-63.832C30.196,79.147,51.538,94.028,50.364,133.189\"/>\n</svg>`,
      ),
    );
  }
}
