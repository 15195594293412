<div class="page-table-filter wrapper--spacing-horizontal">
  <agr-table-v2
    #pickupsTable
    *ngIf="allDataIsLoaded()"
    matSort
    [dataSource]="dataSource"
    [isEqual]="isEqual"
    (filterClearClick)="resetFilters()"
    [tableLayoutKey]="tableDisplayMode === 'list' ? null : tableDisplayMode"
    class="pickup-overview__table-spaced-cols"
  >
    <ng-container *agrTableSearch [formGroup]="searchAndFilters">
      <agr-table-search
        formControlName="search"
        [label]="'search' | translate"
        [placeholder]="'pick-up.overview-table.search-placeholder' | translate"
      >
      </agr-table-search>
    </ng-container>

    <agr-table-top-bar *agrTableTopBar class="pickup-overview__top-bar">
      <agr-table-selected-items></agr-table-selected-items>
      <div
        class="flex--row flex--gutter flex--nowrap flex--align-center flex--justify-space-between"
      >
        <agr-export agrTableExport [options]="exportOptions" [dataSource]="dataSource">
        </agr-export>
      </div>
    </agr-table-top-bar>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'pick-up.overview-table.displayId' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'pick-up.overview-table.displayId' | translate"
        *matCellDef="let row"
      >
        {{ row.displayId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="updated">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'pick-up.overview-table.updated' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'pick-up.overview-table.updated' | translate"
        *matCellDef="let row"
      >
        {{ row.displayUpdated }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'pick-up.overview-table.status' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'pick-up.overview-table.status' | translate"
        *matCellDef="let row"
      >
        <div *ngIf="!isEditAllPickupsMode">
          <span *ngIf="row.status?.length > 0; else showDash">{{
            statusesName.get(row.status)
          }}</span>
          <ng-template #showDash>-</ng-template>
        </div>
        <mat-form-field
          floatLabel="always"
          appearance="fill"
          class="product-quantity__input mat-form-field__no-spacing"
          style="width: 80px"
          *ngIf="isEditAllPickupsMode"
        >
          <mat-select
            panelWidth=""
            placeholder="Status"
            [(ngModel)]="row.status"
            select
            (ngModelChange)="onChange(row)"
          >
            <mat-option [value]="pickupStatuses.noStatus"
              >{{ statusesName.get(pickupStatuses.noStatus) }}
            </mat-option>
            <mat-option [value]="pickupStatuses.planned"
              >{{ statusesName.get(pickupStatuses.planned) }}
            </mat-option>
            <mat-option [value]="pickupStatuses.collected"
              >{{ statusesName.get(pickupStatuses.collected) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="transporterName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'pick-up.overview-table.transporterName' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'pick-up.overview-table.transporterName' | translate"
        *matCellDef="let row"
      >
        {{ row.transporterName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="remarks">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'pick-up.overview-table.remarks' | translate }}
      </th>
      <td
        mat-cell
        [matTooltip]="row.remarks"
        [attr.aria-label]="'pick-up.overview-table.remark' | translate"
        *matCellDef="let row"
      >
        <div *ngIf="!isEditAllPickupsMode" style="width: 90%" class="truncate-text">
          {{ row.remarks }}
        </div>
        <mat-form-field
          floatLabel="always"
          appearance="fill"
          class="product-quantity__input mat-form-field__no-spacing remarks"
          *ngIf="isEditAllPickupsMode"
          (click)="onRemarkChange(row)"
        >
          <input
            matInput
            id="input-with-cursor"
            class="product-quantity__input-text clickable-input"
            placeholder="Remarks"
            [(ngModel)]="row.remarks"
            readonly
          />
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="customer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'pick-up.overview-table.customer' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'pick-up.overview-table.customer' | translate"
        *matCellDef="let row"
      >
        <span
          >{{ row.customerName }} ({{ row.customerExternalId }}),
          {{ row.contactInformation.emailAddress }}, {{ row.contactInformation.phoneNumber }}</span
        >
      </td>
    </ng-container>

    <ng-container matColumnDef="pickupLocation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'pick-up.overview-table.pickupLocation' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'pick-up.overview-table.pickupLocation' | translate"
        *matCellDef="let row"
      >
        {{ row.pickUpInformation.pickUpLocation?.street }}
        {{ row.pickUpInformation.pickUpLocation?.houseNumber }},
        {{ row.pickUpInformation.pickUpLocation?.city }}
      </td>
    </ng-container>

    <ng-container matColumnDef="postalCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'pick-up.overview-table.postalCode' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'pick-up.overview-table.postalCode' | translate"
        *matCellDef="let row"
      >
        {{ row.pickUpInformation.pickUpLocation.postalCode }}
      </td>
    </ng-container>

    <ng-container matColumnDef="crop">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'pick-up.overview-table.crop' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'pick-up.overview-table.crop' | translate"
        *matCellDef="let row"
      >
        <span>{{ row.cropInformation?.cultivationMethod }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="tons">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'pick-up.overview-table.tons' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'pick-up.overview-table.tons' | translate"
        *matCellDef="let row; let i = index"
      >
        <span *ngIf="!isEditAllPickupsMode">{{ row.cropInformation?.ton }}</span>
        <mat-form-field
          floatLabel="always"
          appearance="fill"
          class="product-quantity__input mat-form-field__no-spacing"
          style="width: 35px"
          *ngIf="isEditAllPickupsMode"
        >
          <input
            matInput
            agrNumber
            class="product-quantity__input-text"
            placeholder="tons"
            [(ngModel)]="row.cropInformation.ton"
            select
            (ngModelChange)="onChange(row)"
          />
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="isCustomerLoading">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'pick-up.overview-table.isCustomerLoading' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'pick-up.overview-table.isCustomerLoading' | translate"
        *matCellDef="let row"
      >
        <mat-checkbox
          color="primary"
          [disabled]="!isEditAllPickupsMode || isTransporter"
          [(ngModel)]="row.pickUpInformation.isCustomerLoading"
          select
          (ngModelChange)="onChange(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="isInsideStorage">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'pick-up.overview-table.isInsideStorage' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'pick-up.overview-table.isInsideStorage' | translate"
        *matCellDef="let row"
      >
        <mat-checkbox
          color="primary"
          [disabled]="!isEditAllPickupsMode || isTransporter"
          [(ngModel)]="row.pickUpInformation.isInsideStorage"
          select
          (ngModelChange)="onChange(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="internalNotebook">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'pick-up.overview-table.internalNotebook' | translate }}
      </th>
      <td
        mat-cell
        [matTooltip]="row.internalNotebook"
        [attr.aria-label]="'pick-up.overview-table.internalNotebook' | translate"
        *matCellDef="let row; let i = index"
      >
        <div *ngIf="!isEditAllPickupsMode" style="width: 90%" class="truncate-text">
          {{ row.internalNotebook }}
        </div>
        <mat-form-field
          floatLabel="always"
          appearance="fill"
          class="product-quantity__input mat-form-field__no-spacing"
          *ngIf="isEditAllPickupsMode"
          style="width: 95%"
        >
          <input
            matInput
            id="input-with-cursor"
            class="product-quantity__input-text"
            [(ngModel)]="row.internalNotebook"
            (click)="onInternalNotebookChange(row)"
            readonly
          />
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="pdf" *ngIf="!userCanDeleteRow">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'pick-up.overview-table.actions.downloadFile' | translate }}
      </th>
      <td mat-cell *matCellDef="let item" data-test="history__pdf" class="pdf-cell">
        @if (item.pdf) {
        <button mat-menu-item (click)="downloadPdfFromRow(item)">
          <mat-icon class="action-icon" svgIcon="mdi-file-pdf"></mat-icon>
          <span>{{ 'pick-up.overview-table.actions.downloadFile' | translate }}</span>
        </button>
        } @else {
        <button mat-menu-item disabled>
          <mat-icon>
            <mat-spinner style="display: inline-flex" diameter="20"></mat-spinner>
          </mat-icon>
          <span>{{ 'history.pdf-being-generated' | translate }}</span>
        </button>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'table.actions' | translate }}
      </th>
      <td mat-cell *matCellDef="let row; let i = index" class="action-cell">
        <app-table-actions-button data-test="delivery-overview__context-menu">
          @if (row.pdf) {
          <button mat-menu-item (click)="downloadPdfFromRow(row)">
            <mat-icon class="action-icon" svgIcon="mdi-file-pdf"></mat-icon>
            <span>{{ 'pick-up.overview-table.actions.downloadFile' | translate }}</span>
          </button>
          } @else {
          <button mat-menu-item disabled>
            <mat-icon>
              <mat-spinner style="display: inline-flex" diameter="20"></mat-spinner>
            </mat-icon>
            <span>{{ 'history.pdf-being-generated' | translate }}</span>
          </button>
          }
          <button *ngIf="userCanDeleteRow" mat-menu-item (click)="deleteRow(row)">
            <mat-icon>delete</mat-icon>
            <span>{{ 'pick-up.overview-table.actions.deleteRow' | translate }}</span>
          </button>
        </app-table-actions-button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay"></tr>
    <tr *matNoDataRow>
      <td class="text--center" [attr.colspan]="columnsToDisplay.length">
        <agr-notification-empty
          data-test="hierarchical-data__empty"
          [isEmpty]="true"
          [emptyTitle]="'pick-up.overview-table.empty-title' | translate"
          [emptyDescription]="'pick-up.overview-table.empty-description' | translate"
          [emptyImage]="'/assets/images/no-items-3.png'"
          [emptyActionIcon]="'history'"
          [emptyActionText]="'pick-up.overview-table.reset-filters' | translate"
          [showEmptyAction]="true"
          (emptyAction)="resetFilters()"
          class="spacing--margin-top"
        ></agr-notification-empty>
      </td>
    </tr>
  </agr-table-v2>
</div>
