import { Component, OnInit, NgZone, OnDestroy, Inject, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { getHistory, getHistoryDocument } from '../../store/history.actions';
import { selectHistory } from '../../store/history.selectors';
import { Router } from '@angular/router';
import { BreakpointService, BreakpointServiceInjector } from '@next/core-lib/responsive';
import { AuthorizationSelectors } from '@next/core-lib/authorization';
import { tap, take, withLatestFrom, takeUntil, map, startWith } from 'rxjs/operators';
import { BehaviorSubject, Subject, Subscription, combineLatest } from 'rxjs';
import { SiteState } from '@next/core-lib/site/public_api';
import { Environment } from 'src/environment';
import { EnvironmentInjector } from '@next/core-lib/environment';
import { FilterConfig, FiltersConfig, FilterTypes } from '@next/core-lib/components';
import * as AppSelectors from 'src/app/store/selectors';
import { SetCropYear } from 'src/app/store/actions';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AgrDatePipe } from '@next/core-lib/i18n';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  private readonly cropYearChanged$ = new Subject<number>();
  private readonly destroyed$ = new Subject<boolean>();

  searchChanged$ = new Subject<string>();
  filterValue$ = this.searchChanged$.pipe(
    map((value: string) => value || ''),
    startWith(''),
  );
  displayedColumns: string[];
  isInMaintenanceMode = false;
  pageSize = 25;
  page$ = new BehaviorSubject<PageEvent>({
    pageIndex: 0,
    pageSize: this.pageSize,
    length: 0,
  });
  history$ = this.store.select(selectHistory);

  cropYear$ = this.store.pipe(select(AppSelectors.getCropYear));
  defaultCropYear$ = this.store.pipe(select(AppSelectors.getDefaultCropYear));
  availableCropYears$ = this.store.pipe(select(AppSelectors.getAvailableCropYears));

  isMobile$ = this.breakpoint.ltLg$.pipe(
    tap((isMobile) => {
      this.displayedColumns = isMobile
        ? ['id', 'year', 'pdf']
        : ['id', 'year', 'date', 'certification', 'cultivation', 'hectares', 'pdf'];
    }),
  );
  hasGlobalAdminAccessRule$ = this.store.pipe(
    select(AuthorizationSelectors.hasGlobalAdministratorRole),
    take(1),
    tap((isGlobalAdmin) => {
      this.displayedColumns = [
        'id',
        'year',
        'date',
        'certification',
        'cultivation',
        'hectares',
        'pdf',
      ];
      if (isGlobalAdmin) {
        this.displayedColumns.splice(2, 0, 'customer');
      }
    }),
  );
  pagedHistory$ = combineLatest([this.history$, this.page$, this.filterValue$]).pipe(
    map(([history, { pageIndex, pageSize }, filter]) =>
      history
        .filter(
          (h) =>
            h.customerName.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
            h.externalCustomerId.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
            h.displayId.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
            h.year.toString().indexOf(filter.toLowerCase()) !== -1 ||
            this.agrDate
              .transform(new Date(h.date), 'short')
              .toLowerCase()
              .indexOf(filter.toLowerCase()) !== -1 ||
            h.certificate.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
            h.cultivation.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
            h.hectares.toString().indexOf(filter.toLowerCase()) !== -1,
        )
        .slice(pageIndex * pageSize, pageIndex * pageSize + pageSize),
    ),
  );

  filterConfig: FilterConfig<FiltersConfig> = {
    filters: {
      year: {
        label: 'year',
        type: FilterTypes.Select,
        options: this.availableCropYears$.pipe(
          map((availableYears) =>
            availableYears.map((availableYear) => ({
              value: availableYear,
              viewValue: availableYear.toString(),
            })),
          ),
        ),
        value: this.cropYear$,
        defaultValue: this.defaultCropYear$,
        changes: this.cropYearChanged$,
      },
      search: {
        label: 'search',
        type: FilterTypes.Text,
        changes: this.searchChanged$,
      },
    },
  };

  subscription: Subscription;

  constructor(
    private store: Store<SiteState>,
    private router: Router,
    private ngZone: NgZone,
    private agrDate: AgrDatePipe,
    @Inject(BreakpointServiceInjector) private readonly breakpoint: BreakpointService,
    @Inject(EnvironmentInjector) private readonly env: Environment,
  ) {}

  ngOnInit() {
    this.history$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.resetPagination();
    });
    this.store.dispatch(getHistory());
    this.subscription = this.isMobile$.subscribe();
    this.isInMaintenanceMode = this.env.MAINTENANCE_MODE;

    this.cropYearChanged$
      .pipe(withLatestFrom(this.cropYear$), takeUntil(this.destroyed$))
      .subscribe(([newYear, existingYear]) => {
        if (newYear && newYear !== existingYear) {
          this.store.dispatch(new SetCropYear(newYear));
        }
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onOpenPdfDocument(id: string) {
    this.store.dispatch(getHistoryDocument({ id }));
  }

  onNavigationAddRegistration() {
    this.ngZone.run(() => this.router.navigate(['/registration'])).then();
  }

  page(event: PageEvent) {
    this.page$.next(event);
  }

  private resetPagination() {
    this.page$.next({
      pageIndex: 0,
      pageSize: this.pageSize,
      length: 0,
    });
  }
}
