import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PickUpInformation, PickUpState } from '../../models/pick-up.model';
import { savePickUpRequest } from '../../store/actions/information.actions';
import { selectPickUpInformation } from '../../store/selectors/pick-up.selectors';

@Component({
  selector: 'app-pick-up-registration-confirm',
  templateUrl: './pick-up-registration-confirm.component.html',
})
export class PickUpRegistrationConfirmComponent implements OnInit, OnDestroy {
  pickUpInformation: PickUpInformation;
  private readonly destroyed$ = new Subject<void>();

  constructor(private readonly store: Store<PickUpState>) {}

  ngOnInit(): void {
    this.store
      .select(selectPickUpInformation)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((pickUpInformation) => (this.pickUpInformation = pickUpInformation));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  savePickUpRequest(): void {
    this.store.dispatch(savePickUpRequest());
  }
}
