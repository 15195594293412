import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccessRoleTypes } from '@next/core-lib';
import { RootState } from '@next/core-lib/app';
import { AuthorizationSelectors } from '@next/core-lib/authorization';
import { ParentNavigationItem } from '@next/core-lib/navigation';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterContentChecked {
  navigationItems: ParentNavigationItem[] = [];
  private readonly grainRegistrationRelativeUrl = '/history';
  private readonly pickUpRelativeUrl = '/pick-up';

  constructor(private readonly store: Store<RootState>, private router: Router) {}

  ngOnInit(): void {
    this.selectAccessRuleForGrainRegistration();
    this.selectAccessRuleForGrainPickUp();
  }

  /* By default, the application is initialized with Grain Registration page
   * A redirect is forced to Grain PickUp page, if the user has no access to Grain Registration */
  ngAfterContentChecked(): void {
    this.redirectToGrainPickUp();
  }

  private selectAccessRuleForGrainRegistration(): void {
    this.store
      .select(AuthorizationSelectors.selectAccessRuleForApplicationKey('Aanmelden'))
      .pipe(take(1))
      .subscribe((result) => {
        if (
          result &&
          result.RoleKey !== AccessRoleTypes.NotAuthorized &&
          result.RoleKey !== AccessRoleTypes.Transporter
        ) {
          this.addNavigationItem(this.grainRegistrationRelativeUrl, 'sidenav.grain-registration');
        }
      });
  }

  private selectAccessRuleForGrainPickUp(): void {
    this.store
      .select(AuthorizationSelectors.selectAccessRuleForApplicationKey('GrainPickUp'))
      .pipe(take(1))
      .subscribe((result) => {
        if (result && result.RoleKey !== AccessRoleTypes.NotAuthorized) {
          this.addNavigationItem(this.pickUpRelativeUrl, 'sidenav.grain-pick-up');
        }
      });
  }

  private addNavigationItem(relativeUrl: string, label: string, icon?: string) {
    if (!this.navigationItems.find((item) => item.relativeUrl === relativeUrl)) {
      this.navigationItems.push({
        relativeUrl,
        label,
        icon: icon ? icon : 'dashboard',
      });
    }
  }

  private redirectToGrainPickUp() {
    if (
      this.navigationItems.length === 1 &&
      this.navigationItems[0].relativeUrl === this.pickUpRelativeUrl &&
      this.router.url !== this.pickUpRelativeUrl &&
      this.router.getCurrentNavigation()?.id < 5 // This gives the number of tries to navigate.
      /* The higher the number, it means the navigation was not successful.
       * This is needed because when the access token expires it needs to navigate to login page to refresh it. */
    ) {
      this.router.navigate([this.pickUpRelativeUrl]);
    }
  }
}
