import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, mapToCanActivate } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { StoreModule } from '@ngrx/store';
import { AgrFilterModule, AgrPageToolbarModule } from '@next/core-lib/components';
import { HistoryComponent } from './components/history/history.component';
import { reducer } from './store/history.reducer';
import { HistoryEffects } from './store/history.effects';
import { SharedModule } from '../shared/shared.module';
import { HistoryGuard } from './guards/history-guard';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AgrDatePipe } from '@next/core-lib/i18n';

const routes: Routes = [{ path: '', component: HistoryComponent }];

@NgModule({
  declarations: [HistoryComponent],
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature('historyModule', reducer),
    EffectsModule.forFeature([HistoryEffects]),
    RouterModule.forChild([
      { path: '', redirectTo: 'history', pathMatch: 'full' },
      {
        path: 'history',
        children: routes,
        canActivate: mapToCanActivate([HistoryGuard]),
      },
    ]),
    AgrPageToolbarModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    AgrFilterModule,
  ],
  providers: [AgrDatePipe],
  exports: [],
})
export class HistoryModule {}
