import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RelationshipsActions } from '@next/core-lib/relationships';
import { SessionActions } from '@next/core-lib/session';
import { UserActions } from '@next/core-lib/user';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { SiteState } from '../shared/models';
import { PeriodService } from '../shared/services/arable-period.service';
import {
  AppActionTypes,
  ContextChanged,
  CropYearChanged,
  GetDefaultCropYear,
  LoadCustomerData,
  SetAvailableCropYears,
  SetDefaultCropYear,
} from './actions';
import {
  getAvailableCropYears,
  getCropYear,
  getDefaultCropYear,
  selectSessionCustomerId,
} from './selectors';
import * as historyActions from '../history/store/history.actions';
import { AuthorizationSelectors } from '@next/core-lib/authorization';

@Injectable()
export class AppEffects {
  userInitialized$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.userInitialized),
      switchMap(() => [new GetDefaultCropYear()]),
    ),
  );

  getDefaultCropYear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActionTypes.GetDefaultCropYear),
      switchMap((_) =>
        this.periodService
          .getDefaultYear()
          .pipe(map((defaultYear: number) => new SetDefaultCropYear(defaultYear))),
      ),
    ),
  );

  setDefaultCropYear$ = createEffect(() => {
    const currentYear = new Date().getFullYear();
    return this.actions$.pipe(
      ofType(AppActionTypes.SetDefaultCropYear),
      switchMap((_) => [
        new SetAvailableCropYears([currentYear - 1, currentYear, currentYear + 1]),
        new ContextChanged(),
      ]),
    );
  });

  customerInitialized$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.customerInitialized),
      switchMap((_) => [new ContextChanged()]),
    ),
  );

  customerChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.customerChanged),
      tap((_) => {
        const grainRegistrationRelativeUrl = '/history';
        const pickUpRelativeUrl = '/pick-up';
        if (this.router.url === grainRegistrationRelativeUrl) {
          this.router.navigate([grainRegistrationRelativeUrl]);
        } else if (this.router.url === pickUpRelativeUrl) {
          this.router.navigate([pickUpRelativeUrl]);
        }
      }),
      switchMap((_) => [new ContextChanged()]),
    ),
  );

  cropYearChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActionTypes.CropYearChanged),
      map(() => new ContextChanged()),
    ),
  );

  contextChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActionTypes.ContextChanged),
      withLatestFrom(
        this.store.pipe(select(getCropYear)),
        this.store.pipe(select(selectSessionCustomerId)),
        this.store.select(AuthorizationSelectors.hasGlobalAdministratorRole),
        this.store.select(AuthorizationSelectors.hasAdministratorRole),
      ),
      switchMap(([_, cropYear, customerId, isGlobalAdministrator, isAdministrator]) =>
        cropYear && (customerId || isGlobalAdministrator || isAdministrator)
          ? of(new LoadCustomerData())
          : EMPTY,
      ),
    ),
  );

  relationshipSelectedRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RelationshipsActions.setRelationshipSelectedRedirect),
      switchMap(({ redirectTo }) => {
        const parts = redirectTo ? redirectTo.split('/').slice(1) : [];
        if (parts[0] === 'advice' && parts.length > 2) {
          return of(RelationshipsActions.setRelationshipSelectedRedirect({ redirectTo: '/' }));
        } else {
          return EMPTY;
        }
      }),
    ),
  );

  loadCustomerData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActionTypes.LoadCustomerData),
      switchMap(() => {
        if (this.router.url.includes('/history')) {
          return of(historyActions.getHistory());
        } else {
          return EMPTY;
        }
      }),
    ),
  );

  setCropYear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActionTypes.SetCropYear),
      withLatestFrom(
        this.store.pipe(select(getCropYear)),
        this.store.pipe(select(getAvailableCropYears)),
        this.store.pipe(select(getDefaultCropYear)),
      ),
      switchMap(([{ cropYear }, currentCropYear, availableCropYears, defaultCropYear]) => {
        if (isNaN(cropYear) || availableCropYears.indexOf(cropYear) === -1) {
          return EMPTY;
        }

        if (cropYear !== currentCropYear) {
          return of(
            SessionActions.setSession({
              data:
                cropYear === defaultCropYear
                  ? { arable: {} }
                  : {
                      arable: {
                        cropYear,
                      },
                    },
            }),
            new CropYearChanged(cropYear),
          );
        } else {
          return EMPTY;
        }
      }),
    ),
  );

  constructor(
    protected readonly store: Store<SiteState>,
    protected readonly actions$: Actions,
    protected readonly router: Router,
    protected readonly periodService: PeriodService,
  ) {}
}
