import { Injectable } from '@angular/core';
import { UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { take, map } from 'rxjs/operators';
import { hasValidInformation } from '../store/selectors/information.selectors';
import { SiteState } from 'src/app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class BatchConfigurationAddGuard {
  constructor(private router: Router, private store: Store<SiteState>) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.pipe(
      select(hasValidInformation),
      map((valid) => (!!valid ? true : this.router.parseUrl(`/registration/batches`))),
      take(1),
    );
  }
}
