import { RootState } from '@next/core-lib/app';
import { SessionState } from '@next/core-lib/session';
import { HistoryModuleState } from '../history/models/history.model';
import { RegistrationState } from '../registration/models/registration.model';

export interface SiteState extends RootState {
  app?: AppState;
  history: HistoryModuleState;
  registration: RegistrationState;
}

export interface AppState {
  defaultCropYear?: number;
  availableCropYears?: number[];
}

export interface ArableSessionState extends SessionState {
  arable?: {
    cropYear?: number;
  };
}

export interface GetDefaultYearResponse {
  defaultYear: number;
}

export enum TableDisplayMode {
  list = 'list',
  grid = 'grid',
}
